import React from "react"
import { ReactComponent as InfoSvgIcon } from "../../Images/svg/InfoRedSvg.svg"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const InfoRedSvgIcon = (props: SvgIconProps) => (
    
  <SvgIcon {...props}>
    <InfoSvgIcon />
  </SvgIcon>
)

export default InfoRedSvgIcon