import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { SeriesChartOptionsResult } from '../../../../Models/DataModels/Responses/GraphResponses'
import { RadioBlue } from '../../GlobalSettings/CustomStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../Google/analytics'

const Chronology = (chronologySettings: any) => {
  
  const chartExtraOptions: SeriesChartOptionsResult = chronologySettings.chronologySettings.options

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    gaLogEvent('Updated Chart Options Historical Era Type', gaFinaeonWebAppEventCategories.Graph, 'handleChange Historical Era Type')
    chronologySettings.chronologySettings.setChronology(event.target.value)
  }
  
  const isTrialUser: boolean = chronologySettings?.chronologySettings?.checkIsTrial() || false

  const recessionEnabled: boolean = !chartExtraOptions || chartExtraOptions.availableChronologyOptions.containsRecession
  const headOfStateEnabled: boolean = !chartExtraOptions || chartExtraOptions.availableChronologyOptions.containsHeadOfState
  const headOfGovernmentEnabled: boolean = !chartExtraOptions || chartExtraOptions.availableChronologyOptions.containsHeadOfGovernment
  const bearMarketEnabled: boolean = !chartExtraOptions || chartExtraOptions.availableChronologyOptions.containsBearMarket
  const goldStandardEnabled: boolean = !chartExtraOptions || chartExtraOptions.availableChronologyOptions.containsGoldStandard

  return (
    <div>
      <FormControl>
        <FormLabel id='chronologySelection'>Historical Era Type:</FormLabel>
        <RadioGroup
          row
          name='chronologyRadioGroup'
          value={chronologySettings.chronologySettings.chronology}
          onChange={handleChange}
        >
          <FormControlLabel value='none'  sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser} />} label='None' />
          <FormControlLabel value='recession'  sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser || !recessionEnabled} />} label='Recession' />
          <FormControlLabel value='headofstate' sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser || !headOfStateEnabled} />} label='Head of State' />
          <FormControlLabel value='headofgovernment' sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser || !headOfGovernmentEnabled} />} label='Head of Government' />
          <FormControlLabel value='bearmarket' sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser || !bearMarketEnabled} />} label='Bear Market' />
          <FormControlLabel value='goldstandard' sx={chronologyStyles.chronologyRow} control={<RadioBlue disabled={isTrialUser || !goldStandardEnabled} />} label='Gold Standard' />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

const chronologyStyles = {
  chronologyRow: {
    paddingRight: 3,
    fontWeight: 'bold !important'
  }
}

export default Chronology