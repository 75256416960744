
import ReactGA from 'react-ga4'
import { AppConfigurations } from '../../Models/DataModels/Common/AppConfigurationsModel'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { LOG } from '../../Services/Log'

const gaTrackingID: string = AppConfigurations.googleAnalyticsTrackingID

export const initGA = () => {
    LOG('initGA ' + gaTrackingID)
    if (gaTrackingID !== '') {
        LOG('Google Analytics ENABLED')
        ReactGA.initialize([{ trackingId: gaTrackingID }])
    } else {
        LOG('Google Analytics NOT enabled')
    }
}

export const gaLogPageView = () => {
    if (gaTrackingID !== '') {
        LOG('gaLogPageView.window.location.pathname ' + window.location.pathname)
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '' })
    }
}

export const gaLogEvent = (action: string, category: string, label?: string) => {
    if (gaTrackingID !== '') {
        LOG(`logEvent: action: ${action}, category: ${category}, label: ${label}`)
        if (action && category) {
            const gaEventOptions: UaEventOptions = {
                action: action,
                category: category,
                label: label
            }
            ReactGA.event(gaEventOptions)
        }
    }
}

export const gaFinaeonWebAppEventCategories = {
    Header: 'Header',
    Account: 'Account',
    Admin: 'Admin',
    AutoTrac: 'AutoTrac',
    ForgotPassword: 'ForgotPassword',
    Graph: 'Graph',
    Help: 'Help',
    Login: 'Login',
    Register: 'Register',
    ResetForgotPassword: 'ResetForgotPassword',
    Search: 'Search',
    Tools: 'Tools',
    QuickActions: 'QuickActions',
    DownLoad: 'DownLoad',
    GraphDownload: 'GraphDownload'
}
