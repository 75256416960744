
import { Autocomplete, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddIcon from '@mui/icons-material/Add'
import Tooltip from '@mui/material/Tooltip'
import MaterialTable from '@material-table/core'
import { AutoCompleteRequest } from '../../../../Models/DataModels/Requests/SearchRequests'
import { autoComplete } from '../../../../Services/SearchService'
import { AutoCompleteData } from '../../../../Models/DataModels/Responses/SearchResponses'
import DefaultMaterialTheme from '../../GlobalSettings/DefaultMaterialTheme'
import { SearchDatabaseTypes } from '../../../../Models/DataModels/Common/FieldPopulationModel'
import { GFDToastError } from '../../Utility/GFDToastify'
import { LabelValuePair } from '../../../../Models/DataModels/Common/LabelValuePairModel'
import { SortOrderStringType, getComparator, sort } from '../../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../../Utility/EnhancedTableHead'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../Google/analytics'

const ComparisonSettings = (comparisonSettingsSetters: any) => {

  const comparisonLimit: number = 10

  const errorMessages = {
    blankInput: 'Enter Series',
    exceedLimit: `You can only compare up to ${comparisonLimit} series`,
    duplicate: 'Duplicate series!'
  }

  const isTrialUser: boolean = comparisonSettingsSetters?.comparisonSettingsSetters?.checkIsTrial() || false
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<[any]>([{ label: '' }])
  const [commitEntry, setCommitEntry] = useState<boolean>(false)

  const setTableDataOnInput = (textValue: string) => {

    //run autocomplete end point here and then comparisonSettingsSetters.comparisonSettingsSetters.set the value of search
    const req: AutoCompleteRequest = {
      searchTerm: textValue,
      database: SearchDatabaseTypes.GFDUniverse,
      matchOption: 'Contains',
      sortOption: 'Popular'
    }
    autoComplete(req).then(
      (res) => {
        let autoValues: [any] = ['']
        res.map((a: any) => {
          autoValues.push(autoCompleteDataFactory(a, a))
        })
        autoValues.shift()
        setAutoCompleteOptions(autoValues)
      },
      () => { }
    )
    comparisonSettingsSetters.comparisonSettingsSetters.setSearchTerm(textValue)
  }

  const autoCompleteDataFactory = (label: string, value: string): AutoCompleteData => {
    return { label: label, value: value }
  }

  const addValueToTable = () => {
    gaLogEvent('Added Comparison Ticker', gaFinaeonWebAppEventCategories.Graph, 'Graph Comparison')

    if (!comparisonSettingsSetters.comparisonSettingsSetters.searchTerm || comparisonSettingsSetters.comparisonSettingsSetters.searchTerm.trim() === '') {
      GFDToastError(errorMessages.blankInput)
      return
    }

    if (comparisonSettingsSetters.comparisonSettingsSetters.tickerList.length >= comparisonLimit) {
      GFDToastError(errorMessages.exceedLimit)
      return
    }

    const isDuplicate: boolean = comparisonSettingsSetters.comparisonSettingsSetters.tickerList.some(
      (tickerObj: any) => tickerObj.series.toLowerCase() === comparisonSettingsSetters.comparisonSettingsSetters.searchTerm.trim().toLowerCase()
    )

    if (isDuplicate) {
      GFDToastError(errorMessages.duplicate)
      return
    }

    const autoCompleteEntry: LabelValuePair | null = autoCompleteOptions.find((value: LabelValuePair) => {
      return value.value.split('~')[0].trim().toLowerCase() === comparisonSettingsSetters.comparisonSettingsSetters.searchTerm.trim().toLowerCase()
    })

    if (autoCompleteEntry) {
      const seriesText: string = autoCompleteEntry.value
      const [first, ...rest] = seriesText.split('~')
      const tickerData = {
        series: first.trim(),
        description: rest
      }
      comparisonSettingsSetters.comparisonSettingsSetters.setTickerList([...comparisonSettingsSetters.comparisonSettingsSetters.tickerList, tickerData])
      comparisonSettingsSetters.comparisonSettingsSetters.setSearchTerm('')
      comparisonSettingsSetters.comparisonSettingsSetters.setClearText(!comparisonSettingsSetters.comparisonSettingsSetters.clearText)
    } else {
      GFDToastError('Invalid Series!')
    }
  }

  const deleteRecord = (rowData: any) => {
    comparisonSettingsSetters.comparisonSettingsSetters.setTickerList(comparisonSettingsSetters.comparisonSettingsSetters.tickerList.filter((ticker: any) => {
      return rowData.series.toLowerCase() !== ticker.series.toLowerCase()
    }))
  }

  const parseSeriesAutoCompleteText = (input: string): string => {
    return input.split('~')[0].trim()
  }

  useEffect(() => {
    if (commitEntry) {
      addValueToTable()
      setCommitEntry(false)
    }
  }, [commitEntry])

  const columns: any[] = [
    { title: 'Series', field: 'series', sorting: true },
    { title: 'Description', field: 'description', sorting: true },
    { title: '_id', field: 'id', type: 'numeric', hidden: true },
    {
      title: "Delete",
      field: "button",
      sorting: false,
      render: (rowData: any) =>
        rowData && (
          <>
            <IconButton
              title='Remove Series'
              aria-label='Remove Series'
              sx={{ color: 'red' }}
              onClick={() =>
                deleteRecord(rowData)
              }
            >
              <HighlightOffIcon />
            </IconButton>
          </>)
    }
  ]

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
    () =>
      sort(comparisonSettingsSetters.comparisonSettingsSetters.tickerList, getComparator(sortOrder, sortColumn)),
    [sortOrder, sortColumn, comparisonSettingsSetters.comparisonSettingsSetters.tickerList],
  )

  return (
    <div>
      <Grid container spacing={3} columns={25} sx={{ paddingBottom: 2 }}>
        <Grid item md={5}>
          <TextField
            value={comparisonSettingsSetters.comparisonSettingsSetters.comparisonType}
            onChange={(e) => {
              gaLogEvent('Updated Chart Options Comparison Type', gaFinaeonWebAppEventCategories.Graph, 'Graph comparisonType')
              comparisonSettingsSetters.comparisonSettingsSetters.setComparisonType(e.target.value)
            }}
            select
            label='Comparison Types'
            sx={{ minWidth: 200 }}
            size={'medium'}
            disabled={isTrialUser}
          >
            <MenuItem value={'none'}>None</MenuItem>
            <MenuItem value={'compare'}>Compare Series</MenuItem>
            <MenuItem value={'divideby'}>Divide By</MenuItem>
            <MenuItem value={'subtractfrom'}>Subtract From</MenuItem>
          </TextField>
        </Grid>
        {comparisonSettingsSetters.comparisonSettingsSetters.comparisonType === 'compare' &&
          <Grid item md={8}>
            <TextField
              value={comparisonSettingsSetters.comparisonSettingsSetters.comparisonScale}
              onChange={(e) => {
                gaLogEvent('Updated Chart Options Comparison Scale', gaFinaeonWebAppEventCategories.Graph, 'Graph comparisonScale')
                comparisonSettingsSetters.comparisonSettingsSetters.setComparisonScale(e.target.value)
              }}
              select
              label='Comparison Scale'
              sx={{ minWidth: 200 }}
              size={'medium'}
            >
              <MenuItem value={'direct'}>Direct</MenuItem>
              <MenuItem value={'indirect'}>Indirect</MenuItem>
              <MenuItem value={'dualscale'}>Dual Scale</MenuItem>
              <MenuItem value={'percentage'}>Percentage</MenuItem>
            </TextField>
          </Grid>
        }
      </Grid>
      {['divideby', 'subtractfrom'].includes(comparisonSettingsSetters.comparisonSettingsSetters.comparisonType) &&
      <Grid container spacing={2} columns={15} sx={{ paddingBottom: 1 }}>
          <Grid item md={10}>
              <Typography color='blue'>"Divide By" and "Subtract From" comparison settings apply only to Line graph(s), Mountain and Column graph types</Typography>
          </Grid>
      </Grid>
      }
      {comparisonSettingsSetters.comparisonSettingsSetters.comparisonType !== 'none' &&
        <>
          <Grid container spacing={5} columns={25} sx={{ paddingBottom: 2 }}>
            <Grid item md={1}>
              <Tooltip title='Add Ticker to Compare List'>
                <IconButton
                  onClick={addValueToTable}
                >
                  <AddIcon sx={{ minHeight: 40, minWidth: 40 }} color='success' />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={24} minWidth={'75%'}>
              <Autocomplete
                disablePortal
                key={comparisonSettingsSetters.comparisonSettingsSetters.clearText}
                id='addGraphComparison'
                options={autoCompleteOptions}
                style={{ minWidth: '100%' }}
                size='medium'
                clearOnBlur={false}
                onChange={(e: any, value: any) => {
                  if (e?.key === 'Enter') {
                    if (value) {
                      const parsedSeries: string = parseSeriesAutoCompleteText(value.value)
                      comparisonSettingsSetters.comparisonSettingsSetters.setSearchTerm(parsedSeries)
                      setCommitEntry(true)
                    }
                    return
                  }
                  if (e?.target?.innerText) {
                    e.preventDefault()
                    const parsedSeries: string = parseSeriesAutoCompleteText(e.target.innerText)
                    comparisonSettingsSetters.comparisonSettingsSetters.setSearchTerm(parsedSeries)
                    setCommitEntry(true)
                  } else {
                    comparisonSettingsSetters.comparisonSettingsSetters.setSearchTerm('')
                  }
                }}
                renderInput={(params) =>
                  <TextField {...params}
                    label='Search Ticker to Compare'
                    value={comparisonSettingsSetters.comparisonSettingsSetters.searchTerm}
                    onChange={(e) => {
                      e.preventDefault()
                      setTableDataOnInput(e.target.value)
                    }}
                  />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} columns={25} sx={{ paddingBottom: 2 }} >
            <Grid item md={25}>
              <ThemeProvider theme={DefaultMaterialTheme}>
                <MaterialTable
                  columns={columns}
                  data={sortedData}
                  title='Tickers for Comparison'
                  options={{
                    draggable: false,
                    selection: false,
                    search: false,
                    padding: 'dense',
                    toolbarButtonAlignment: 'left',
                    headerStyle: {
                      fontWeight: 'bold'
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100],
                    paginationType: 'stepped',
                    maxBodyHeight: '500px',
                    emptyRowsWhenPaging: false,
                  }}
                  onSelectionChange={(rows, event: any) => {
                    if (event === undefined) {
                      comparisonSettingsSetters.comparisonSettingsSetters.setSelectedValues([...rows])
                    } else {
                      if ([...comparisonSettingsSetters.comparisonSettingsSetters.selectedValues].filter(a => a === event?.id).length === 0) {
                        //THIS WILL SAVE JUST IDS CHANGE TO EVENT IF YOU WANT WHOLE ROW
                        comparisonSettingsSetters.comparisonSettingsSetters.setSelectedValues([...comparisonSettingsSetters.comparisonSettingsSetters.selectedValues, event?.id])
                      }
                      else {
                        comparisonSettingsSetters.comparisonSettingsSetters.setSelectedValues([...comparisonSettingsSetters.comparisonSettingsSetters.selectedValues].filter(a =>
                          a !== event?.id
                        ))
                      }
                    }
                  }}
                  components={{
                    Header:
                      props => (
                        <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns }} />
                      )
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </>
      }
    </div>
  )
}

export default ComparisonSettings
