import { Autocomplete, TextField, Tooltip } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import React, { useEffect, useState } from 'react'
import { GraphRequest, SeriesChartOptionsRequest } from '../../../Models/DataModels/Requests/GraphRequests'
import { AutoCompleteRequest } from '../../../Models/DataModels/Requests/SearchRequests'
import { autoComplete, autoCompleteLabelValues } from '../../../Services/SearchService'
import GraphOptionsModal, { GraphOptionsModalProps } from '../../Common/Modals/GraphOptionsModal'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DateRangeSelections } from '../../../Models/DataModels/Common/ToolsModel'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import { SeriesChartOptionsResult } from '../../../Models/DataModels/Responses/GraphResponses'
import { getSeriesChartOptions } from '../../../Services/GraphService'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { getSessionStorageOrDefault, sessionStorageKeys } from '../../../Services/SessionStorageService'
import { CustomBtnBlue, BtnGraphSearch, SecondaryThemeSiteThemeHoverButton } from '../../Common/GlobalSettings/CustomStyles'
import LoadingScreen from '../../Common/Modals/LoadingScreen'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface GraphSearchProps {
    viewChart: (graphRequest: GraphRequest) => void,
    modalDataFromPreviousRequest: any,
    checkIsTrial: () => boolean
}

const GraphSearch = ({
    viewChart,
    modalDataFromPreviousRequest,
    checkIsTrial
}: GraphSearchProps) => {

    const navigate = useNavigate()
    const { symbol } = useParams()
    const location: any = useLocation()

    const [searchTermGraphDisplay, setSearchTermGraphDisplay] = useState<any>(modalDataFromPreviousRequest?.primaryTicker ? modalDataFromPreviousRequest.primaryTicker : '')
    const [searchTermGraph, setSearchTermGraph] = useState<any>(modalDataFromPreviousRequest?.primaryTicker ? modalDataFromPreviousRequest.primaryTicker : '')
    const [commitGraphSearch, setCommitGraphSearch] = useState<boolean>(false)
    const [commitSymbolGraphSearch, setCommitSymbolGraphSearch] = useState<boolean>(false)

    const [autoCompleteOptionsGraph, setAutoCompleteOptionsGraph] = useState<LabelValuePair[]>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [graphingOptionsResult, setGraphingOptionsResult] = useState<any>(modalDataFromPreviousRequest)
    const [initialAutoCompleteList, setInitialAutoCompleteList] = useState<string[]>([])
    const [chartExtraOptions, setChartExtraOptions] = useState<SeriesChartOptionsResult | null>(null)

    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [navPayload, setNavPayload] = useState<any | null>(null)

    const savedGraphSeries: any[] = getSessionStorageOrDefault(sessionStorageKeys.selectedResultsForGraphData, [])
    const compareGraphSeries: any[] = getSessionStorageOrDefault(sessionStorageKeys.selectedComparisonGraphData, [])

    //This function will also run the autocomplete in it
    const setValueForAutoComplete = (textValue: string) => {
        const req: AutoCompleteRequest = {
            searchTerm: textValue,
            database: SearchDatabaseTypes.GFDUniverse,
            matchOption: 'Contains',
            sortOption: 'Popular'
        }
        autoCompleteLabelValues(req).then((res) => {
            setAutoCompleteOptionsGraph(res)
        }, () => { })
        //run autocomplete end point here and then set the value of search

        setSearchTermGraph(textValue)
        setSearchTermGraphDisplay(textValue)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const handleSuccess = (result: any) => {
        setGraphingOptionsResult(result)
        setShowModal(false)
        setCommitGraphSearch(true)
    }

    const _showModal = () => {

        if (modalDataFromPreviousRequest.comparisonSettingsSetters.symbolList.length > 0) {
            let autoCompleteList: string[] = []

            modalDataFromPreviousRequest.comparisonSettingsSetters.symbolList.forEach((series: string, index: number) => {

                const req: AutoCompleteRequest = {
                    searchTerm: series,
                    database: SearchDatabaseTypes.GFDUniverse,
                    matchOption: 'exactMatch',
                    sortOption: 'Popular'
                }

                autoComplete(req).then((res: string[]) => {
                    autoCompleteList.push(res[0])

                    if (modalDataFromPreviousRequest.comparisonSettingsSetters.symbolList.length === autoCompleteList.length) {

                        setInitialAutoCompleteList(autoCompleteList)
                    }

                }, () => { })
            })

        }
        else {
            setShowModal(true)
        }
    }

    const modalProps: GraphOptionsModalProps = {
        searchTerm: searchTermGraph,
        modalData: graphingOptionsResult,
        showModal: showModal,
        setShowModal: setShowModal,
        handleClose: handleClose,
        handleSuccess: handleSuccess,
        extraOptions: chartExtraOptions,
        checkIsTrial: checkIsTrial
    }

    const processReturnSymbols = () => {
        const returnVal: any = []
        if (graphingOptionsResult.comparisonSettingsSetters.tickerList.length > 0) {
            graphingOptionsResult.comparisonSettingsSetters.tickerList.forEach((a: any) => {
                returnVal.push(a.series)
            })
        }
        return returnVal
    }

    const goBack = () => {
        gaLogEvent('Clicked on Graph Back Button', gaFinaeonWebAppEventCategories.Graph, 'goBack')
        const fromGraphNavPayload: any = { state: { ...navPayload, prevPage: paths.graph.lineGraph } }
        if (navPayload?.prevPage) {
            navigate(navPayload.prevPage, fromGraphNavPayload)
        } else {
            navigate(`${paths.searchHome.base}`, fromGraphNavPayload)
        }
    }

    const doGraphSearch = () => {
        gaLogEvent('Clicked on Graph Button / Searched', gaFinaeonWebAppEventCategories.Graph, 'Graph')
        if (searchTermGraph.length > 0) {
            const graphRequest: GraphRequest = {
                primaryTicker: searchTermGraph
            }

            if (graphingOptionsResult) {
                if (graphingOptionsResult.chartSettingsSetters) {
                    graphRequest.settings = {
                        chartType: graphingOptionsResult.chartSettingsSetters.graphType,
                        scaleType: graphingOptionsResult.chartSettingsSetters.scaleType,
                        years: graphingOptionsResult.chartSettingsSetters.currentDateSelection ? graphingOptionsResult.chartSettingsSetters.currentDateSelection : DateRangeSelections.Max,
                        isCustomDate: graphingOptionsResult.chartSettingsSetters.currentDateMode === 'selectDate' ? false : true,
                        startDate: graphingOptionsResult.chartSettingsSetters.currentStartDate,
                        endDate: graphingOptionsResult.chartSettingsSetters.currentEndDate,
                        currency: graphingOptionsResult.chartSettingsSetters.currencyType,
                        periodicity: graphingOptionsResult.chartSettingsSetters.periodicity,
                        showSplits: graphingOptionsResult.chartSettingsSetters.showSplits,
                        showDividends: graphingOptionsResult.chartSettingsSetters.showDividends,
                        splitAdjusted: graphingOptionsResult.chartSettingsSetters.splitAdjusted,
                        inflationAdjusted: graphingOptionsResult.chartSettingsSetters.inflationAdjusted,
                        totalReturn: graphingOptionsResult.chartSettingsSetters.totalReturn,
                        percentGDP: graphingOptionsResult.chartSettingsSetters.percentGDP,
                        annualPercentChange: graphingOptionsResult.chartSettingsSetters.annualPctChg,
                        movingAverages: graphingOptionsResult.chartSettingsSetters.movingAvg,
                        includeVolumeChart: graphingOptionsResult.chartSettingsSetters.includeVolumeChart
                    }
                }

                if (graphingOptionsResult.technicalIndicatorsSetters) {
                    graphRequest.indicators = {
                        chartIndicators: graphingOptionsResult.technicalIndicatorsSetters.chartIndicatorObject,
                        technicalIndicators: graphingOptionsResult.technicalIndicatorsSetters.technicalIndicatorObject,
                        valueIndicators: {
                            valueIndicatorCode: graphingOptionsResult.technicalIndicatorsSetters.valueIndicator,
                            isPrimaryGraph: graphingOptionsResult.technicalIndicatorsSetters.isValueIndicatorOnPrimaryGraph
                        },
                        financialRatios: {
                            financialRatioCode: graphingOptionsResult.technicalIndicatorsSetters.financialRatio
                        }
                    }
                }

                if (graphingOptionsResult.comparisonSettingsSetters) {
                    graphRequest.comparison = {
                        type: graphingOptionsResult.comparisonSettingsSetters.comparisonType,
                        scale: graphingOptionsResult.comparisonSettingsSetters.comparisonScale,
                        symbols: processReturnSymbols()
                    }
                }

                if (graphingOptionsResult.chronologySettings) {
                    graphRequest.chronology = {
                        type: graphingOptionsResult.chronologySettings.chronology
                    }
                }
            }
            viewChart(graphRequest)
        }
    }

    useEffect(() => {
        setGraphingOptionsResult(modalDataFromPreviousRequest)
    }, [modalDataFromPreviousRequest])

    useEffect(() => {
        if (modalDataFromPreviousRequest?.primaryTicker && modalDataFromPreviousRequest?.primaryTicker !== '') {
            navigate(`${paths.graph.lineGraph}/${searchTermGraph}`)
        } else {
            navigate(`${paths.graph.lineGraph}`)
        }
    }, [modalDataFromPreviousRequest.primaryTicker])

    useEffect(() => {
        if (searchTermGraph !== '') {
            return
        }
        if (!symbol) {
            return
        }

        setSearchTermGraph(symbol)
        setSearchTermGraphDisplay(symbol)

        setCommitSymbolGraphSearch(true)
    }, [symbol])

    useEffect(() => {
        if (!commitSymbolGraphSearch) {
            return
        }

        if (savedGraphSeries.length > 0) {

            let symbolList: any[] = []
            if (compareGraphSeries?.length > 0) {
                symbolList = compareGraphSeries
            }

            const req: GraphRequest = {
                primaryTicker: searchTermGraph as string,
                comparison: {
                    type: 'compare',
                    scale: 'indirect',
                    symbols: symbolList
                }
            }


            viewChart(req as GraphRequest)
        } else {
            viewChart({ primaryTicker: searchTermGraph } as GraphRequest)
        }

        setCommitSymbolGraphSearch(false)
    }, [commitSymbolGraphSearch])


    useEffect(() => {
        if (commitGraphSearch) {
            if (Object.keys(graphingOptionsResult).length > 0) {
                doGraphSearch()
            }
            setCommitGraphSearch(false)
        }
    }, [commitGraphSearch])

    useEffect(() => {
        if (initialAutoCompleteList.length === 0) {
            return
        }

        const symbolsList: string[] = modalDataFromPreviousRequest.comparisonSettingsSetters.symbolList
        const initialSeriesDescription: any[] = []
        initialAutoCompleteList.forEach((seriesDescription: string, index: number) => {
            const [first, ...rest] = seriesDescription.split('~')
            const tempTickerData = {
                series: first.trim(),
                description: rest
            }
            if (symbolsList.includes(tempTickerData.series.toLocaleUpperCase())) {
                initialSeriesDescription.push(tempTickerData)
            }
        })
        const initialSeriesDescriptionSortedByOriginalOrder: any[] = []
        symbolsList.forEach((seriesName: string) => {
            const currentSeriesDescription: any = initialSeriesDescription.find((sDescription: any) => sDescription.series === seriesName)
            if (currentSeriesDescription) {
                initialSeriesDescriptionSortedByOriginalOrder.push(currentSeriesDescription)
            }
        })
        modalDataFromPreviousRequest.comparisonSettingsSetters.tickerList = initialSeriesDescriptionSortedByOriginalOrder
        setGraphingOptionsResult(modalDataFromPreviousRequest)

        if (modalDataFromPreviousRequest.comparisonSettingsSetters.tickerList.length === modalDataFromPreviousRequest.comparisonSettingsSetters.symbolList.length) {
            setShowModal(true)
        }

    }, [initialAutoCompleteList])

    useEffect(() => {
        if (!showModal) {
            return
        }

        const searchTerm: string = modalProps.searchTerm.trim()
        if (searchTerm === '') {
            setChartExtraOptions(null)
            return
        }

        const checkSeriesList: string[] = [graphingOptionsResult.primaryTicker]
        if (graphingOptionsResult?.comparisonSettingsSetters?.symbolList?.length > 0) {
            checkSeriesList.push(...graphingOptionsResult?.comparisonSettingsSetters?.symbolList)
        }

        const requestCheckSeriesListParam: string = checkSeriesList.join(',')

        const extraOptionsRequest: SeriesChartOptionsRequest = {
            seriesListString: requestCheckSeriesListParam
        }

        setShowLoadingModal(true)
        getSeriesChartOptions(extraOptionsRequest).then(
            (extraOptionsResult: SeriesChartOptionsResult) => {
                setChartExtraOptions(extraOptionsResult)
                setShowLoadingModal(false)
            },
            // Reject Promise
            () => {
                setChartExtraOptions(null)
                setShowLoadingModal(false)
            })
    }, [showModal])

    useEffect(() => {
        if (location?.state) {
            setNavPayload(location.state)
        }

        if (symbol && symbol !== searchTermGraphDisplay) {
            if (symbol !== searchTermGraphDisplay.substr(0, symbol.length)) {
                setSearchTermGraphDisplay(symbol)
            }
        }
    }, [location])

    return (
        <div style={graphStyles.searchRow}>
            <Tooltip title='Go Back'>
                <CustomBtnBlue id='graphSettings' variant='contained' style={{ marginLeft: 10, marginRight: 5 }} onClick={goBack}>
                    <ArrowBackIcon />
                </CustomBtnBlue>
            </Tooltip>
            <CustomBtnBlue id='graphSettings' variant='contained' style={{ marginRight: 5 }}
                onClick={() => {
                    gaLogEvent('Clicked on Graph Options Button', gaFinaeonWebAppEventCategories.Graph, 'Graph Options')
                    _showModal()
                }}>
                <SettingsIcon /> Options
            </CustomBtnBlue>
            <SecondaryThemeSiteThemeHoverButton id='graphSearch' variant='contained' style={{ borderRadius: '5px 0px 0px 5px' }} onClick={doGraphSearch} disabled={checkIsTrial()}>
                Graph
            </SecondaryThemeSiteThemeHoverButton>
            <Autocomplete
                disablePortal
                id='graphQuikSearch'
                options={autoCompleteOptionsGraph}
                value={searchTermGraphDisplay}
                sx={{ width: '100%' }}
                size='small'
                onChange={(e, value: LabelValuePair) => {
                    if (value) {
                        setSearchTermGraph(value.value)
                        setSearchTermGraphDisplay(value.label)
                        setCommitGraphSearch(true)
                    } else {
                        setSearchTermGraph('')
                        setSearchTermGraphDisplay('')
                    }
                }}
                renderInput={(params) => <TextField {...params}
                    label='GFD Graph Quik Search, Start Typing and Select an Option to Graph'
                    onChange={(e) => {
                        e.preventDefault()
                        setValueForAutoComplete(e.target.value)
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === 'Enter') {
                            setCommitGraphSearch(true)
                        }
                    }}
                />}
                disabled={checkIsTrial()}
            />
            {showModal ? <GraphOptionsModal {...modalProps} /> : <></>}
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
        </div>
    )
}

const graphStyles = {
    searchInput: {
        marginRight: 5,
        borderColor: '#ccc'
    },
    searchBtn: {
        marginRight: 5,
        borderColor: '#ccc'
    },
    searchRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 10,
        paddingBottom: 5
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        position: 'relative'
    }
}

export default GraphSearch
