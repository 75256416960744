import { Accordion, AccordionDetails, Button, Chip, CircularProgress, FormLabel, IconButton, TextField, Tooltip, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { DownloadProperties, TickerData } from '../../../Models/DataModels/Responses/AutoTracResponses'
import { CreateWorkbookDownloadRequest, DownloadRequestSource, DownloadRequestSourceValueType } from '../../../Models/DataModels/Requests/AutoTracRequests'
import { directDownloadWorkbook, downloadFile, downloadProperties, downloadWorkbook } from '../../../Services/AutoTracService'
import AlertModal, { AlertButtonType, AlertModalProps } from './AlertModal'
import DownloadOptions, { DownloadOptionsProps } from '../Utility/DownloadOptions'
import { CreateDownloadSettingsModelWithDefaults, DownloadSettingsModel } from '../../../Models/DataModels/Common/DownloadSettingsModel'
import moment from 'moment'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../Utility/ComponentMessageHandler'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { MessageResponse, MessageResponseValueType } from '../../../Models/DataModels/Responses/NotOKResponse'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { useNavigate } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import React from 'react'
import { GFDToastSuccess } from '../Utility/GFDToastify'
import { Info } from '@mui/icons-material'
import DownloadMembers from '../Utility/DownloadMembers'
import TrialUserLock, { trialUserDownloadOptionsMessage } from '../Utility/TrialUserLock'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface DownloadModalProps {
  workbookName?: string,
  tickers?: TickerData[],
  seriesMinDate?: moment.Moment | null,
  seriesMaxDate?: moment.Moment | null,
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  showDownloadModal: boolean,
  setShowDownloadModal: (value: boolean) => void,
  downloadRequestSource?: DownloadRequestSourceValueType,
  directDownload: boolean,
  isComposite: boolean
}

const DownloadModal = ({
  workbookName,
  tickers,
  checkIsTrial,
  signOut,
  showDownloadModal,
  setShowDownloadModal,
  downloadRequestSource,
  directDownload,
  isComposite
}: DownloadModalProps) => {
  const navigate = useNavigate()

  const getDefaultDownloadSettings = (): DownloadSettingsModel => {
    const settings = CreateDownloadSettingsModelWithDefaults()
    if (tickers) settings.Series = tickers.map(ticker => ticker.id)
    settings.SecuritiesIncluded = isSecuritiesIncluded
    settings.CorporateActionsIncluded = isCorporateActionsIncluded

    if (!containsSplitAdjusted) {
      settings.Adjustments.SplitAdjusted = false
    }
    settings.hasSplitAdjusted = containsSplitAdjusted
    settings.hasInflationAdjusted = containsInflationAdjusted
    settings.hasPerCapita = containsPerCapita
    settings.hasPercentGDP = containsPercentGDP

    // reset to min and max, if given
    const InvalidDate = 'Invalid date'
    if (seriesMinDate && seriesMinDate.format() !== InvalidDate) {
      settings.FromDate = seriesMinDate
      settings.FromYear = seriesMinDate.year()
    }
    if (seriesMaxDate && seriesMaxDate.format() !== InvalidDate) {
      settings.ToDate = seriesMaxDate
      settings.ToYear = seriesMaxDate.year()
    }

    return settings
  }
  const [name, setName] = useState<string>(workbookName || '')
  const [isNameError, setIsNameError] = useState<boolean>(false)
  const [nameError, setNameError] = useState<string>()
  const [isSecuritiesIncluded, setIsSecuritiesIncluded] = useState<boolean>(false)
  const [isCorporateActionsIncluded, setIsCorporateActionsIncluded] = useState<boolean>(false)
  const [containsSplitAdjusted, setContainsSplitAdjusted] = useState<boolean>(true)
  const [containsInflationAdjusted, setContainsInflationAdjusted] = useState<boolean>(true)
  const [containsPerCapita, setContainsPerCapita] = useState<boolean>(true)
  const [containsPercentGDP, setContainsPercentGDP] = useState<boolean>(true)
  const [seriesMinDate, setSeriesMinDate] = useState<moment.Moment | null>()
  const [seriesMaxDate, setSeriesMaxDate] = useState<moment.Moment | null>()
  const [currentDownloadSettings, setCurrentDownloadSettings] = useState<DownloadSettingsModel>(getDefaultDownloadSettings())
  const [savedDownloadSettings, setSavedDownloadSettings] = useState<DownloadSettingsModel | undefined>(undefined)
  const [disableSaveDownloadSettings, setDisableSaveDownloadSettings] = useState<boolean>(false)
  const [triggerSaveDownloadSettings, setTriggerSaveDownloadSettings] = useState<boolean>(false)
  const [showFinishModal, setShowFinishModal] = useState<boolean>(false)

  const [messages, setMessages] = useState<MessageResponse[]>([])
  const [response, setResponse] = useState<NotOKResponseModel | null>()

  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const nameTooltip = 'Name cannot contain special characters, allowed characters are alphanumeric, hyphen, space, underscore, parentheses, period, ampersand, percent, comma, colon and plus. Maximum length is 50.'

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setResponse(model as NotOKResponseModel)
      return
    }
  }

  const clearAlertsInCurrentWindow = () => {
    setMessages([])
    setResponse(null)
  }

  const clearNameError = () => {
    setIsNameError(false)
    setNameError('')
  }
  const clearAlert = () => {
    clearAlertsInCurrentWindow()
    clearNameError()
  }

  const validate = (): boolean => {
    if (!directDownload && !name) {
      setIsNameError(true)
      setNameError('Please enter a name')
      return false
    }
    clearNameError()
    return true
  }

  const downloadFileFromServer = (file: string) => {
    clearAlert()

    downloadFile(file).then(async (response: any) => {
      const b = await response.blob()
      let a = document.createElement('a')
      let url = URL.createObjectURL(b)
      a.href = url
      a.download = file
      a.click()
      GFDToastSuccess(`${file} downloaded!`)
      setShowDownloadModal(false)
      setDownloadInProgress(false)
    }, () => { }).catch(err => {
      console.log(err)
      setShowDownloadModal(false)
      setDownloadInProgress(false)
    })
  }

  useEffect(() => {
    clearAlertsInCurrentWindow()
    downloadProperties({
      tickerIDs: tickers?.map(ticker => ticker.id) || [],
      isComposite
    })
      .then((response: DownloadProperties) => {
        setSeriesMinDate(moment(response.seriesMinDate))
        setSeriesMaxDate(moment(response.seriesMaxDate))
        setIsSecuritiesIncluded(response.hasSecurities)
        setIsCorporateActionsIncluded(response.hasCorporateActions)
        setContainsSplitAdjusted(response.hasSplitAdjusted)
        setContainsInflationAdjusted(response.hasInflationAdjusted)
        setContainsPerCapita(response.hasPerCapita)
        setContainsPercentGDP(response.hasPercentGDP)
      },
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
        })

  }, [tickers])

  useEffect(() => {
    if (!showDownloadModal) {
      clearAlert()
      setName('')
      clearNameError()
    }
  }, [showDownloadModal])

  useEffect(() => {
    setCurrentDownloadSettings(getDefaultDownloadSettings())
  }, [seriesMaxDate, seriesMinDate, isSecuritiesIncluded, isCorporateActionsIncluded, containsSplitAdjusted, containsInflationAdjusted, containsPerCapita, containsPercentGDP])

  useEffect(() => {
    if (savedDownloadSettings && validate()) {

      // save downloadsettings and queue download entry
      const downloadRequest = CreateWorkbookDownloadRequest(name, savedDownloadSettings, DownloadRequestSource.OnDemand, isComposite)

      setDownloadInProgress(true)
      directDownload ?
        directDownloadWorkbook(downloadRequest)
          .then((result: any) => {
            downloadFileFromServer(result)
          },
            (notOKResponseModel: NotOKResponseModel) => {
              displayResponse(notOKResponseModel)
              setDownloadInProgress(false)
            })
        :
        downloadWorkbook(downloadRequest)
          .then((result: any) => {
            setShowFinishModal(true)
            setDownloadInProgress(false)
          },
            (notOKResponseModel: NotOKResponseModel) => {
              displayResponse(notOKResponseModel)
              setDownloadInProgress(false)
            })
    }
  }, [savedDownloadSettings])

  const resetTriggerDownloadSettings = () => {
    setTriggerSaveDownloadSettings(false)
  }

  const onDownloadOKClick = () => {
    gaLogEvent('Clicked on Direct/Enqueue Download Button', gaFinaeonWebAppEventCategories.DownLoad, 'onDownloadOKClick')
    if (!disableSaveDownloadSettings) {
      setTriggerSaveDownloadSettings(true)
    }
  }

  const onDownloadCancelClick = () => {
    setShowDownloadModal(false)
  }

  const finishModalProps: AlertModalProps = {
    showModal: showFinishModal,
    setShowModal: setShowFinishModal,
    AlertTitle: 'Download Queued',
    AlertContent: `Workbook [${name}] queued for download.`,
    AlertButtons: [
      {
        type: AlertButtonType.OK,
        display: 'OK',
        onClick: () => {
          setShowDownloadModal(false)
          setShowFinishModal(false)
        },
        isPrimary: false
      },
      {
        type: AlertButtonType.OK,
        display: 'Go to Download Queue',
        onClick: () => {
          setShowDownloadModal(false)
          setShowFinishModal(false)
          navigate(paths.autoTrac, { state: { expanded: true } })
        },
        isPrimary: true
      }
    ],
    onAlertClose: () => {
      return true
    }
  }

  const downloadOptionsProps: DownloadOptionsProps = {
    downloadSettings: currentDownloadSettings,
    setDisableSaveDownloadSettings: setDisableSaveDownloadSettings,
    saveDownloadSettings: setSavedDownloadSettings,
    triggerSaveDownloadSettings: triggerSaveDownloadSettings,
    resetTriggerDownloadSettings: resetTriggerDownloadSettings,
    seriesMinDate,
    seriesMaxDate,
    downloadRequestSource,
    tickers,
    checkIsTrial,
    signOut
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlert()

    if (AppConfigurations.workbookNameRegEx.test(event.target.value)) {
      setName(event.target.value)
    }
  }

  const renderEditName = () => {
    return (
      <>
        <FormLabel
          id='nameLabel'
          key='nameLabel'
          style={styles.formLabel}
        >
          {'Name' + (directDownload ? ' (Optional)' : '')}
        </FormLabel>
        <TextField
          error={isNameError}
          helperText={nameError}
          style={styles.editContainer}
          value={name}
          variant='outlined'
          onChange={handleChange}
          sx={styles.edit}
          autoFocus
          inputProps={{
            type: 'text',
            maxLength: 50,
            'title': nameTooltip,
            'aria-label': nameTooltip
          }}
        />
      </>
    )
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response,
    signOut
  }

  const getDownloadModalTitle = () => {
    const title = <div>Download Options 
      {checkIsTrial() && <div style={{margin: '5px', display: 'inline-block'}}>
            <TrialUserLock checkIsTrial={checkIsTrial} message={trialUserDownloadOptionsMessage} />
      </div>}
      {downloadInProgress ? <IconButton title='Download in Progress'
        aria-label='Download in Progress' component='label' sx={{
          p: '10px',
          color: '#1976d2',
        }}>
        <CircularProgress title='Download in Progress' aria-label='Download in Progress' />
      </IconButton> : disableSaveDownloadSettings || nameError ? <IconButton title='Error(s) in form input'
        aria-label='Error(s) in form input' component='label' sx={{
          p: '10px',
          color: 'red',
        }}>
        <Info />
      </IconButton> : <></>}
    </div>
    return <>
      <ComponentMessageHandler {...componentMessageHandlerProps} />
      {title}

      {workbookName ? <>

        <h5 style={styles.modalTitleWorkbook}>{workbookName}</h5>
      </> : <>
        {renderEditName()}
      </>}
    </>
  }

  const getDownloadModalBody = () => {
    return <div style={{ overflow: 'auto' }}>
      <DownloadMembers tickers={tickers} />
      <DownloadOptions {...downloadOptionsProps} />
    </div>
  }

  const downloadModalProps: AlertModalProps = {
    showModal: showDownloadModal,
    setShowModal: setShowDownloadModal,
    AlertTitle: getDownloadModalTitle(),
    AlertContent: getDownloadModalBody(),
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: onDownloadCancelClick,
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: directDownload ? 'Direct Download' : 'Enqueue Download',
        onClick: onDownloadOKClick,
        isPrimary: true,
        disable: disableSaveDownloadSettings || downloadInProgress,
        style: { textTransform: 'capitalize' }
      }
    ],
    onAlertClose: () => {
      return true
    },
    Size: 'xl'
  }

  return (
    <>
      <AlertModal {...downloadModalProps} />
      <AlertModal {...finishModalProps} />
    </>
  )
}

const styles = {
  formLabel: {
    color: 'rgb(33,37,41)',
    margin: '0px'
  },
  editContainer: {
    width: '100%'
  },
  edit: {
    input: {
      padding: '4px 8px',
      backgroundColor: 'whitesmoke',
      fontSize: 'default'
    }
  },
  modalTitleWorkbook: {
    fontStyle: 'italic',
    marginTop: '8px'
  }
}

export default DownloadModal
