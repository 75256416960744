import { Autocomplete, LabelDisplayedRowsArgs, Table, TableBody, TablePagination, TableRow, TextField } from "@mui/material"
import { CSSProperties, useEffect, useState } from "react"
import { formatNumberEnUSLocalString } from "../../../Services/UtilityService"

export interface GFDTablePaginationProps {
  perPageCount: number,
  pageNumber: number,
  totalCount: number,
  setPageNumber: (page: number) => void,
  setPerPageCount: (perPage: number) => void,
  changeAction?: () => void,
  readOnly?: boolean
}

const GFDTablePagination = (
  pageData: GFDTablePaginationProps
) => {
  const getMaxPageCount = (): number => {
    if (!pageData.totalCount) return 0
    return Math.ceil(pageData.totalCount / pageData.perPageCount)
  }

  const getPageList = (): number[] => {
    const maxPageNumber = getMaxPageCount()
    const list: number[] = []
    let i: number
    if (maxPageNumber) {
      for (i = 1; i <= maxPageNumber; i++) {
        list.push(i)
      }
    } else {
      list.push(0)
    }
    return list
  }
  const [pageList, setPageList] = useState<number[]>(getPageList())
  const [maxPageCount, setMaxPageCount] = useState<number>(getMaxPageCount())

  useEffect(() => {
    setMaxPageCount(getMaxPageCount())
    setPageList(getPageList())
  }, [pageData.perPageCount, pageData.totalCount])

  const getPageLabel = () => {
    return pageData.totalCount ? `Page (${pageData.pageNumber} of ${formatNumberEnUSLocalString(maxPageCount)})` : 'Page'
  }

  const readOnlyProp: CSSProperties = pageData.readOnly ?? false ? { pointerEvents: 'none' } : {}

  return (
    <>
      <td style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'stretch', ...readOnlyProp }}>

        <div style={{
          width: '200px', height: '100%', display: 'flex', flexDirection: 'column', borderBottom: '1px solid rgba(224, 224, 224, 1)',
          alignItems: 'stretch', justifyContent: 'center'
        }}>
          <Autocomplete
            style={{ marginRight: '8px', marginLeft: '8px' }}
            disablePortal
            id='selectPage'
            disableClearable
            options={pageList}
            value={pageData.totalCount ? pageData.pageNumber : 0}
            onChange={(event: any, newValue: number) => {
              pageData.setPageNumber(newValue)
            }}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => {
              return option.toString()
            }}
            renderInput={(params) =>
              <TextField {...params}
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
                label={getPageLabel()}
                size='small'
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key) && !['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
                    event.preventDefault()
                  }
                }}
              />
            }
          />
        </div>

        <Table><TableBody><TableRow>
          <TablePagination
            style={{ flex: 1, paddingRight: '20px', backgroundColor: '#FFF' }}
            showFirstButton
            showLastButton
            rowsPerPageOptions={[10, 25, 50, 100]}
            rowsPerPage={pageData.perPageCount}
            count={pageData.totalCount || 0}
            page={pageData.pageNumber > 0 ? pageData.pageNumber - 1 : 0}
            onPageChange={(e: any, page: number) => {
              pageData.setPageNumber(page + 1)
              if (pageData.changeAction) {
                pageData.changeAction()
              }
            }}
            onRowsPerPageChange={(event: { target: { value: any } }) => {
              pageData.setPerPageCount(event.target.value)
              if (pageData.changeAction) {
                pageData.changeAction()
              }
            }}
            labelDisplayedRows={
              ({ from, to, count }: LabelDisplayedRowsArgs) => {
                return `${from}–${to} of ${count !== -1 ? formatNumberEnUSLocalString(count) : `more than ${to}`}`
              }
            }
          />
        </TableRow></TableBody></Table>
      </td>
    </>
  )
}

export default GFDTablePagination
