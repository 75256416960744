import React, { useEffect } from 'react'
import { gaLogPageView } from './analytics'
import { useLocation } from 'react-router-dom'
import { AppConfigurations } from '../../Models/DataModels/Common/AppConfigurationsModel'

const FinaeonGoogleAnalytics = () => {

    const location: any = useLocation()

    useEffect(() => {

        if (AppConfigurations.googleAnalyticsTrackingID !== '') {
            if (location?.pathname) {
                gaLogPageView()
            }
        } else {
        }

    }, [location])

    return (
        <div>

        </div>
    )
}

export default FinaeonGoogleAnalytics
