import { Autocomplete, Box, CircularProgress, ClickAwayListener, Grid, IconButton, Paper, TextField } from '@mui/material'
import { CSSProperties, useEffect, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { CreateYieldDownloadDataRequest, GraphYieldRequest } from '../../../../Models/DataModels/Requests/GraphRequests'
import { createYieldDataFile, graphYieldBySeries } from '../../../../Services/GraphService'
import { getCountriesByGroupYieldCurve, getCountryGroups } from '../../../../Services/FieldPopulationService'
import { getNextChartDarkColor } from '../../../../Services/ColorService'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject } from '../../../Common/Modals/GraphUtil'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { NotOKResponseModel } from '../../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../../Models/DataModels/Common/OutputFormatModel'
import { FileDownloadRequest } from '../../../../Models/DataModels/Requests/ToolsRequests'
import { CreateYieldDownloadDataResponse } from '../../../../Models/DataModels/Responses/GraphResponses'
import { LogoutReasonType } from '../../../../Models/DataModels/Requests/AuthRequests'
import { downloadFileFromServer } from '../../../../Services/DownloadService'
import { CountryGroupRequest } from '../../../../Models/DataModels/Requests/PopulationRequests'
import YieldCurveIcon from '../../../Icons/YieldCurveIcon'
import YieldCurveClearGroupsConfirmationModal from '../../../Common/Modals/YieldCurveClearGroupsConfirmationModal'
import { customLabelToolTip } from '../../../Common/Modals/CustomToolTips'
import { getTooltipMessage, yieldCurveTooltipKeys } from '../../../../Models/DataModels/Common/TooltipContents'
import { AppConfigurations, chartSizes } from '../../../../Models/DataModels/Common/AppConfigurationsModel'
import { globalStyles } from '../../../Common/GlobalSettings/GlobalStyles'
import { ComponentMessageHandler, ComponentMessageHandlerProps } from '../../../Common/Utility/ComponentMessageHandler'
import { MessageResponse, MessageResponseValueType } from '../../../../Models/DataModels/Responses/NotOKResponse'
import { blankEntry } from '../../../../Models/DataModels/Common/FieldPopulationModel'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../Google/analytics'

export interface YieldCurveProps {
  signOut: (logoutReason: LogoutReasonType) => void,
}

const YieldCurve = ({
  signOut
}: YieldCurveProps) => {

  const minimumDate: moment.Moment = moment(`${AppConfigurations.minimumDate}`)
  const maximumDate: moment.Moment = moment()

  const [yieldCountry, setYieldCountry] = useState<any>('')

  const [countryGroup, setCountryGroup] = useState<any>({ label: 'ALL', value: 'ALL' })
  const [countryGroupList, setCountryGroupList] = useState<any[]>([blankEntry])
  const [countriesByGroup, setCountriesByGroup] = useState<any[]>([''])

  const [dataForLines, setDataForLines] = useState<any>()
  const [yieldText, setYieldText] = useState<any>('')
  const [yieldDate, setYieldDate] = useState<moment.Moment>(moment())
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [clearCountryGroup, setClearCountryGroup] = useState<boolean>(false)
  const [countryGroupWatcher, setCountryGroupWatcher] = useState<any>({ label: 'ALL', value: 'ALL' })

  const [yieldDateTracker, setYieldDateTracker] = useState<moment.Moment>(moment())
  const [dateError, setDateError] = useState<string | null>(null)
  const [graphDataInProgress, setGraphDataInProgress] = useState<boolean>(false)

  const [commitSearch, setCommitSearch] = useState<boolean>(false)

  const [messages, setMessages] = useState<MessageResponse[]>([])

  const displayMessage = (message: string, type: MessageResponseValueType) => {
    const componentMessage: MessageResponse = {
      message: message as string,
      type
    }
    setErrorResponse(null)
    setMessages([componentMessage])
    return
  }

  const displayResponse = (model: NotOKResponseModel) => {
    if (model) {
      setMessages([])
      setErrorResponse(model as NotOKResponseModel)
      return
    }
  }

  const resetMessage = () => {
    setMessages([])
    setErrorResponse(null)
  }

  const modalProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    setClearCountryGroup: setClearCountryGroup
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    let dataSymbols: any[] = []
    let dateData: any[] = []

    if (active && payload && payload.length) {
      payload.forEach((dataSet: any, index: number) => {
        return dataSymbols.push(<p key={`yieldValue${index}`} className='label' style={{ color: dataSet.color }}>{dataSet.value}</p>)
      })
    }

    if (active && payload && payload.length) {
      dateData.push(<p key={`yieldDate`} className='label'>{payload[0].payload.date}</p>)
    }

    if (active && payload && payload.length) {
      return (
        <Paper elevation={3} style={{ padding: 5 }} >
          <div className='custom-tooltip'>
            <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Duration:</p>
            {dateData}
            <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Yield Value:</p>
            {dataSymbols}
          </div>
        </Paper>
      )
    }
    return null
  }

  const generateLines = () => {
    let arrayOfJSXLines: any = []
    if (dataForLines && dataForLines.length > 0) {
      dataForLines.forEach((payload: any, index: any) => {
        const color: string = getNextChartDarkColor(index)
        if (Object.keys(payload).length !== 0) {
          Object.keys(payload[0]).map((lineName: string) => {
            if (lineName !== 'date' && lineName !== 'area') {
              arrayOfJSXLines.push(<Line key={`yieldLine${index}`} data={dataForLines[index.toString()]} name={lineName} type='monotone' dataKey={lineName + '.value'} stroke={color} />)
            }
          })
        }
      })
    }

    return arrayOfJSXLines
  }

  const doGraphSearch = () => {
    setYieldDateTracker(yieldDate)

    if (yieldCountry) {
      const graphRequest: GraphYieldRequest = {
        series: "IG" + yieldCountry.value + "10D",
        yieldDate: yieldDate && yieldDate.isValid() ? yieldDate.toISOString() : moment().toISOString()
      }

      setGraphDataInProgress(true)
      graphYieldBySeries(graphRequest).then(
        (res: any) => {
          setGraphDataInProgress(false)
          setDataForLines(res.charts[0].dataSources)
          setYieldText(`Yield Curve: ${yieldCountry.label} ${yieldDate.format(AppConfigurations.dateFormat)}`)
        },
        //Reject
        (notOKResponseModel: NotOKResponseModel) => {
          displayResponse(notOKResponseModel)
          setGraphDataInProgress(false)
          setDataForLines(null)
          setYieldText('')
        }
      )
    }
  }

  const updateYieldText = () => {
    if (yieldCountry) {
      if (yieldCountry.label === '') {
        setDataForLines([])
        return setYieldText('No Country Selected')
      }
    }
  }

  useEffect(() => {
    setYieldCountry('')

    const request: CountryGroupRequest = {
      group: countryGroup.value
    }

    getCountriesByGroupYieldCurve(request).then(
      (res: any) => {
        const countriesByGroup: string[] = res.value
        setCountriesByGroup(countriesByGroup.map((group: any) => ({ label: group.label, value: group.value })))
      },
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
      })
  }, [countryGroup])

  useEffect(() => {
    if (clearCountryGroup) {
      setCountryGroup(countryGroupWatcher)
      setClearCountryGroup(false)
    }
  }, [clearCountryGroup])

  useEffect(
    () => {
      getCountryGroups().then(
        (res: any) => {
          const countryGroups = res.value
          setCountryGroupList(countryGroups.map((group: any) => ({ label: group, value: group })))
        },
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
        })
    }, [])

  useEffect(() => {
    if (commitSearch) {
      doGraphSearch()
      setCommitSearch(false)
    }
  }, [commitSearch])

  const updateUponValidity = () => {
    if (graphDataInProgress) return
    if (dateError) return
    if (yieldCountry && yieldCountry.value) {
      setCommitSearch(true)
    }
    updateYieldText()
  }

  useEffect(() => {
    if (!yieldCountry.value) {
      setDataForLines(null)
      setYieldText('')
      setYieldDate((moment()))
    }
    updateUponValidity()
  }, [yieldCountry.value])


  const downloadDataFile = (format: OutputFormatValueType) => {
    const chartRequest: GraphYieldRequest = {
      series: `IG${yieldCountry.value}10D`,
      yieldDate: yieldDate && yieldDate.isValid() ? yieldDate.toISOString() : moment().toISOString()
    }
    const chartDataDownloadRequest: CreateYieldDownloadDataRequest = {
      chartRequest: chartRequest,
      outputFormat: format
    }
    createYieldDataFile(chartDataDownloadRequest).then(
      (fileCreateResponse: CreateYieldDownloadDataResponse) => {
        const downloadRequest: FileDownloadRequest = {
          filename: fileCreateResponse.filename,
          mimeType: fileCreateResponse.mimeType
        }
        downloadFileFromServer(downloadRequest, setErrorResponse)
      },
      (error: NotOKResponseModel) => {
        setErrorResponse(error)
      }
    )
  }

  const downloadCSV = () => {
    downloadDataFile(OutputFormatValue.CSV)
  }

  const downloadExcel = () => {
    downloadDataFile(OutputFormatValue.Excel)
  }

  const imageID: string = 'yieldCurveGraph'

  const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
    downloadCSV: downloadCSV,
    downloadExcel: downloadExcel,
    imageIDValue: imageID
  }

  const handleYieldDateChange = () => {
    if (!yieldDate) {
      setDateError(null)
    } else if (!yieldDate.isValid()) {
      setDateError('Invalid date')
    } else if (yieldDate.isAfter(maximumDate)) {
      setDateError(`Date must be less than or equal to ${maximumDate?.format(AppConfigurations.dateFormat)}`)
    } else if (yieldDate.isBefore(minimumDate)) {
      setDateError(`Date must be greater than or equal to ${minimumDate?.format(AppConfigurations.dateFormat)}`)
    } else {
      setDateError(null)
    }
  }

  useEffect(() => {
    handleYieldDateChange()
  }, [yieldDate])

  const handleClickOutside = () => {
    if (yieldDate !== yieldDateTracker) {
      updateUponValidity()
    }
  }

  const componentMessageHandlerProps: ComponentMessageHandlerProps = {
    messages,
    setMessages,
    response: errorResponse,
    signOut
  }

  const readOnlyProp: CSSProperties = graphDataInProgress ? { pointerEvents: 'none' } : {}

  return (
    <>
      {showModal && <YieldCurveClearGroupsConfirmationModal modalProps={modalProps} />}
      <Grid container spacing={0} columns={3}>
        <Grid item sm={2} md={2} lg={1}>
          <div style={headerStyles.headerWrapper}>
            <YieldCurveIcon style={headerStyles.customIcon}></YieldCurveIcon>
            <h3 style={{ fontWeight: 'bold' }}>Yield Curve </h3>
          </div>
        </Grid>
      </Grid>

      <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={3} sx={{ paddingBottom: 2, marginTop: '10px', ...readOnlyProp }}>
        <Grid item>
          <Autocomplete
            disablePortal
            onFocus={() => resetMessage()}
            onChange={(e, value) => {
              gaLogEvent('Updated Yield Curve Country Group', gaFinaeonWebAppEventCategories.Tools, 'Yield Curve Country Group')
              resetMessage()
              let actualValue: any = ''
              typeof (value?.value) !== (null) ? actualValue = value?.value : actualValue = ''
              setShowModal(true)
              setCountryGroupWatcher({ label: actualValue, value: actualValue })
            }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            getOptionLabel={(option) => option.label || ''}
            options={countryGroupList}
            value={countryGroup}
            renderInput={(params) => (
              <TextField
                {...params}
                label={customLabelToolTip('Select Group for Yield Curve', getTooltipMessage(yieldCurveTooltipKeys.group))}
                variant='outlined'
                margin='dense'
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: 'bold' }
                }}
                sx={{ minWidth: 300, maxWidth: 300 }}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            disablePortal
            onFocus={() => resetMessage()}
            onChange={(e, value) => {
              gaLogEvent('Updated Yield Curve Country', gaFinaeonWebAppEventCategories.Tools, 'Yield Curve Country')
              resetMessage()
              if (value) {
                setYieldCountry(value)
              } else {
                setYieldCountry('')
              }
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.label || ''}
            options={countriesByGroup}
            value={yieldCountry}
            renderInput={(params) => (
              <TextField
                {...params}
                label={customLabelToolTip('Select Country for Yield Curve', getTooltipMessage(yieldCurveTooltipKeys.country))}
                variant='outlined'
                margin='dense'
                InputLabelProps={{
                  shrink: true,
                  style: { fontWeight: 'bold' }
                }}
                sx={{ minWidth: 300, maxWidth: 300 }}
              />
            )}
          />
        </Grid>
        <Grid item>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ClickAwayListener onClickAway={handleClickOutside} disableReactTree={true}>
              <DatePicker
                label={customLabelToolTip('Set Date', getTooltipMessage(yieldCurveTooltipKeys.setDate))}
                value={yieldDate}
                onChange={(newValue) => {
                  gaLogEvent('Updated Yield Curve Country startYear', gaFinaeonWebAppEventCategories.Tools, 'Yield Curve Country startYear')
                  resetMessage()
                  setYieldDate(newValue as moment.Moment)
                }}
                sx={{ fontWeight: 'bold' }}
                slotProps={{
                  textField: {
                    onFocus: () => resetMessage(),
                    id: 'startYear',
                    variant: 'outlined',
                    InputLabelProps: {
                      shrink: true
                    },
                    error: dateError !== null,
                    helperText: dateError
                  }
                }}
                minDate={minimumDate}
                maxDate={maximumDate}
              />
            </ClickAwayListener>
          </LocalizationProvider>
          {
            graphDataInProgress &&
            <IconButton aria-label='Getting graph data...' component='label'>
              <CircularProgress style={globalStyles.circularProgress} title='Getting graph data...' aria-label='Getting graph data...' />
            </IconButton>
          }

        </Grid>
      </Grid>

      <div style={{ marginBottom: '16px' }}>
        <ComponentMessageHandler {...componentMessageHandlerProps} />
      </div>

      <Grid container direction='row' justifyContent='flex-end' columns={12} alignItems='flex-end' sx={{ ...readOnlyProp }}>
        <Grid item md={6} onFocus={() => resetMessage()}>
          {dataForLines && dataForLines.length > 0 && <GraphDownloadOptions {...graphDownloadOptionsProps} />}
        </Grid>
      </Grid>
      <Box
        id={imageID}
        marginBottom={'40px'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        style={{ backgroundColor: 'white', ...readOnlyProp }}
      >
        <strong style={{ paddingLeft: '10%', fontSize: '20px' }}>{yieldText}</strong>
        <ResponsiveContainer width='100%' height={chartSizes.standard} >
          <LineChart
            width={500}
            height={300}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            data={dataForLines}
            barCategoryGap={0}
            barGap={0}
          >
            <CartesianGrid />
            <XAxis dataKey='date' allowDuplicatedCategory={false} label={rechartsSourceTextLabelObject} />
            <YAxis orientation='left' scale={'linear'} domain={['auto', 'auto']} interval={'preserveStartEnd'} allowDecimals={false} />
            {generateLines()}
            <Tooltip content={<CustomTooltip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
            {graphCompanyLogoSVG()}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  )
}

const headerStyles = {
  customIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, #e8d57b, #fdf1bb)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  },
  reportWrapper: {
    paddingTop: '20px'
  }
}

export default YieldCurve
