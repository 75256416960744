import { Typography } from '@mui/material'
import { AppConfigurations, ConfigKeys, trailUserAccount } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { useState, useEffect } from 'react'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

const AccountAPI = () => {

  const defaultMessageHTML: string = "Trial users have access to Dow 30 series.|Dow 30 series includes the following: |CVX, AXP, DIS, PG, CAT, HD, MCD, WMT, IBM, JNJ, MRK, MMM, BA, KO, HON, VZ, SHW, JPM, AAPL, NKE, TRV, MSFT, AMGN, CSCO, UNH, GS, AMZN, NVDA, CRM, V, DJIA|Note: Trial access users get access to the DJIA (Dow Jones Industrial Average) as part of the Dow 30 subscription above."
  const [configData, setConfigData] = useState<any | null>(null)
  const [trialUserAccessMessage, setTrialUserAccessMessage] = useState<string>(defaultMessageHTML)

  const TrialUserDescription = (configeredMessages: string): JSX.Element[] => {
    const configuredMessageParts: string[] = configeredMessages.split('|')
    const trialUserComponentParts: JSX.Element[] = []
    if (configuredMessageParts.length > 0) {
      trialUserComponentParts.push(<p key={'intro'}>{configuredMessageParts[0]}</p>)
    }
    if (configuredMessageParts.length > 1) {
      trialUserComponentParts.push(<span key={'seriesInfo'}>{configuredMessageParts[1]}</span>)
    }
    if (configuredMessageParts.length > 2) {
      trialUserComponentParts.push(<b key={'commaDelimitedSeries'}>{configuredMessageParts[2]}</b>)
    }
    if (configuredMessageParts.length > 3) {
      trialUserComponentParts.push(<p key={'notes'}>{configuredMessageParts[3]}</p>)
    }
    trialUserComponentParts.push(<p key={'returnChar'}></p>)
    return trialUserComponentParts
  }

  useEffect(() => {
    fetch('/config.json', {
      headers: new Headers({
        'Cache-Control': 'no-store'
      })
    })
      .then(response => response.json())
      .then(data => setConfigData(data))
      .catch(error => console.error('Error fetching the config.json file:', error));
  }, [])

  useEffect(() => {
    if (configData) {
      const configuredTrialUserAccessMessage: string = configData[ConfigKeys.trialUserAccessMessageHTML] || defaultMessageHTML
      setTrialUserAccessMessage(configuredTrialUserAccessMessage)
    }
  }, [configData])

  const ApiSandboxLink = (): JSX.Element => {
    return (
      <a style={{ color: globalStyles.siteThemeColor }} href={AppConfigurations.apiDocsURL} target='_blank' rel='noopener noreferrer'
        onClick={() => {
          gaLogEvent('Clicked on API Link', gaFinaeonWebAppEventCategories.Account, 'ApiSandboxLink')
        }}
      >
        API sandbox
      </a>
    )
  }

  return (
    <div>
      <h4>JSON API sandbox</h4>
      <Typography component={'span'} variant={'body2'}>
        <p>You can practice API calls using <ApiSandboxLink /> link as a trial user and see what the expected responses will be.</p>
        {TrialUserDescription(trialUserAccessMessage)}
        <p>
          Here is the api sandbox for you to use: <ApiSandboxLink />
        </p>
        <p>
          Use the trial user account <span style={{ fontStyle: 'italic' }}>{trailUserAccount}</span> in the sandbox to retrieve the token and use the token to call the available endpoints.
        </p>
      </Typography>
    </div>
  )
}

export default AccountAPI
