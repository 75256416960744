import { Divider, TextField } from '@mui/material'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ChangePasswordResultCodes } from '../../../Models/DataModels/Common/AccountModel'
import { ChangePasswordRequest } from '../../../Models/DataModels/Requests/AccountRequests'
import { ChangePasswordResult } from '../../../Models/DataModels/Responses/AccountResponses'
import { changePassword } from '../../../Services/AccountService'
import { GFDToastError, GFDToastSuccess } from '../Utility/GFDToastify'
import { BtnEnabledDisabled, CustomBtnCloseCancel } from '../GlobalSettings/CustomStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

const ChangePasswordModal = ({ setShow, showModal }: any) => {

  const ChangePasswordMessages = {
    Success: 'Password Updated. You should receive an email confirmation of this change. Use your new password next time you log in.',
    InvalidCurrentPassword: 'Invalid password',
    OldNewPasswordMatch: 'Your new password cannot match your old password.',
    NewConfirmPasswordMismatch: 'New Password and Confirm Password do not match',
    InvalidPassword_LessThan6Chars: 'Password must have at least 6 characters',
    InvalidPassword_GreaterThan256Chars: 'Password cannot be more than 256 characters long',
    InvalidPassword_NoUpperCase: 'Password must have at least one uppercase letter',
    InvalidPassword_NoLowerCase: 'Password must have at least one lowercase letter',
    InvalidPassword_NoNumber: 'Password must have at least one number',
    InvalidPassword_NoSpecialChar: 'Password must have at least one special character @$!*?&)',
    EmailNotificationFailure: '',
    Failure: 'Error occurred'
  }

  const errorSection = {
    currentPasswordError: 'currentPasswordError',
    newPasswordError: 'newPasswordError',
    confirmPasswordError: 'confirmPasswordError'
  }

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

  const [isCurrentPassError, setIsCurrentPassError] = useState<boolean>(false)
  const [isNewPassError, setIsNewPassError] = useState<boolean>(false)
  const [isConfirmPassError, setIsConfirmPassError] = useState<boolean>(false)

  const [errorLinesCurrentPassword, setErrorTextCurrentPassword] = useState<string[]>([])
  const [errorLinesNewPassword, setErrorTextNewPassword] = useState<string[]>([])
  const [errorLinesConfirmPassword, setErrorTextConfirmPassword] = useState<string[]>([])

  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState<boolean>(true)

  const handleClose = () => setShow(false)

  const handleCustomClose = () => {
    resetStates()
    handleClose()
  }

  const validateChangePassword = (currentPW: string, newPW: string, newConfirmPW: string) => {
    let validationResult: ChangePasswordResultCodes = ChangePasswordResultCodes.Success

    if (currentPW === newPW) {
      validationResult |= ChangePasswordResultCodes.OldNewPasswordMatch
    }
    if (newPW !== newConfirmPW) {
      validationResult |= ChangePasswordResultCodes.NewConfirmPasswordMismatch
    }

    // Password must have at least 6 characters (6 to 256 characters), at least one uppercase letter, one lowercase letter, one number and one special character (@$!*?&).
    // Note: No % symbol allowed.
    // let passwordRequirementsRegex: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!*?&])[A-Za-z\d@$!*?&]{6,256}$')
    let passwordRequirementsRegex: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!*?&])[A-Za-z0-9@$!*?&]{6,256}$')

    if (!passwordRequirementsRegex.test(newPW)) {
      validationResult |= ChangePasswordResultCodes.InvalidPassword
    }

    return validationResult
  }

  const resetStates = () => {
    clearFields()
    resetErrorState()
  }

  const resetErrorState = () => {
    setIsCurrentPassError(false)
    setIsNewPassError(false)
    setIsConfirmPassError(false)
    setErrorTextCurrentPassword([])
    setErrorTextNewPassword([])
    setErrorTextConfirmPassword([])
  }

  const clearFields = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
  }

  const displayErrorMessages = (validationResult: ChangePasswordResultCodes, inputNewPassword: string) => {

    if ((validationResult & ChangePasswordResultCodes.Failure) === ChangePasswordResultCodes.Failure) {
      // Some other error occurred
      handleClose()
      GFDToastError(ChangePasswordMessages.Failure)
      return
    }

    const currentPassErrors: string[] = []
    const newPassErrors: string[] = []
    const confirmPassErrors: string[] = []

    if ((validationResult & ChangePasswordResultCodes.InvalidCurrentPassword) === ChangePasswordResultCodes.InvalidCurrentPassword) {
      // This is only set based on the API Result.
      currentPassErrors.push(ChangePasswordMessages.InvalidCurrentPassword)
    }
    if ((validationResult & ChangePasswordResultCodes.OldNewPasswordMatch) === ChangePasswordResultCodes.OldNewPasswordMatch) {
      newPassErrors.push(ChangePasswordMessages.OldNewPasswordMatch)
    }
    if ((validationResult & ChangePasswordResultCodes.NewConfirmPasswordMismatch) === ChangePasswordResultCodes.NewConfirmPasswordMismatch) {
      confirmPassErrors.push(ChangePasswordMessages.NewConfirmPasswordMismatch)
    }
    if ((validationResult & ChangePasswordResultCodes.InvalidPassword) === ChangePasswordResultCodes.InvalidPassword) {
      if (inputNewPassword.length < 6) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_LessThan6Chars)
      }
      if (inputNewPassword.length > 256) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_GreaterThan256Chars)
      }
      const atLeastOneUpper: RegExp = new RegExp('[A-Z]+')
      if (!atLeastOneUpper.test(inputNewPassword)) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_NoUpperCase)
      }
      const atLeastOneLower: RegExp = new RegExp('[a-z]+')
      if (!atLeastOneLower.test(inputNewPassword)) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_NoLowerCase)
      }
      const atLeastOneNumber: RegExp = new RegExp('[0-9]+')
      if (!atLeastOneNumber.test(inputNewPassword)) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_NoNumber)
      }
      const atLeastOneSpecialChar: RegExp = new RegExp('[@$!*?&]+')
      if (!atLeastOneSpecialChar.test(inputNewPassword)) {
        newPassErrors.push(ChangePasswordMessages.InvalidPassword_NoSpecialChar)
      }
    }

    if ((validationResult & ChangePasswordResultCodes.EmailNotificationFailure) === ChangePasswordResultCodes.EmailNotificationFailure) {
      // Do nothing for now
    }

    if (currentPassErrors.length > 0) {
      setIsCurrentPassError(true)
    }
    if (newPassErrors.length > 0) {
      setIsNewPassError(true)
    }
    if (confirmPassErrors.length > 0) {
      setIsConfirmPassError(true)
    }

    setErrorTextCurrentPassword(currentPassErrors)
    setErrorTextNewPassword(newPassErrors)
    setErrorTextConfirmPassword(confirmPassErrors)
  }

  const handleInputChange = (currentPW: string, newPW: string, newConfirmPW: string) => {
    resetErrorState()
    let validationResult: ChangePasswordResultCodes = validateChangePassword(currentPW, newPW, newConfirmPW)
    if (validationResult === ChangePasswordResultCodes.Success) {
      // Enable Button
      setIsResetButtonDisabled(false)
      resetErrorState()
    } else {
      setIsResetButtonDisabled(true)
      // Display Error messages
      displayErrorMessages(validationResult, newPW)
    }
  }

  const handleCurrentPassChange = (updatedCurrentPassword: string, newPW: string, newConfirmPW: string) => {
    handleInputChange(updatedCurrentPassword, newPW, newConfirmPW)
    setCurrentPassword(updatedCurrentPassword)
  }

  const handleNewPassChange = (currentPW: string, updatedNewPassword: string, newConfirmPW: string) => {
    handleInputChange(currentPW, updatedNewPassword, newConfirmPW)
    setNewPassword(updatedNewPassword)
  }

  const handleConfirmPassChange = (currentPW: string, newPW: string, updatedConfirmPassword: string) => {
    handleInputChange(currentPW, newPW, updatedConfirmPassword)
    setConfirmNewPassword(updatedConfirmPassword)
  }

  const handleResetPasswordButton = () => {
    gaLogEvent('Clicked on Reset Password Button', gaFinaeonWebAppEventCategories.Account, 'handleResetPasswordButton')
    resetErrorState()
    let validationResult: ChangePasswordResultCodes = validateChangePassword(currentPassword, newPassword, confirmNewPassword)
    if (validationResult === ChangePasswordResultCodes.Success) {
      sendChangePasswordRequest()
    } else {
      displayErrorMessages(validationResult, '')
    }
  }

  const sendChangePasswordRequest = () => {
    const request: ChangePasswordRequest = {
      OldPassword: currentPassword,
      NewPassword: newPassword,
      NewPasswordConfirmation: confirmNewPassword
    }

    changePassword(request).then(
      (result: ChangePasswordResult) => {
        const resultCode: ChangePasswordResultCodes = result.resultCode
        if (resultCode === ChangePasswordResultCodes.Success) {
          // Original Success Message: "Password Updated. You should receive an email confirmation of this change. Use your new password next time you log in."
          // Close Modal and diplay success message (Previously in header message thing)
          clearFields()
          handleClose()
          GFDToastSuccess(ChangePasswordMessages.Success)
        } else {
          displayErrorMessages(resultCode, '')
        }
      },
      //Reject Promise
      () => {
        console.log('Error occurred attempting to change password')
        // Display some other error related to the API call
        handleClose()
        GFDToastError(ChangePasswordMessages.Failure)
      })
  }

  const multiErrorLines = (errors: string[], errorID: string) => {
    return (
      <>
        {errors.map((error: string, index: number) => (
          <span key={`${errorID}multiErrorLines${index}`}>{error}<br /></span>
        ))}
      </>
    )
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Your Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Passwords must be at least 6 characters long</h5>
          <Divider sx={{ borderColor: 'black' }} />
          <div style={changePasswordModalStyles.topRow}>
            <TextField id='standard-basic' label='Current Password' variant='outlined' type={'password'}
              InputLabelProps={{
                shrink: true,
                style: { fontWeight: 'bold' }
              }}
              value={currentPassword}
              onChange={(e) => { handleCurrentPassChange(e.target.value, newPassword, confirmNewPassword) }}
              error={isCurrentPassError}
              helperText={multiErrorLines(errorLinesCurrentPassword, errorSection.currentPasswordError)} />
          </div>
          <div style={changePasswordModalStyles.row}>
            <TextField id='standard-basic' label='New Password' variant='outlined' type={'password'}
              InputLabelProps={{
                shrink: true,
                style: { fontWeight: 'bold' }
              }}
              value={newPassword}
              onChange={(e) => { handleNewPassChange(currentPassword, e.target.value, confirmNewPassword) }}
              error={isNewPassError}
              helperText={multiErrorLines(errorLinesNewPassword, errorSection.newPasswordError)} />
          </div>
          <div style={changePasswordModalStyles.row}>
            <TextField id='standard-basic' label='Confirm Password' variant='outlined' type={'password'}
              InputLabelProps={{
                shrink: true,
                style: { fontWeight: 'bold' }
              }}
              value={confirmNewPassword}
              onChange={(e) => { handleConfirmPassChange(currentPassword, newPassword, e.target.value) }}
              error={isConfirmPassError}
              helperText={multiErrorLines(errorLinesConfirmPassword, errorSection.confirmPasswordError)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CustomBtnCloseCancel variant='outlined' onClick={(e) => handleCustomClose()}>
            Close
          </CustomBtnCloseCancel>
          <BtnEnabledDisabled variant='contained' disabled={isResetButtonDisabled} onClick={handleResetPasswordButton}>
            Reset Password
          </BtnEnabledDisabled>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const changePasswordModalStyles = {
  row: {
    paddingTop: 7
  },
  topRow: {
    paddingTop: 7,
    paddingBottom: 25
  }
}

export default ChangePasswordModal