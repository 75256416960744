import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ChartSettings from './GraphOptionsModalSubComponents.tsx/ChartSettings'
import Chronology from './GraphOptionsModalSubComponents.tsx/Chronology'
import ComparisonSettings from './GraphOptionsModalSubComponents.tsx/ComparisonSettings'
import TechnicalIndicators from './GraphOptionsModalSubComponents.tsx/TechnicalIndicators'
import { styled, Typography } from '@mui/material'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import moment from 'moment'
import { ChartScaleTypes, DateRangeSelections, PeriodicityTypes } from '../../../Models/DataModels/Common/ToolsModel'
import { SeriesChartOptionsResult } from '../../../Models/DataModels/Responses/GraphResponses'
import ArrowExpand from '../../Icons/ArrowExpandIcon'
import { customLabelToolTip } from './CustomToolTips'
import { CustomBtnBlue, CustomBtnCloseCancel } from '../GlobalSettings/CustomStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface GraphOptionsModalProps {
    searchTerm: string,
    modalData: any,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    handleClose: () => void,
    handleSuccess: (result: any) => void,
    extraOptions: SeriesChartOptionsResult | null,
    checkIsTrial: () => boolean
}

const GraphOptionsModal = (modalProps: GraphOptionsModalProps) => {


    const resolveTechnicalIndicator = (technicalIndicator: string) => {
        let returnObj: any = {}
        if (technicalIndicator === 'none') {
            return returnObj
        }
        else if (technicalIndicator === 'chaikinOscillator' || technicalIndicator === 'MACD') {
            returnObj[technicalIndicator] = {
                periodShort: technicalIndicatorShortPeriod,
                periodLong: technicalIndicatorLongPeriod
            }
        }
        else if (technicalIndicator === 'stochasticIndicator') {
            returnObj[technicalIndicator] = {
                periodK: technicalIndicatorPeriodK,
                periodD: technicalIndicatorPeriodD
            }
        } else if (technicalIndicator === 'forceIndex') {
            returnObj[technicalIndicator] = {
                periodEMA: technicalIndicatorPeriodEMA
            }
        } else if (technicalIndicator === 'accumulationDistribution' || technicalIndicator === 'onBalanceVolume') {
            returnObj[technicalIndicator] = {}
        } else {
            returnObj[technicalIndicator] = {
                period: technicalIndicatorPeriod
            }
        }
        return returnObj
    }

    const resolveChartIndicator = (chartIndicator: string) => {
        let returnObj: any = {}
        if (chartIndicator === 'none') {
            return returnObj
        }
        else if (chartIndicator === 'bollingerBands') {
            returnObj[chartIndicator] = {
                period: chartIndicatorPeriod,
                stdDev: chartIndicatorStdDev
            }
        } else if (chartIndicator === 'envelopes') {
            returnObj[chartIndicator] = {
                period: chartIndicatorPeriod,
                shift: chartIndicatorShift
            }
        } else if (chartIndicator === 'medianPrice' || chartIndicator === 'typicalPrice' || chartIndicator === 'medianPrice') {
            returnObj[chartIndicator] = {}
        }
        else {
            returnObj[chartIndicator] = {
                period: chartIndicatorPeriod,
            }
        }
        return returnObj
    }

    const [expanded, setExpanded] = useState<string | false>('chartSettings')
    const [currentDateMode, setCurrentDateMode] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.currentDateMode : 'selectDate')
    const [currentDateSelection, setCurrentDateSelection] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.currentDateSelection : DateRangeSelections.Max)
    const [currentStartDate, setCurrentStartDate] = useState<moment.Moment>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.currentStartDate as moment.Moment : moment('1-1-1950', 'MM-DD-YYYY'))
    const [currentEndDate, setCurrentEndDate] = useState<moment.Moment>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.currentEndDate as moment.Moment : moment())

    const [periodicity, setPeriodicity] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.periodicity : PeriodicityTypes.Quarterly)
    const [graphType, setGraphType] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.graphType : 'line')
    const [scaleType, setScaleType] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.scaleType : ChartScaleTypes.Log)
    const [currencyType, setCurrencyType] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.currencyType : 'NATIVE')

    const [showSplits, setShowSplits] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.showSplits : false)
    const [splitAdjusted, setSplitAdjusted] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.splitAdjusted : true)
    const [totalReturn, setTotalReturn] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.totalReturn : false)
    const [percentGDP, setPercentGDP] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.percentGDP : false)
    const [showDividends, setShowDividends] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.showDividends : false)
    const [inflationAdjusted, setInflationAdjusted] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.inflationAdjusted : false)
    const [annualPctChg, setAnnualPctChg] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.annualPctChg : false)

    const [movingAvg, setMovingAvg] = useState<any[]>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.movingAvg : [])
    const [customAvg, setCustomAvg] = useState<number | null>(1)

    const [includeVolumeChart, setIncludeVolumeChart] = useState<boolean>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chartSettingsSetters.includeVolumeChart : false)

    const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState<boolean>(false)

    const [comparisonType, setComparisonType] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.comparisonSettingsSetters.comparisonType : 'none')
    const [comparisonScale, setComparisonScale] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.comparisonSettingsSetters.comparisonScale : 'indirect')

    const chartSettingsSetters = {
        comparisonType: comparisonType,
        currentDateMode: currentDateMode,
        setCurrentDateMode: setCurrentDateMode,
        currentDateSelection: currentDateSelection,
        setCurrentDateSelection: setCurrentDateSelection,
        currentStartDate: currentStartDate,
        setCurrentStartDate: setCurrentStartDate,
        currentEndDate: currentEndDate,
        setCurrentEndDate: setCurrentEndDate,
        periodicity: periodicity,
        setPeriodicity: setPeriodicity,
        graphType: graphType,
        setGraphType: setGraphType,
        scaleType: scaleType,
        setScaleType: setScaleType,
        currencyType: currencyType,
        setCurrencyType: setCurrencyType,
        showSplits: showSplits,
        setShowSplits: setShowSplits,
        splitAdjusted: splitAdjusted,
        setSplitAdjusted: setSplitAdjusted,
        totalReturn: totalReturn,
        setTotalReturn: setTotalReturn,
        percentGDP: percentGDP,
        setPercentGDP: setPercentGDP,
        showDividends: showDividends,
        setShowDividends: setShowDividends,
        inflationAdjusted: inflationAdjusted,
        setInflationAdjusted: setInflationAdjusted,
        annualPctChg: annualPctChg,
        setAnnualPctChg: setAnnualPctChg,
        movingAvg: movingAvg,
        setMovingAvg: setMovingAvg,
        customAvg: customAvg,
        setCustomAvg: setCustomAvg,
        isApplyButtonDisabled: isApplyButtonDisabled,
        setIsApplyButtonDisabled: setIsApplyButtonDisabled,
        includeVolumeChart: includeVolumeChart,
        setIncludeVolumeChart: setIncludeVolumeChart,
        showVolumeOption: !modalProps.extraOptions || modalProps.extraOptions.hasSecurities ? true : false,
        options: modalProps.extraOptions,
        checkIsTrial: modalProps.checkIsTrial
    }

    const [selectedValues, setSelectedValues] = useState<any[]>([])
    const [tickerList, setTickerList] = useState<any[]>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.comparisonSettingsSetters.tickerList : [])
    const [ticker, setTicker] = useState<any>()
    const [symbolList, setSymbolList] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.comparisonSettingsSetters.symbolList : [])

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [clearText, setClearText] = useState<any>(false)

    const comparisonSettingsSetters = {
        comparisonType: comparisonType,
        setComparisonType: setComparisonType,
        comparisonScale: comparisonScale,
        setComparisonScale: setComparisonScale,
        selectedValues: selectedValues,
        setSelectedValues: setSelectedValues,
        tickerList: tickerList,
        setTickerList: setTickerList,
        ticker: ticker,
        setTicker: setTicker,
        searchTerm: searchTerm,
        setSearchTerm: setSearchTerm,
        clearText: clearText,
        setClearText: setClearText,
        symbolList: symbolList,
        checkIsTrial: modalProps.checkIsTrial
    }

    const [chartIndicator, setChartIndicator] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.chartIndicator : 'none')
    const [chartIndicatorPeriod, setChartIndicatorPeriod] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.chartIndicatorPeriod : 30)
    const [chartIndicatorStdDev, setChartIndicatorStdDev] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.chartIndicatorStdDev : 2)
    const [chartIndicatorShift, setChartIndicatorShift] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.chartIndicatorShift : 30)

    const [technicalIndicator, setTechnicalIndicator] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicator : 'none')
    const [technicalIndicatorPeriod, setTechnicalIndicatorPeriod] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorPeriod : 30)
    const [technicalIndicatorShortPeriod, setTechnicalIndicatorShortPeriod] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorShortPeriod : 12)
    const [technicalIndicatorLongPeriod, setTechnicalIndicatorLongPeriod] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorLongPeriod : 5)
    const [technicalIndicatorPeriodA, setTechnicalIndicatorPeriodA] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorPeriodA : 30)
    const [technicalIndicatorPeriodEMA, setTechnicalIndicatorPeriodEMA] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorPeriodEMA : 30)
    const [technicalIndicatorPeriodK, setTechnicalIndicatorPeriodK] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorPeriodK : 8)
    const [technicalIndicatorPeriodD, setTechnicalIndicatorPeriodD] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.technicalIndicatorPeriodD : 12)

    const [valueIndicator, setValueIndicator] = useState<string>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.valueIndicator : 'none')
    const [isValueIndicatorOnPrimaryGraph, setIsValueIndicatorOnPrimaryGraph] = useState<boolean>(modalProps?.modalData?.technicalIndicatorsSetters?.isValueIndicatorOnPrimaryGraph || false)

    const [financialRatio, setFinancialRatio] = useState<string>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.technicalIndicatorsSetters.financialRatio : 'none')

    const technicalIndicatorsSetters = {
        chartIndicator: chartIndicator,
        setChartIndicator: setChartIndicator,
        chartIndicatorPeriod: chartIndicatorPeriod,
        setChartIndicatorPeriod: setChartIndicatorPeriod,
        chartIndicatorStdDev: chartIndicatorStdDev,
        setChartIndicatorStdDev: setChartIndicatorStdDev,
        chartIndicatorShift: chartIndicatorShift,
        setChartIndicatorShift: setChartIndicatorShift,
        technicalIndicator: technicalIndicator,
        setTechnicalIndicator: setTechnicalIndicator,
        technicalIndicatorPeriod: technicalIndicatorPeriod,
        setTechnicalIndicatorPeriod: setTechnicalIndicatorPeriod,
        technicalIndicatorShortPeriod: technicalIndicatorShortPeriod,
        setTechnicalIndicatorShortPeriod: setTechnicalIndicatorShortPeriod,
        technicalIndicatorLongPeriod: technicalIndicatorLongPeriod,
        setTechnicalIndicatorLongPeriod: setTechnicalIndicatorLongPeriod,
        technicalIndicatorPeriodA: technicalIndicatorPeriodA,
        setTechnicalIndicatorPeriodA: setTechnicalIndicatorPeriodA,
        technicalIndicatorPeriodK: technicalIndicatorPeriodK,
        setTechnicalIndicatorPeriodK: setTechnicalIndicatorPeriodK,
        technicalIndicatorPeriodD: technicalIndicatorPeriodD,
        setTechnicalIndicatorPeriodD: setTechnicalIndicatorPeriodD,
        technicalIndicatorPeriodEMA: technicalIndicatorPeriodEMA,
        setTechnicalIndicatorPeriodEMA: setTechnicalIndicatorPeriodEMA,
        technicalIndicatorObject: resolveTechnicalIndicator(technicalIndicator),
        chartIndicatorObject: resolveChartIndicator(chartIndicator),
        valueIndicator: valueIndicator,
        setValueIndicator: setValueIndicator,
        isValueIndicatorOnPrimaryGraph: isValueIndicatorOnPrimaryGraph,
        setIsValueIndicatorOnPrimaryGraph: setIsValueIndicatorOnPrimaryGraph,
        financialRatio: financialRatio,
        setFinancialRatio: setFinancialRatio,
        isApplyButtonDisabled: isApplyButtonDisabled,
        setIsApplyButtonDisabled: setIsApplyButtonDisabled,
        checkIsTrial: modalProps.checkIsTrial
    }

    const [chronology, setChronology] = useState<any>(Object.keys(modalProps.modalData).length > 0 ? modalProps.modalData.chronologySettings.chronology : 'none')

    const chronologySettings = {
        chronology: chronology,
        setChronology: setChronology,
        options: modalProps.extraOptions,
        checkIsTrial: modalProps.checkIsTrial
    }

    const handleClose = () => {
        modalProps.handleClose()
    }
    const handleSuccess = () => {
        gaLogEvent('Clicked on Graph Apply Options Button', gaFinaeonWebAppEventCategories.Graph, 'Graph Options handleSuccess')
        const modalReturn = {
            chartSettingsSetters: chartSettingsSetters,
            comparisonSettingsSetters: comparisonSettingsSetters,
            technicalIndicatorsSetters: technicalIndicatorsSetters,
            chronologySettings: chronologySettings
        }
        modalProps.handleSuccess({ ...modalReturn })
    }

    const handleChangeAccordian =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false)
        }

    return (
        <>
            <Modal size='xl' show={modalProps.showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Chart Options</Modal.Title>
                    {modalProps.checkIsTrial() && <Typography sx={{ textAlign: 'center', color: 'darkred', paddingLeft: 1, fontStyle: 'italic' }}>Options are limited for trial users.</Typography>}
                    {modalProps?.extraOptions?.isCustomIndex && (
                        <Typography sx={{ textAlign: 'center', color: 'darkred', paddingLeft: 1, fontStyle: 'italic' }}>
                            {customLabelToolTip('Certain options may not apply for custom indices.',
                            'For instance, not all of the date range inputs will be applicable because the date range comes from the date set in the custom indices and will not be affected by changes here.')}
                        </Typography>
                    )}
                </Modal.Header>
                <Modal.Body>
                    <Accordion expanded={expanded === 'chartSettings'} onChange={handleChangeAccordian('chartSettings')}>
                        <AccordionSummary id='chartSettingsAccordian'>
                            <Typography>Chart Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ChartSettings chartSettingsSetters={chartSettingsSetters} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'comparisonSettings'} onChange={handleChangeAccordian('comparisonSettings')}>
                        <AccordionSummary id='comparisonSettingsAccordian'>
                            <Typography >Comparison</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ComparisonSettings comparisonSettingsSetters={comparisonSettingsSetters} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'technicalIndicators'} onChange={handleChangeAccordian('technicalIndicators')}>
                        <AccordionSummary id='technicalIndicatorsAccordian'>
                            <Typography >Technical Indicators</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TechnicalIndicators technicalIndicatorsSetters={technicalIndicatorsSetters} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'chronology'} onChange={handleChangeAccordian('chronology')}>
                        <AccordionSummary id='chronologyAccordian'>
                            <Typography >Chronology</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Chronology chronologySettings={chronologySettings} />
                        </AccordionDetails>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <CustomBtnCloseCancel variant='outlined' onClick={handleClose}>
                        Close
                    </CustomBtnCloseCancel>
                    <CustomBtnBlue variant='contained' onClick={handleSuccess} disabled={modalProps.checkIsTrial() || isApplyButtonDisabled}>
                        Apply Options
                    </CustomBtnBlue>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowExpand style={{ fontSize: '1.3rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default GraphOptionsModal