import { Paper, Divider, Grid, TextField, MenuItem, Autocomplete, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RegisterUserInputValidationResultCodes, RegisterUserResultCodes } from '../../Models/DataModels/Common/AccountModel'
import { blankEntry } from '../../Models/DataModels/Common/FieldPopulationModel'
import { LabelValuePair } from '../../Models/DataModels/Common/LabelValuePairModel'
import { NotOKResponseModel, ServerErrorMessage } from '../../Models/DataModels/Common/NotOKResponseModel'
import { CheckExistingOrgRequest, RegisterUserRequest } from '../../Models/DataModels/Requests/AccountRequests'
import { PopulateCountryRequest } from '../../Models/DataModels/Requests/PopulationRequests'
import { RegisterUserResult, CheckAnonymousAccessResult, AnonymousUserOrgData, CheckExistingOrgResult } from '../../Models/DataModels/Responses/AccountResponses'
import { registerUser, checkAnonymousAccessRegistration, CheckExistingOrg } from '../../Services/AccountService'
import { populateCountry2D } from '../../Services/FieldPopulationService'
import { ConcatenateOrgAddressParts, ConcatenateUserAddressParts } from '../../Services/UtilityService'
import { isNullOrUndefinedOrWhiteSpace, validateFirstNameChar, validateLastNameChar, validatePositionChar, validateFullAddressChar, validatePhoneChar, validatePhoneNumberFormat, validateEmailChar, validateEmailAddressFormat, MaxChar } from '../../Services/ValidationService'
import { CheckboxBlue, BtnEnabledDisabled } from '../Common/GlobalSettings/CustomStyles'
import LegalModal, { LegalTypes } from '../Common/Modals/Legal/LegalModal'
import LoadingScreen from '../Common/Modals/LoadingScreen'
import { ErrorHandlerProps, ErrorHandler } from '../Common/Utility/ErrorHandler'
import { GFDToastSuccess, GFDToastError, GFDToastWarning, GFDToastInfo } from '../Common/Utility/GFDToastify'
import { UnorderedMultiErrorLines } from '../Common/Utility/MultiLines'
import RegistrationSuccess from './RegistrationSuccess'
import { LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { useSearchParams } from 'react-router-dom'
import { errorRedColor } from '../Common/GlobalSettings/GlobalStyles'
import InfoRedSvgIcon from '../Icons/InfoRedSvgIcon'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../Google/analytics'

export interface RegistrationFormProps {
    signOut: (logoutReason: LogoutReasonType) => void
}

const RegistrationForm = ({
    signOut
}: RegistrationFormProps) => {

    const orgTypeDropDownOptions: string[] = [
        'Business',
        'Academic'
    ]

    const contactSourceDropDownOptions: string[] = [
        'Convention/Trade Show',
        'LinkedIn Advertisement',
        'Book/Article',
        'Blog',
        'Internet Search',
        'Word of Mouth',
        'Previous User',
        'Updata',
        'University',
        'Other'
    ]

    const academicUserTypeDropDownOptions: string[] = [
        'Administrator',
        'Faculty',
        'Librarian',
        'Student'
    ]

    const queryParameterSource: string = 'source'

    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [registerPathQueryParams, setRegisterPathQueryParams] = useSearchParams()
    const [campaignID, setCampaignID] = useState<string | null>(null)

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [orgName, setOrgName] = useState<string>('')
    const [orgType, setOrgType] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [renterEmail, setRenterEmail] = useState<string>('')
    const [position, setPosition] = useState<string>('')
    const [street, setStreet] = useState<string>('')
    const [street2, setStreet2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [postalZone, setPostalZone] = useState<string>('')
    const [selectedCountry, setSelectedCountry] = useState<LabelValuePair | null>(blankEntry)
    const [phone, setPhone] = useState<string>('')
    const [contactSource, setContactSource] = useState<string>('')
    const [anonymousUserID, setAnonymousUserID] = useState<number>(-1)
    const [existingOrgID, setExistingOrgID] = useState<number>(-1)
    const [academicUserType, setAcademicUserType] = useState<string>('')

    const [iAcceptChecked, setIAcceptChecked] = useState<boolean>(false)
    const [agreeButtonDisabled, setAgreeButtonDisabled] = useState<boolean>(true)

    const [countryList, setCountryList] = useState<LabelValuePair[]>([blankEntry])
    const [errorLines, setErrorLines] = useState<string[]>([])
    const [hasRequestBeenSubmittedBefore, setHasRequestBeenSubmittedBefore] = useState<boolean>(false)

    const [showRegistrationSuccessModal, setShowRegistrationSuccessModal] = useState<boolean>(false)
    const [isEmailFailed, setIsEmailFailed] = useState<boolean>(false)

    const [errorFirstName, setErrorFirstName] = useState<boolean>(false)
    const [errorLastName, setErrorLastName] = useState<boolean>(false)
    const [errorOrgName, setErrorOrgName] = useState<boolean>(false)
    const [errorOrgType, setErrorOrgType] = useState<boolean>(false)
    const [errorAcademicUserType, setErrorAcademicUserType] = useState<boolean>(false)
    const [errorEmail, setErrorEmail] = useState<boolean>(false)
    const [errorRenterEmail, setErrorRenterEmail] = useState<boolean>(false)
    const [errorPosition, setErrorPosition] = useState<boolean>(false)
    const [errorStreet, setErrorStreet] = useState<boolean>(false)
    const [errorStreet2, setErrorStreet2] = useState<boolean>(false)
    const [errorCity, setErrorCity] = useState<boolean>(false)
    const [errorState, setErrorState] = useState<boolean>(false)
    const [errorPostalZone, setErrorPostalZone] = useState<boolean>(false)
    const [errorSelectedCountry, setErrorSelectedCountry] = useState<boolean>(false)
    const [errorPhone, setErrorPhone] = useState<boolean>(false)
    const [errorContactSource, setErrorContactSource] = useState<boolean>(false)

    const [disableOrgName, setDisableOrgName] = useState<boolean>(false)
    const [disableOrgType, setDisableOrgType] = useState<boolean>(false)
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)

    const [showTermsModal, setShowTermsModal] = useState<boolean>(false)
    const [selectedLegalType, setSelectedLegalType] = useState<LegalTypes>(LegalTypes.Privacy)
    const [hideAcademicUserType, setHideAcademicUserType] = useState<boolean>(true)
    const [hideContactSource, setHideContactSource] = useState<boolean>(false)

    const [triggerOnce, setTriggerOnce] = useState<boolean>(false)

    const validateFields = (): RegisterUserInputValidationResultCodes => {
        let validationResult: RegisterUserInputValidationResultCodes = RegisterUserInputValidationResultCodes.AllValid
        if (isNullOrUndefinedOrWhiteSpace(firstName)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_FirstName
        }
        if (isNullOrUndefinedOrWhiteSpace(lastName)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_LastName
        }
        if (isNullOrUndefinedOrWhiteSpace(orgName)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_Organization
        }
        if (isNullOrUndefinedOrWhiteSpace(orgType)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_OrgType
        }
        if (isNullOrUndefinedOrWhiteSpace(city)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_City
        }
        if (isNullOrUndefinedOrWhiteSpace(state)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_State
        }
        if (isNullOrUndefinedOrWhiteSpace(postalZone)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_ZipCode
        }
        const countryValue: string = selectedCountry ? selectedCountry.value : ''
        if (isNullOrUndefinedOrWhiteSpace(countryValue)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_Country
        }
        if (isNullOrUndefinedOrWhiteSpace(email)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_Email
        }
        if (isNullOrUndefinedOrWhiteSpace(renterEmail)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_ReEnterEmail
        }
        if (isNullOrUndefinedOrWhiteSpace(contactSource)) {
            validationResult |= RegisterUserInputValidationResultCodes.Blank_ContactSource
        }
        if (orgType === 'Academic' && isNullOrUndefinedOrWhiteSpace(academicUserType)) {
            validationResult |= RegisterUserInputValidationResultCodes.MissingAcademicUserType
        }
        if (!validateFirstNameChar(firstName)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorFirstName
        }
        if (!validateLastNameChar(lastName)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorLastName
        }
        if (!validatePositionChar(position)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorPosition
        }
        const fullOrgAddress: string = ConcatenateOrgAddressParts(street, street2, city, state, postalZone, countryValue)
        if (!validateFullAddressChar(fullOrgAddress)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorAddress
        }
        const fullUserAddress: string = ConcatenateUserAddressParts(street, street2, city, state, postalZone, countryValue)
        if (!validateFullAddressChar(fullUserAddress)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorAddress
        }
        if (!validatePhoneChar(phone)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorPhone
        }
        if (!validatePhoneNumberFormat(phone)) {
            validationResult |= RegisterUserInputValidationResultCodes.PhoneFormatError
        }
        if (!validateEmailChar(email)) {
            validationResult |= RegisterUserInputValidationResultCodes.CharErrorEmail
        }
        if (!validateEmailAddressFormat(email)) {
            validationResult |= RegisterUserInputValidationResultCodes.EmailFormatError
        }
        if (email !== renterEmail) {
            validationResult |= RegisterUserInputValidationResultCodes.EmailConfirmEmailMismatch
        }

        return validationResult
    }

    const emailValidation = (inputEmail: string): boolean => {
        if (!isNullOrUndefinedOrWhiteSpace(inputEmail) && validateEmailChar(inputEmail) && validateEmailAddressFormat(inputEmail)) {
            return true
        }
        return false
    }

    const InputValidationErrorMessages = {
        Blank_FirstName: 'First Name is a required field.',
        Blank_LastName: 'Last Name is a required field.',
        Blank_Organization: 'Organization is a required field.',
        Blank_OrgType: 'Organization Type is a required field.',
        Blank_City: 'City is a required field.',
        Blank_State: 'State/Province is a required field.',
        Blank_ZipCode: 'Postal Code is a required field.',
        Blank_Country: 'Country is a required field.',
        Blank_Email: 'Email is a required field.',
        Blank_ReEnterEmail: 'Reenter Email is a required field.',
        Blank_ContactSource: '\'How did you hear about us?\' is a required field.',
        CharErrorFirstName: 'First name cannot be longer than ' + MaxChar.FirstName + ' characters.',
        CharErrorLastName: 'Last name cannot be longer than ' + MaxChar.LastName + ' characters.',
        CharErrorPosition: 'Position cannot be longer than ' + MaxChar.Position + ' characters.',
        CharErrorAddress: 'Full Address (Street 1, Street 2, City, State/Province, Postal Code, Country Combined) cannot be longer than ' + MaxChar.FullAddress + ' characters.',
        CharErrorPhone: 'Phone number cannot be longer than ' + MaxChar.Phone + ' characters.',
        CharErrorEmail: 'Email address cannot be longer than ' + MaxChar.Email + ' characters.',
        EmailFormatError: 'Invalid Email Address Format.',
        PhoneFormatError: 'Invalid Phone Number Format.',
        EmailConfirmEmailMismatch: 'Email does not match reentered Email, please enter your correct email address and retry.',
        MissingAcademicUserType: 'User Type is required for Academic Users.'
    }

    const ResultMessages = {
        Success: 'Successfully Registered',
        EmailFailure: 'Successfully Registered but failed to send notification',
        InputDataValidationError: 'Please fix all errors',
        UserBlackListed: 'User not Allowed',
        UserAlreadyExists: 'User already exists',
        RestrictedDomainUser: 'Personal emails not allowed. Please use a work email.',
        InvalidAnonymousUser: 'Unrecognized institution email address',
        Submitted: 'Registration Submitted, please wait...',
        Failure: 'We are experiencing some issues. Please try again later.',
        Default: 'Unsupported Code'
    }

    const ResultErrorDetailedMessages = {
        UserBlackListed: 'User is not allowed.',
        UserAlreadyExists: 'User already exists. Please use the Forgot Password link to reset the password or use a different email address.',
        RestrictedDomainUser: 'We’re sorry but currently Finaeon does not recognize personal e-mail addresses.  Please send us an inquiry via e-mail for further information about our products and services.',
        InvalidAnonymousUser: 'We’re sorry but Finaeon does not recognize your e-mail address as assigned to the selected institution.  Please send us an inquiry via e-mail for further information about our products and services.',
        Failure: 'Unknown Error: Please try again later.'
    }

    const getErrorMessages = (inputFieldsErrorCode: RegisterUserInputValidationResultCodes): string[] => {
        let validationErrorMessages: string[] = []

        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_FirstName) === RegisterUserInputValidationResultCodes.Blank_FirstName) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_FirstName)
            setErrorFirstName(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorFirstName) === RegisterUserInputValidationResultCodes.CharErrorFirstName) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorFirstName)
            setErrorFirstName(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_LastName) === RegisterUserInputValidationResultCodes.Blank_LastName) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_LastName)
            setErrorLastName(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorLastName) === RegisterUserInputValidationResultCodes.CharErrorLastName) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorLastName)
            setErrorLastName(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_Organization) === RegisterUserInputValidationResultCodes.Blank_Organization) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_Organization)
            setErrorOrgName(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_OrgType) === RegisterUserInputValidationResultCodes.Blank_OrgType) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_OrgType)
            setErrorOrgType(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_Email) === RegisterUserInputValidationResultCodes.Blank_Email) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_Email)
            setErrorEmail(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_ReEnterEmail) === RegisterUserInputValidationResultCodes.Blank_ReEnterEmail) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_ReEnterEmail)
            setErrorRenterEmail(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorEmail) === RegisterUserInputValidationResultCodes.CharErrorEmail) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorEmail)
            setErrorEmail(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.EmailFormatError) === RegisterUserInputValidationResultCodes.EmailFormatError) {
            validationErrorMessages.push(InputValidationErrorMessages.EmailFormatError)
            setErrorEmail(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.EmailConfirmEmailMismatch) === RegisterUserInputValidationResultCodes.EmailConfirmEmailMismatch) {
            validationErrorMessages.push(InputValidationErrorMessages.EmailConfirmEmailMismatch)
            setErrorEmail(true)
            setErrorRenterEmail(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorPosition) === RegisterUserInputValidationResultCodes.CharErrorPosition) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorPosition)
            setErrorPosition(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_City) === RegisterUserInputValidationResultCodes.Blank_City) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_City)
            setErrorCity(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_State) === RegisterUserInputValidationResultCodes.Blank_State) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_State)
            setErrorState(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_ZipCode) === RegisterUserInputValidationResultCodes.Blank_ZipCode) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_ZipCode)
            setErrorPostalZone(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_Country) === RegisterUserInputValidationResultCodes.Blank_Country) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_Country)
            setErrorSelectedCountry(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorAddress) === RegisterUserInputValidationResultCodes.CharErrorAddress) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorAddress)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.CharErrorPhone) === RegisterUserInputValidationResultCodes.CharErrorPhone) {
            validationErrorMessages.push(InputValidationErrorMessages.CharErrorPhone)
            setErrorPhone(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.PhoneFormatError) === RegisterUserInputValidationResultCodes.PhoneFormatError) {
            validationErrorMessages.push(InputValidationErrorMessages.PhoneFormatError)
            setErrorPhone(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.Blank_ContactSource) === RegisterUserInputValidationResultCodes.Blank_ContactSource) {
            validationErrorMessages.push(InputValidationErrorMessages.Blank_ContactSource)
            setErrorContactSource(true)
        }
        if ((inputFieldsErrorCode & RegisterUserInputValidationResultCodes.MissingAcademicUserType) === RegisterUserInputValidationResultCodes.MissingAcademicUserType) {
            validationErrorMessages.push(InputValidationErrorMessages.MissingAcademicUserType)
            setErrorAcademicUserType(true)
        }
        return validationErrorMessages
    }

    const handleAcceptChecked = (isChecked: boolean) => {
        isChecked ? setAgreeButtonDisabled(false) : setAgreeButtonDisabled(true)
    }

    const GFDToastMessageByResultCode = (resultCode: RegisterUserResultCodes) => {
        switch (resultCode) {
            case RegisterUserResultCodes.Success:
                GFDToastSuccess(ResultMessages.Success)
                break
            case RegisterUserResultCodes.InputDataValidationError:
                GFDToastError(ResultMessages.InputDataValidationError)
                break
            case RegisterUserResultCodes.UserBlackListed:
                GFDToastError(ResultMessages.UserBlackListed)
                break
            case RegisterUserResultCodes.UserAlreadyExists:
                GFDToastWarning(ResultMessages.UserAlreadyExists)
                break
            case RegisterUserResultCodes.RestrictedDomainUser:
                GFDToastError(ResultMessages.RestrictedDomainUser)
                break
            case RegisterUserResultCodes.Failure:
                GFDToastError(ResultMessages.Failure)
                break
            case RegisterUserResultCodes.EmailFailure:
                GFDToastWarning(ResultMessages.EmailFailure)
                break
            case RegisterUserResultCodes.InvalidAnonymousUser:
                GFDToastError(ResultMessages.InvalidAnonymousUser)
                break
            default:
                GFDToastError(ResultMessages.Default)
                break
        }
    }

    const sendRegisterUserRequest = () => {
        const countryValue: string = selectedCountry ? selectedCountry.value : ''
        const request: RegisterUserRequest = {
            Email: email,
            ReEnterEmail: renterEmail,
            FirstName: firstName,
            LastName: lastName,
            Position: position,
            AcademicUserType: academicUserType,
            Organization: orgName,
            OrgType: orgType,
            Address: street,
            Address2: street2,
            City: city,
            State: state,
            ZipCode: postalZone,
            Country: countryValue,
            Phone: phone,
            ContactSource: contactSource,
            AnonymousUserID: anonymousUserID,
            ExistingOrgID: existingOrgID,
            CampaignID: campaignID
        }

        setShowLoadingModal(true)
        registerUser(request).then(
            (result: RegisterUserResult) => {
                if (result.resultCode === RegisterUserResultCodes.Success) {
                    setIsEmailFailed(false)
                    // Display Success Modal
                    setShowRegistrationSuccessModal(true)
                } else {
                    // Display Error Messages
                    if (result.resultCode === RegisterUserResultCodes.InputDataValidationError) {
                        const errorMessages: string[] = getErrorMessages(result.missingInputValidationResult)
                        setErrorLines(errorMessages)
                    } else if (result.resultCode === RegisterUserResultCodes.UserBlackListed) {
                        setErrorLines([ResultErrorDetailedMessages.UserBlackListed])
                    } else if (result.resultCode === RegisterUserResultCodes.UserAlreadyExists) {
                        setErrorLines([ResultErrorDetailedMessages.UserAlreadyExists])
                    } else if (result.resultCode === RegisterUserResultCodes.RestrictedDomainUser) {
                        setErrorLines([ResultErrorDetailedMessages.RestrictedDomainUser])
                    } else if (result.resultCode === RegisterUserResultCodes.EmailFailure) {
                        setIsEmailFailed(true)
                        setShowRegistrationSuccessModal(true)
                    } else if (result.resultCode === RegisterUserResultCodes.InvalidAnonymousUser) {
                        setErrorLines([ResultErrorDetailedMessages.InvalidAnonymousUser])
                    } else {
                        setErrorLines([ResultErrorDetailedMessages.Failure])
                    }
                }
                GFDToastMessageByResultCode(result.resultCode)
                setShowLoadingModal(false)
            },
            //Reject Promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                setShowLoadingModal(false)
            })
    }

    const validateFieldsAndDisplayErrors = () => {
        if (!hasRequestBeenSubmittedBefore) {
            return
        }
        const inputValidationResult: RegisterUserInputValidationResultCodes = validateFields()
        if (inputValidationResult !== RegisterUserInputValidationResultCodes.AllValid) {
            const errorMessages: string[] = getErrorMessages(inputValidationResult)
            setErrorLines(errorMessages)
        } else {
            setErrorLines([])
        }
    }

    const handleAgree = () => {
        gaLogEvent('Clicked Agree Button', gaFinaeonWebAppEventCategories.Register, 'handleAgree')
        setHasRequestBeenSubmittedBefore(true)
        // Clear previous errors
        setErrorLines([])
        const inputValidationResult: RegisterUserInputValidationResultCodes = validateFields()
        if (inputValidationResult !== RegisterUserInputValidationResultCodes.AllValid) {
            const errorMessages: string[] = getErrorMessages(inputValidationResult)
            setErrorLines(errorMessages)
            GFDToastError(ResultMessages.InputDataValidationError)
        } else {
            GFDToastInfo(ResultMessages.Submitted)
            sendRegisterUserRequest()
        }
    }

    const populateCountryTable = () => {
        const popCountryRequest: PopulateCountryRequest = {
            database: 'GFDatabase'
        }
        populateCountry2D(popCountryRequest).then(
            (countriesLabelValues: LabelValuePair[]) => {
                const countriesWithBlank: LabelValuePair[] = [{ label: '', value: '' }].concat(countriesLabelValues)
                setCountryList(countriesWithBlank)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                // any error occurs here, display standard message
                GFDToastError(ServerErrorMessage)
            }
        )
    }

    const isAnonymousUserRegistrationRequest = () => {
        checkAnonymousAccessRegistration().then(
            (checkAnonResponse: CheckAnonymousAccessResult) => {
                if (checkAnonResponse.isAnonymousAccess) {
                    const anonOrgData: AnonymousUserOrgData = checkAnonResponse.anonymousUserOrgData
                    setAnonymousUserID(anonOrgData.anonymousUserID)
                    setOrgType(anonOrgData.orgType)
                    setOrgName(anonOrgData.orgName)
                    setStreet(anonOrgData.street1)
                    setStreet2(anonOrgData.street2)
                    setCity(anonOrgData.city)
                    setState(anonOrgData.province)
                    // undefined is specified here since Array.Find() returns LabelValuePair | undefined
                    const matchingCountryEntry: LabelValuePair | undefined = countryList.find((entry: LabelValuePair): boolean => { return entry.value === anonOrgData.country })
                    if (matchingCountryEntry) {
                        setSelectedCountry(matchingCountryEntry)
                    }
                    setPostalZone(anonOrgData.postalZone)
                    setPhone(anonOrgData.phone)
                    setDisableOrgName(true)
                    setDisableOrgType(true)
                } else {
                    setAnonymousUserID(-1)
                    setDisableOrgName(false)
                    setDisableOrgType(false)
                }
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setAnonymousUserID(-1)
                setDisableOrgName(false)
                setDisableOrgType(false)
            }
        )
    }

    const checkOrgExistsByEmailDomainRequest = (enteredEmail: string) => {

        const request: CheckExistingOrgRequest = {
            email: enteredEmail
        }

        CheckExistingOrg(request).then(
            (emailCheckedResponse: CheckExistingOrgResult) => {
                if (emailCheckedResponse.orgID > -1) {
                    setExistingOrgID(emailCheckedResponse.orgID)
                    setOrgType(emailCheckedResponse.orgType)
                    setOrgName(emailCheckedResponse.orgName)
                    setStreet(emailCheckedResponse.street1)
                    setStreet2(emailCheckedResponse.street2)
                    setCity(emailCheckedResponse.city)
                    setState(emailCheckedResponse.province)
                    const matchingCountryEntry: LabelValuePair | undefined = countryList.find((entry: LabelValuePair): boolean => { return entry.value === emailCheckedResponse.country })
                    if (matchingCountryEntry) {
                        setSelectedCountry(matchingCountryEntry)
                    }
                    setPostalZone(emailCheckedResponse.postalZone)
                    setPhone(emailCheckedResponse?.phone || '')

                    setDisableOrgName(true)
                    setDisableOrgType(true)
                } else {
                    setExistingOrgID(-1)
                    setDisableOrgName(false)
                    setDisableOrgType(false)
                }
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setExistingOrgID(-1)
                setDisableOrgName(false)
                setDisableOrgType(false)
            }
        )
    }

    useEffect(() => setTriggerOnce(true), [])
    useEffect(() => {
        if (triggerOnce) {
            populateCountryTable()
            setTriggerOnce(false)
        }
    }, [triggerOnce])
    useEffect(() => {
        if (countryList.length > 1) {
            isAnonymousUserRegistrationRequest()
        }
    }, [countryList])
    useEffect(validateFieldsAndDisplayErrors, [firstName, lastName, orgName, orgType, email, renterEmail, position, street, street2, city, state, postalZone, selectedCountry, phone, contactSource, academicUserType])
    useEffect(() => {
        if (anonymousUserID === -1) {
            if (email === renterEmail && emailValidation(email) && emailValidation(renterEmail)) {
                checkOrgExistsByEmailDomainRequest(email)
            } else {
                setExistingOrgID(-1)
                setDisableOrgName(false)
                setDisableOrgType(false)
            }
        }
    }, [email, renterEmail])
    useEffect(() => {
        if (orgType === 'Academic') {
            setHideAcademicUserType(false)
        } else {
            setHideAcademicUserType(true)
            setAcademicUserType('')
        }
    }, [orgType])
    useEffect(() => {
        const sourceParamValue: string | null = registerPathQueryParams.get(queryParameterSource)
        if (sourceParamValue && sourceParamValue.trim() !== '') {
            setCampaignID(sourceParamValue)
        } else {
            setCampaignID(null)
        }
    }, [registerPathQueryParams])
    useEffect(() => {
        if (campaignID) {
            setContactSource('Marketing Campaign')
            setHideContactSource(true)
        } else {
            setContactSource('')
            setHideContactSource(false)
        }
    }, [campaignID])

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    return (
        <div id='registrationForm'>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
            <ErrorHandler {...errorHandlerProps} />
            {/* <UnorderedMultiErrorLines lines={errorLines} /> */}
            <Paper elevation={0} style={{ padding: 5, backgroundColor: 'white' }}>
                <h4>Registration Form</h4>
                <Divider sx={{ borderColor: 'black', marginBottom: 5 }} />
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8}>
                        <TextField id='firstNameInput' label='First Name' variant='outlined' value={firstName} style={{ width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorFirstName(false)
                                setFirstName(e.target.value)
                            }}
                            error={errorFirstName}
                        />
                    </Grid>
                    <Grid item md={8}>
                        <TextField id='lastNameInput' label='Last Name' variant='outlined' value={lastName} style={{ width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorLastName(false)
                                setLastName(e.target.value)
                            }}
                            error={errorLastName}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8} style={{ minWidth: 300 }}>
                        <TextField id='emailInput' label='Email' variant='outlined' value={email} style={{ width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorEmail(false)
                                setErrorRenterEmail(false)
                                setEmail(e.target.value)
                            }}
                            error={errorEmail}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16}>
                    <Grid item md={8} style={{ minWidth: 300 }}>
                        <TextField id='renterEmailInput' label='Reenter Email' variant='outlined' value={renterEmail} style={{ width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorEmail(false)
                                setErrorRenterEmail(false)
                                setRenterEmail(e.target.value)
                            }}
                            error={errorRenterEmail}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' sx={{ justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '0px', paddingLeft: '5px', ...regStyles.categorySpacing }} spacing={1}>
                    <Grid item>
                        <InfoRedSvgIcon fontSize='small' />
                    </Grid>
                    <Grid item sx={{ marginTop: '1px' }}>
                        <Typography variant='subtitle1' sx={{ color: errorRedColor }}>
                            Work Email Required
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8}>
                        <TextField id='orgNameInput' label='Organization' variant='outlined' value={orgName} disabled={disableOrgName} style={{ width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorOrgName(false)
                                setOrgName(e.target.value)
                            }}
                            error={errorOrgName}
                        />
                    </Grid>
                    <Grid item md={4} style={{ minWidth: 240, maxWidth: 240 }}>
                        <TextField
                            id='orgTypeInput'
                            label="Business Type"
                            defaultValue={''}
                            value={orgType}
                            disabled={disableOrgType}
                            select
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorOrgType(false)
                                setOrgType(e.target.value)
                            }}
                            error={errorOrgType}
                        >
                            {orgTypeDropDownOptions.map((option: string) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={regStyles.categorySpacing}>
                    <Grid item md={8}>
                        <TextField id='positionInput' label='Title (Optional)' variant='outlined' value={position} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorPosition(false)
                                setPosition(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorPosition}
                        />
                    </Grid>
                    <Grid item md={4} style={{ minWidth: 240, maxWidth: 240 }}>
                        <TextField
                            id='userTypeInput'
                            label="User Type"
                            defaultValue={''}
                            value={academicUserType}
                            hidden={hideAcademicUserType}
                            select
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            onChange={(e: any) => {
                                setErrorAcademicUserType(false)
                                setAcademicUserType(e.target.value)
                            }}
                            error={errorAcademicUserType}
                        >
                            {academicUserTypeDropDownOptions.map((option: string) => (
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8}>
                        <TextField id='streetInput' label='Street 1 (Optional)' variant='outlined' value={street} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorStreet(false)
                                setStreet(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorStreet}
                        />
                    </Grid>
                    <Grid item md={8}>
                        <TextField id='street2Input' label='Street 2 (Optional)' variant='outlined' value={street2} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorStreet2(false)
                                setStreet2(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorStreet2}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8}>
                        <TextField id='cityInput' label='City' variant='outlined' value={city} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorCity(false)
                                setCity(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorCity}
                        />
                    </Grid>
                    <Grid item md={8}>
                        <TextField id='stateInput' label='State/Province' variant='outlined' value={state} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorState(false)
                                setState(e.target.value)
                            }}
                            inputProps={{ maxLength: 2 }}

                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorState}
                        />
                    </Grid>

                </Grid>
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={8}>
                        <TextField id='postalZoneInput' label='Postal Code' variant='outlined' value={postalZone} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorPostalZone(false)
                                setPostalZone(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorPostalZone}
                        />
                    </Grid>
                    <Grid item md={8} style={{ minWidth: 300 }}>
                        <Autocomplete
                            id='countrySelection'
                            defaultValue={selectedCountry}
                            value={selectedCountry}
                            style={{ width: '100%' }}
                            options={countryList}
                            getOptionLabel={(option) => option.label}
                            onChange={(event: any, newValue: LabelValuePair | null, reason: string) => {
                                setErrorSelectedCountry(false)
                                setSelectedCountry(newValue)
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params}
                                label="Country"
                                error={errorSelectedCountry}
                            />}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={16} sx={regStyles.categorySpacing}>
                    <Grid item md={8}>
                        <TextField id='phoneInput' label='Phone (Optional)' variant='outlined' value={phone} style={{ width: '100%' }}
                            onChange={(e) => {
                                setErrorPhone(false)
                                setPhone(e.target.value)
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { fontWeight: 'bold' }
                            }}
                            error={errorPhone}
                        />
                    </Grid>
                </Grid>
                {!hideContactSource && (
                    <Grid container spacing={1} columns={16} sx={regStyles.categorySpacing}>
                        <Grid item style={{ minWidth: 240, maxWidth: 240 }}>
                            <TextField
                                id='contactSourceInput'
                                label="How did you hear about us?"
                                variant='outlined'
                                defaultValue={''}
                                value={contactSource}
                                style={{ width: '100%' }}
                                select
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold' }
                                }}
                                onChange={(e: any) => {
                                    setErrorContactSource(false)
                                    setContactSource(e.target.value)
                                }}
                                error={errorContactSource}
                            >
                                {contactSourceDropDownOptions.map((option: string) => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={1} columns={16} sx={{ paddingBottom: 1 }}>
                    <Grid item md={16}>
                        <span style={{ display: 'inline-block', marginLeft: 12, width: '100%' }}>
                            By checking this box and clicking Agree & Join, you acknowledge that you have read and understood
                            <span style={regStyles.hyperlinkStyle} onClick={() => {
                                gaLogEvent('Clicked on this Agreement Link', gaFinaeonWebAppEventCategories.Register, 'this Agreement')
                                setSelectedLegalType(LegalTypes.Terms)
                                setShowTermsModal(true)
                            }}>&nbsp;this Agreement&nbsp;</span>
                            and you are hereby confirming your authority to accept and intent to be bound by this Agreement in accordance with its terms.
                        </span>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center' columns={16} rowSpacing={0.5} columnSpacing={1} sx={{ paddingBottom: 2 }}>
                    <Grid item>
                        <CheckboxBlue
                            id='acceptCheckBox'
                            checked={iAcceptChecked}
                            onChange={(e: any) => {
                                setIAcceptChecked(e.currentTarget.checked)
                                handleAcceptChecked(e.currentTarget.checked)
                            }}
                            inputProps={{
                                'title': 'I Accept',
                                'aria-label': 'I Accept'
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <label><b>I Accept</b></label>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='flex-start' alignItems='center' columns={16} rowSpacing={0.5} columnSpacing={1}>
                    <Grid item>
                        <BtnEnabledDisabled id='agreeButton' variant='contained' style={{ marginLeft: 10 }} onClick={handleAgree} disabled={agreeButtonDisabled}>Agree & Join</BtnEnabledDisabled>
                    </Grid>
                </Grid>
                <Grid container direction='row' justifyContent='center' alignItems='center' rowSpacing={1} columnSpacing={10} sx={regStyles.hyperlinkStyle}>
                    <Grid item>
                        <span onClick={() => {
                            gaLogEvent('Clicked on Privacy Policy Link', gaFinaeonWebAppEventCategories.Register, 'Privacy Policy')
                            setSelectedLegalType(LegalTypes.Privacy)
                            setShowTermsModal(true)
                        }}
                        >
                            Privacy Policy
                        </span>
                    </Grid>
                    <Grid item>
                        <span onClick={() => {
                            gaLogEvent('Clicked on Cookie Policy Link', gaFinaeonWebAppEventCategories.Register, 'Cookie Policy')
                            setSelectedLegalType(LegalTypes.Cookie)
                            setShowTermsModal(true)
                        }}>Cookie Policy</span>
                    </Grid>
                    <Grid item>
                        <span onClick={() => {
                            gaLogEvent('Clicked on Terms and Conditions Link', gaFinaeonWebAppEventCategories.Register, 'Terms and Conditions')
                            setSelectedLegalType(LegalTypes.Terms)
                            setShowTermsModal(true)
                        }}>Terms and Conditions</span>
                    </Grid>
                </Grid>
            </Paper>
            <RegistrationSuccess setShow={setShowRegistrationSuccessModal} showModal={showRegistrationSuccessModal} isEmailFailed={isEmailFailed} />
            <LegalModal showModal={showTermsModal} setShowModal={setShowTermsModal} type={selectedLegalType} />
        </div>
    )
}

const regStyles = {
    hyperlinkStyle: {
        color: '#006080',
        textDecoration: 'underline',
        cursor: 'pointer',
        paddingTop: '15px',
    },
    categorySpacing: {
        paddingBottom: 5
    }
}

export default RegistrationForm
