import { Chip, Divider, TextField } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTable from '@material-table/core'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import { NotificationHubUser } from '../../../Models/DataModels/SignalR/NotificationHubUser'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { SignalRMessageRequest, SignalRSignOutRequest } from '../../../Models/DataModels/SignalR/NotificationHubRequests'
import { useMemo, useState } from 'react'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../Common/Modals/AlertModal'
import { ApplicationType } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { CustomBtnBlue, MessageBtn, LoggoffBtn } from '../../Common/GlobalSettings/CustomStyles'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import { SortOrderStringType, getComparator, sort } from '../../../Models/DataModels/Common/SortModel'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface OnlineUserProps {
    activeUsers: NotificationHubUser[],
    setSignalRMessageRequest: (request: SignalRMessageRequest | null) => void,
    setSignalRSignOutRequest: (request: SignalRSignOutRequest | null) => void,
}

const OnlineUsers = ({
    activeUsers,
    setSignalRMessageRequest,
    setSignalRSignOutRequest
}: OnlineUserProps) => {
    const titleForAllUsers = 'Enter message to send to all logged in clients'
    const titleForSelectedUser = 'Enter message to send to selected client'
    const all = 'All clients online'
    const errorMessage = 'Message is empty'

    const [showModal, setShowModal] = useState<boolean>(false)
    const [globalMessage, setGlobalMessage] = useState<string | null>()
    const [recipient, setRecipient] = useState<string | null>()
    const [title, setTitle] = useState<string | null>()
    const [error, setError] = useState<boolean>(false)
    const [helperText, setHelperText] = useState<string | null>()
    const [connectionID, setConnectionID] = useState<string | null>()
    
    const reset = () => {
        setShowModal(false)
        setGlobalMessage(null)
        setRecipient(null)
        setTitle(null)
        setError(false)
        setHelperText(null)
        setConnectionID(null)
    }

    const renderModalBody = () => {
        return (
            <>
                <Chip label={`Recipient: ${recipient}`} color="primary" style={{backgroundColor: globalStyles.siteThemeColor}} />
                <Divider light style={{marginTop: '8px'}}/>
                <TextField
                    style={{width: '100%', marginTop: '8px'}}
                    id="globalMessageTextField"
                    label={title}
                    value={globalMessage || ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setHelperText(null)
                        setError(false)
                        setGlobalMessage(event.target.value)
                    }}
                    helperText={helperText}
                    error={error}
                    multiline
                />
            </>
        )
    }
        
    const alertModalProps: AlertModalProps = {
        showModal,
        setShowModal,
        AlertTitle: 'Send Message',
        AlertContent: renderModalBody(),
        AlertButtons: [
            {
                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => {
                    reset()
                },
                isPrimary: false,
            },
            {
                type: AlertButtonType.OK,
                display: 'Send',
                onClick: () => {
                    if (!globalMessage?.trim()) {
                        setHelperText(errorMessage)
                        setError(true)
                        return
                    }
                    
                    setSignalRMessageRequest({ toUserConnectionID: connectionID, message: globalMessage })

                    reset()
                    
                },
                isPrimary: true,
            },
        ],
        onAlertClose: () => {
            reset()
            return true
        }
    }
    
    const sendGlobalMessage = () => {
        gaLogEvent('Clicked on Send Global Message Button', gaFinaeonWebAppEventCategories.Admin, 'sendGlobalMessage')
        reset()
        setConnectionID(null)
        setTitle(titleForAllUsers)
        setRecipient(all)
        setShowModal(true)
    }

    const sendIndividualMessage = (id: string, name: string) => {
        reset()
        setConnectionID(id)
        setTitle(titleForSelectedUser)
        setRecipient(name)
        setShowModal(true)
    }

    const columns = [
        { title: 'Connection ID', field: 'connnectionId', hidden: true },
        { title: 'User Name', field: 'userName' },
        { title: 'Category', field: 'specialCategory' },
        { title: 'Organization', field: 'orgName' },
        { title: 'Action', field: 'action' },
        { title: 'Login Time', field: 'loginTime' },
        { title: 'Application Type', field: 'applicationType', render: (rowData: NotificationHubUser) => ApplicationType[Number(rowData.applicationType)] || ''},
        { title: 'Application', field: 'applicationName' },
        { title: 'IP', field: 'userIP' },
        { title: 'Session ID', field: 'hash', render: (rowData: NotificationHubUser) => Math.abs(rowData?.hash || 0).toString(16) },
        { title: 'Log Off', field: 'logOff', sort: false, render: (rowData: NotificationHubUser) =>
            rowData && (
            <LoggoffBtn
                variant='contained'
                color='primary'
                onClick={(event) => {
                    gaLogEvent('Clicked on Admin Log Off Button', gaFinaeonWebAppEventCategories.Admin, 'Admin Log Off')
                    setSignalRSignOutRequest({ signOutUserConnectionID: rowData.connectionId })
                }}
            >
                Log Off
            </LoggoffBtn>
        )},
        { title: 'Message', field: 'message', sort: false, render: (rowData: NotificationHubUser) =>
            rowData && (
            <MessageBtn
                variant='outlined'
                // color='secondary'
                onClick={(event) => {
                    gaLogEvent('Clicked on Admin Message Button', gaFinaeonWebAppEventCategories.Admin, 'Admin Message')
                    sendIndividualMessage(rowData.connectionId,rowData.userName)
                }}
            >
                Message
            </MessageBtn>
        )}
    ]

    const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
    const [sortColumn, setSortColumn] = useState<string>('')
    const sortedData: any[] = useMemo(
        () =>
        sort(activeUsers, getComparator(sortOrder, sortColumn)),
        [sortOrder, sortColumn, activeUsers],
    )

    return (
        <div>
            <AlertModal {...alertModalProps} />
            <div style={{ display: 'flex', flexWrap: 'wrap' as 'wrap', gridRow: '1 / span 2', marginTop: '8px' }}>
                <h3>Users Currently Online</h3>
                <CustomBtnBlue variant='contained' style={{ marginLeft: 15, marginBottom: 15 }} onClick={(event) => sendGlobalMessage()}>Send Global Message</CustomBtnBlue>
            </div>
            <Divider sx={{ borderColor: 'black', marginBottom: 5, width: '100%' }} />
            <ThemeProvider theme={DefaultMaterialTheme}>
                <MaterialTable
                    columns={columns}
                    data={sortedData}
                    title='Users Currently Online'
                    options={{
                        ...MaterialTableOptions,
                        selection: false
                    }}
                    components={{
                        Header:
                           props => (
                             <EnhancedTableHead { ...{ sortData: {sortColumn, sortOrder, setSortColumn, setSortOrder }, columns } } />
                           )
                    }}
                />
            </ThemeProvider>
        </div>
    )
}

export default OnlineUsers
