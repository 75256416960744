import { Grid, Paper } from '@mui/material'
import React from 'react'
import { CapitalizationData } from '../../../../../../Models/DataModels/Common/ToolsModel'
import DenseMCapTable from './DenseMCapTable'
import { formatNumberEnUSLocalString } from '../../../../../../Services/UtilityService'

export interface CapitalizationResultGridViewProps {
    listResult: CapitalizationData[]
}

interface CapDataSet {
    key: number
    title: string,
    rows: CapitalizationData[],
    titleBackgroundColor?: string
}

interface HSL {
    h: number,
    s: number,
    l: number
}

const CapitalizationResultGridView = ({
    listResult
}: CapitalizationResultGridViewProps) => {

    const setCount: number = 10

    const createCapDataSet = (key: number, capData: CapitalizationData[]): CapDataSet => {
        const minEntry: number = capData[capData.length - 1].value
        const maxEntry: number = capData[0].value
        const titleRange: string = `Range: ${formatNumberEnUSLocalString(minEntry, 2)} - ${formatNumberEnUSLocalString(maxEntry, 2)} (MCap in ${capData[0].currency})`
        const color: string = 'rgb(0, 255, 0)'
        const capSet: CapDataSet = {
            key: key,
            title: titleRange,
            rows: capData,
            titleBackgroundColor: color
        }
        return capSet
    }

    const createCapDataSetRows = (capData: CapitalizationData[]): CapDataSet[] => {
        let keyCounter = 1
        let resultRows: CapDataSet[] = []

        let sortedCapData: CapitalizationData[] = capData.sort((a, b) => b.value - a.value)
        if (sortedCapData.length < 10) {
            let setData: CapDataSet = createCapDataSet(keyCounter, sortedCapData)
            resultRows.push(setData)
            return resultRows
        }

        let tempSetData: CapitalizationData[] = []
        for (var i = 0; i < sortedCapData.length; i++) {
            let currentCapData: CapitalizationData = sortedCapData[i]
            tempSetData.push(currentCapData)
            if (tempSetData.length % setCount === 0) {
                let tempSet: CapDataSet = createCapDataSet(keyCounter, tempSetData)
                resultRows.push(tempSet)
                tempSetData = []
                keyCounter++
            }
        }
        if (tempSetData.length > 0) {
            let tempSet: CapDataSet = createCapDataSet(keyCounter, tempSetData)
            resultRows.push(tempSet)
            tempSetData = []
            keyCounter++
        }
        return resultRows
    }

    const setChipColor = (capData: CapDataSet[]) => {
        if (!capData || capData.length === 0) {
            return
        }

        const colorCount: number = capData.length
        const hslIncrementValue: number = 200 / colorCount
        const hslColor: HSL = {
            h: 120,
            s: 40,
            l: 65
        }
        capData.forEach((value: CapDataSet) => {
            const colorString: string = `hsl(${hslColor.h},${hslColor.s}%,${hslColor.l}%)`
            value.titleBackgroundColor = colorString
            hslColor.h = hslColor.h - (hslIncrementValue)
        })
    }

    if (listResult.length === 0) {
        return (
            <Paper elevation={3} style={{ padding: 5 }}>
                <p>No Data</p>
            </Paper>
        )
    }

    let setRows: CapDataSet[] = createCapDataSetRows(listResult)

    // set header/chip colors
    setChipColor(setRows)

    let rowCountHalved: number = setRows.length / 2
    let roundedHalvedRowCount: number = Math.round(rowCountHalved)

    const firstHalfRows: CapDataSet[] = setRows.splice(0, roundedHalvedRowCount)
    const secondHalfRows: CapDataSet[] = setRows

    return (
        <Grid container spacing={1} columns={16}>
            <Grid item md={8}>
                <Grid container direction='column' spacing={2} justifyContent='space-evenly' alignItems='center' >
                    {firstHalfRows.map(
                        (row: CapDataSet) => (
                            <Grid item md={4} key={row.key}>
                                <DenseMCapTable title={row.title} rows={row.rows} titleBackgroundColor={row.titleBackgroundColor} />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid item md={8}>
                <Grid container direction='column' spacing={2} justifyContent='space-evenly' alignItems='center' >
                    {secondHalfRows.map(
                        (row: CapDataSet) => (
                            <Grid item md={4} key={row.key}>
                                <DenseMCapTable title={row.title} rows={row.rows} titleBackgroundColor={row.titleBackgroundColor} />
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CapitalizationResultGridView
