import React from 'react'
import { CapitalizationSummary } from '../../../../../../Models/DataModels/Common/ToolsModel'
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Cell, LabelList, Legend } from 'recharts'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import { AppConfigurations, chartSizes } from '../../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { graphCompanyLogoSVG, renderTitleOnGraph, sourceTextSVG } from '../../../../../Common/Modals/GraphUtil'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../../Models/DataModels/Common/OutputFormatModel'
import { CreateDataFileRequest, FileDownloadRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { CreateDataFileResponse } from '../../../../../../Models/DataModels/Responses/ToolsResponses'
import { downloadFileFromServer } from '../../../../../../Services/DownloadService'
import { createDownloadDataFile } from '../../../../../../Services/ToolsService'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { createDownloadData } from './CapitalizationUtil'
import { formatNumberEnUSLocalString } from '../../../../../../Services/UtilityService'

export interface CapSummaryBarChartProps {
    chartTitle: string,
    headerName: string,
    capSummaryData: CapitalizationSummary[],
    availableColorCodes: string[],
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const CapSummaryBarChart = ({
    chartTitle,
    headerName,
    capSummaryData,
    availableColorCodes,
    setErrorResponse
}: CapSummaryBarChartProps) => {

    const legendData = (): Payload[] => {
        const payload: Payload[] = []
        capSummaryData.forEach(
            (entry: CapitalizationSummary, index: number) => {
                payload.push(
                    {
                        id: entry.sector,
                        type: 'square',
                        value: entry.sector,
                        color: availableColorCodes[index]
                    }
                )
            }
        )
        return payload
    }

    const downloadDataFile = (format: OutputFormatValueType) => {
        const dataFileRequest: CreateDataFileRequest = {
            fileName: 'Capitalization Summary Bar Chart',
            fileData: createDownloadData(headerName, capSummaryData),
            outputFormat: format
        }
        createDownloadDataFile(dataFileRequest).then(
            (fileCreateResponse: CreateDataFileResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const imageID: string = 'capSummaryBarChart'

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    return (
        <>
            <div style={{ paddingBottom: 5 }}>
                <GraphDownloadOptions {...graphDownloadOptionsProps} />
            </div>
            <ResponsiveContainer id={imageID} width='100%' height={chartSizes.large} className={'large-graph-container'}>
                <BarChart
                    width={500}
                    height={300}
                    data={capSummaryData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='sector' hide={true} />
                    <YAxis />
                    <Tooltip formatter={(value: number) => formatNumberEnUSLocalString(value)} />
                    {capSummaryData.length > AppConfigurations.chartLabelDisplayThreshold && <Legend payload={(legendData())} />}
                    <Bar dataKey='marketCap' fill='#82ca9d' name='Market Cap'>
                        {capSummaryData.map(
                            (entry: CapitalizationSummary, index: number) => {
                                return <Cell key={`cell=${index}`} fill={availableColorCodes[index]} name={entry.sector} />
                            }
                        )}
                        {capSummaryData.length <= AppConfigurations.chartLabelDisplayThreshold && <LabelList dataKey='sector' position='top' angle={0} offset={10} />}
                    </Bar>
                    {graphCompanyLogoSVG()}
                    {sourceTextSVG()}
                    {renderTitleOnGraph(chartTitle)}
                </BarChart>
            </ResponsiveContainer>
        </>

    )
}

export default CapSummaryBarChart
