import { CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { UserAccessActivity } from '../../../Models/DataModels/Common/UserAccessModel'
import { QuotaBreakdownInfo, QuotaInfo } from '../../../Models/DataModels/Responses/AccountResponses'
import { GFDToastError } from '../../Common/Utility/GFDToastify'
import { useEffect } from 'react'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import { CSSProperties } from '@mui/material/styles/createTypography'
import { globalStyles } from '../../Common/GlobalSettings/GlobalStyles'
import RefreshMembersList from '../../Icons/RefreshMembersIcon'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface ActivityProps {
  ActivityInfo: UserAccessActivity[],
  TriggerRefreshActivityInfo: () => void,
  QuotaInfo: QuotaInfo[],
  TriggerRefreshQuotaInfo: () => void
  QuotaBreakdownInfo: QuotaBreakdownInfo[],
  TriggerRefreshQuotaBreakdownInfo: () => void,
  ActivityInfoError: string | undefined,
  QuotaInfoError: string | undefined,
  QuotaBreakdownInfoError: string | undefined,
  ActivityInfoRequestInTransit: boolean,
  QuotaInfoRequestInTransit: boolean,
  QuotaBreakdownInfoRequestInTransit: boolean,
  ClearActivityInfoError: () => void,
  ClearQuotaInfoError: () => void,
  ClearQuotaBreakdownInfoError: () => void,
  hideQuotaBreakdown?: boolean
}

const Activity = ({
  ActivityInfo,
  TriggerRefreshActivityInfo,
  QuotaInfo,
  TriggerRefreshQuotaInfo,
  QuotaBreakdownInfo,
  TriggerRefreshQuotaBreakdownInfo,
  ActivityInfoRequestInTransit,
  QuotaInfoRequestInTransit,
  QuotaBreakdownInfoRequestInTransit,
  ActivityInfoError,
  QuotaInfoError,
  QuotaBreakdownInfoError,
  ClearActivityInfoError,
  ClearQuotaInfoError,
  ClearQuotaBreakdownInfoError,
  hideQuotaBreakdown
}: ActivityProps) => {

  useEffect(() => {
    if (ActivityInfoError?.trim()) {
      GFDToastError(ActivityInfoError)
      ClearActivityInfoError()
    }
  }, [ActivityInfoError])

  useEffect(() => {
    if (QuotaInfoError?.trim()) {
      GFDToastError(QuotaInfoError)
      ClearQuotaInfoError()
    }
  }, [QuotaInfoError])

  useEffect(() => {
    if (QuotaBreakdownInfoError?.trim()) {
      GFDToastError(QuotaBreakdownInfoError)
      ClearQuotaBreakdownInfoError()
    }
  }, [QuotaBreakdownInfoError])

  const refreshAll = () => {
    gaLogEvent('Clicked on Access Activity Refresh Button', gaFinaeonWebAppEventCategories.Account, 'refreshAll')
    TriggerRefreshActivityInfo()
    TriggerRefreshQuotaInfo()
    TriggerRefreshQuotaBreakdownInfo()
  }

  const renderRefreshAll = () => {
    return (<>
      <div style={styles.refreshAll as CSSProperties}>
        <IconButton title='Refresh Activity Info' aria-label='Refresh Activity Info' component='label' onClick={refreshAll}>
          <RefreshMembersList style={{ fontSize: '1.2em' }} />
        </IconButton>
      </div>
      <br />
    </>)
  }

  const renderProgress = () => {
    return <>
      <IconButton aria-label='Refresh Activity Info' component='label'>
        <CircularProgress style={globalStyles.circularProgress} title='Refresh Activity Info' aria-label='Refresh Activity Info' />
      </IconButton>
    </>
  }

  const renderActivityTitle = () => {
    const title = 'Activity'

    return (<>
      {title}

      {ActivityInfoRequestInTransit ?
        renderProgress()
        : <></>
      }
    </>)
  }

  const renderQuotaTitle = () => {
    const title = 'Overall Quota Usage'

    return (<>
      {title}

      {QuotaInfoRequestInTransit ?
        renderProgress()
        : <></>
      }
    </>)
  }

  const renderQuotaBreakdownTitle = () => {
    const title = 'Quota Usage Breakdown'

    return (<>
      {title}

      {QuotaBreakdownInfoRequestInTransit ?
        renderProgress()
        : <></>
      }
    </>)
  }

  const renderActivityInfo = () => {
    return <>
      <h5>{renderActivityTitle()}</h5>
      <TableContainer style={styles.tableContainer} component={Paper}>
        <Table style={styles.table} size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={styles.minimumHeaderCellWidth}>Period</TableCell>
              <TableCell align='right'>Logins</TableCell>
              <TableCell align='right'>Series Graphed</TableCell>
              <TableCell align='right'>GFDatabase Downloaded</TableCell>
              <TableCell align='right'>US stocks Downloaded</TableCell>
              <TableCell align='right'>UK stocks Downloaded</TableCell>
              <TableCell align='right'>Real Estate Downloaded</TableCell>
              <TableCell align='right'>GFD Indices Downloaded</TableCell>
              <TableCell align='right'>Events-In-Time Downloaded</TableCell>
              <TableCell align='right'>Custom Index Downloaded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ActivityInfo.map((row, index) => (
              <TableRow
                key={row.period}
              >
                <TableCell component='th' scope='row'>
                  {row.period}
                </TableCell>
                <TableCell align='right'>{row.logins}</TableCell>
                <TableCell align='right'>{row.seriesGraphed}</TableCell>
                <TableCell align='right'>{row.gfdDownloaded}</TableCell>
                <TableCell align='right'>{row.usStocksDownloaded}</TableCell>
                <TableCell align='right'>{row.ukStocksDownloaded}</TableCell>
                <TableCell align='right'>{row.realEstateDownloaded}</TableCell>
                <TableCell align='right'>{row.gfdIndicesDownloaded}</TableCell>
                <TableCell align='right'>{row.eventsInTimeDownloaded}</TableCell>
                <TableCell align='right'>{row.customIndexDownloaded}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  }

  const renderQuotaInfo = () => {
    return <>
      <h5>{renderQuotaTitle()}</h5>
      <TableContainer style={styles.tableContainer} component={Paper}>
        <Table style={styles.table} size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={styles.minimumHeaderCellWidth}>Category</TableCell>
              <TableCell align='right'>Current Downloads</TableCell>
              <TableCell>Quota Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {QuotaInfo.map((quota, index) => (
              <TableRow
                key={index}
              >
                <TableCell component='th'>{index === 0 ? 'All Series' : ''}</TableCell>
                <TableCell align='right'>{quota.downloadsSoFar}</TableCell>
                <TableCell>{`${quota.limit} series per ${quota.interval}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  }

  const renderQuotaBreakdownInfo = () => {
    return <>
      <h5>{renderQuotaBreakdownTitle()}</h5>
      <TableContainer style={styles.tableContainer} component={Paper}>
        <Table style={styles.table} size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Subset</TableCell>
              <TableCell align='right'>Current Downloads</TableCell>
              <TableCell>Quota Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              QuotaBreakdownInfo.map((quotaBreakdown) =>
                quotaBreakdown.quotaUsage.map((quota, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell component='th'>{index === 0 ? quotaBreakdown.quotaCategory : ''}</TableCell>
                    <TableCell align='right'>{quota.downloadsSoFar}</TableCell>
                    <TableCell>{`${quota.limit} series per ${quota.interval}`}</TableCell>
                  </TableRow>
                )))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  }

  return (
    <div style={styles.scroll}>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <>
          {renderRefreshAll()}
          {renderActivityInfo()}
          <br />
          {renderQuotaInfo()}
          <br />
          {hideQuotaBreakdown !== true && renderQuotaBreakdownInfo()}
        </>
      </ThemeProvider>
    </div>
  )
}

const styles = {
  table: {
    width: 'fit-content',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableContainer: {
    borderRadius: '0px'
  },
  minimumHeaderCellWidth: {
    minWidth: '200px'
  },
  refreshAll: {
    float: 'right',
    clear: 'both'
  },
  scroll: {
    overflow: 'auto'
  }
}

export default Activity