
import { Typography, Grid, Tooltip, styled, Pagination, Stack, IconButton, CircularProgress, Alert } from '@mui/material'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { ChangeEvent, CSSProperties, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FamaFrenchStartScreen, { FamaStateProps } from './FamaFrenchSubComponents/FamaFrenchStartScreen'
import { deleteScreeningReportByScreenID, getScreenReportsByUser } from '../../../../Services/ScreeningService'
import FamaFrenchCard from './FamaFrenchSubComponents/FamaFrenchCard'
import { DeleteScreeningReportRequest, ScreeningDefinition, ScreenReportListRequest, ScreenReportListResponse } from '../../../../Models/DataModels/Requests/ScreeningRequests'
import { LogoutReasonType } from '../../../../Models/DataModels/Requests/AuthRequests'
import AddNewScreening from '../../../Icons/AddNewScreeningIcon'
import CancelScreening from '../../../Icons/CancelScreeningIcon'
import FamaFrenchDeleteModal from '../../../Common/Modals/FamaFrenchDeleteModal'
import FamaFrenchSVG from '../../../Icons/FamaFrenchIcon'
import ArrowExpand from '../../../Icons/ArrowExpandIcon'
import { PaginationItem } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { ArrowForward, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { pathParts, paths } from '../../../../Models/DataModels/Common/RedirectionModel'
import { NotOKResponseModel } from '../../../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandler, ErrorHandlerProps } from '../../../Common/Utility/ErrorHandler'
import { globalStyles } from '../../../Common/GlobalSettings/GlobalStyles'
import { CustomBtnAddEdit, CustomEditButton, DeleteBtn } from '../../../Common/GlobalSettings/CustomStyles'
import { ToolsAccesibilityProps } from '../../../../Models/DataModels/Common/ToolsModel'
import { UserInfo } from '../../../../Models/DataModels/Common/UserInfoModel'
import { GFDToastInfo } from '../../../Common/Utility/GFDToastify'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../Google/analytics'

export interface FamaFrenchProps {
  userInfo: UserInfo | null,
  signOut: (logoutReason: LogoutReasonType) => void,
  accessibilityProps: ToolsAccesibilityProps,
  checkIsTrial: () => boolean
}

const FamaFrench = ({
  userInfo,
  signOut,
  accessibilityProps,
  checkIsTrial
}: FamaFrenchProps) => {

  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()

  const [reportData, setReportData] = useState<ScreeningDefinition[]>([])

  const [expanded, setExpanded] = useState<string | false>(false)
  const [isAddOrEditScreen, setIsAddOrEditScreen] = useState<boolean>(false)
  const [screeningName, setScreeningName] = useState<string>('')
  const [customIndexID, setCustomIndexID] = useState<number | null | undefined>()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [showResetModal, setShowResetModal] = useState<boolean>(false)
  const [isCancel, setIsCancel] = useState<boolean>(false)

  const [toDelete, setToDelete] = useState<boolean>(false)
  const [deleteID, setDeleteID] = useState<number>(-1)

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [perPageCount, setPerPageCount] = useState<number>(5)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [screenReportListRequestInProgress, setScreenReportListRequestInProgress] = useState<boolean>(false)

  const navigate = useNavigate()
  const location: any = useLocation()
  const { expandedScreenID } = useParams()

  const modalProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    setToDelete: setToDelete
  }

  const cancelModalProps = {
    resetAll: () => { },
    showModal: showResetModal,
    setShowModal: setShowResetModal,
    isCancel: true,
    cancelScreening: () => {
      setIsAddOrEditScreen(!isAddOrEditScreen)
      setIsEdit(false)
      setScreeningName('')
      setCustomIndexID(null)
    },
    setIsCancel: setIsCancel
  }

  const editScreeningReport = (screenName: string, customIndexID: number | null | undefined) => {
    setIsEdit(true)
    setScreeningName(screenName)
    setCustomIndexID(customIndexID)
  }

  const openEditScreen = () => {
    setIsAddOrEditScreen(!isAddOrEditScreen)
    setExpanded(false)
  }

  const getListRequest = (resetPageNumber: boolean, requestTotalCount: boolean): ScreenReportListRequest => {
    return { pagination: {
      perPageCount: !perPageCount || perPageCount <= 0 ? 5 : perPageCount,
      pageNumber: !pageNumber || pageNumber <= 0 || resetPageNumber ? 1 : pageNumber,
      isTotalCountRequested: requestTotalCount
    }}
  }

  const refreshScreenReportList = (resetPageNumber: boolean, requestTotalCount: boolean) => {
    if (screenReportListRequestInProgress) {
      GFDToastInfo('Previous Screen Report Refresh Request is still in progress')
      return
    }

    setScreenReportListRequestInProgress(true)
    getScreenReportsByUser(getListRequest(resetPageNumber, requestTotalCount)).then((response: ScreenReportListResponse) => {
      setReportData(response?.screenReports || [])
      if (response?.pagination) {
        if (pageNumber !== response.pagination.pageNumber) setPageNumber(response.pagination.pageNumber)
          if (perPageCount !== response.pagination.perPageCount) setPerPageCount(response.pagination.perPageCount)
  
          if (response.pagination.totalCount || response.pagination.totalCount === 0) {
            setTotalCount(response.pagination.totalCount)
          }
      }
      setScreenReportListRequestInProgress(false)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setErrorResponse(notOKResponseModel)
      setScreenReportListRequestInProgress(false)
    })
  }

  const deleteScreeningReport = () => {
    const request: DeleteScreeningReportRequest = {
      screenID: deleteID
    }
    deleteScreeningReportByScreenID(request).then(() => {
      refreshScreenReportList(false, true)
    },
      (notOKResponseModel: NotOKResponseModel) => {
        setErrorResponse(notOKResponseModel)
      })
    setToDelete(false)
  }

  const handleChangeAccordian = (panel: string) => () => {
    setExpanded(expanded === panel ? false : panel)
  }

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPageNumber(value)
  }

  const updateReportCustomIndex = (report: any) => (customIndex: number | null) => {
    report.customIndex = customIndex
  }

  const generateReports = () => {
    const reportsArray: any = []

    if (reportData && reportData.length > 0) {
      reportData.forEach((report: any, index: number) => {
        reportsArray.push(
          <div key={`ScreenReportItem${index}`}>
            <div style={{ ...styles.section, cursor: 'pointer', backgroundColor: 'rgba(232, 245, 253, 0.3)' }} onClick={handleChangeAccordian(report.screenID)}>
              <Grid container sx={{ padding: 1 }}>
                <Grid item md={10}>
                  <div style={{ display: 'inline', verticalAlign: 'top' }}>
                    <ArrowExpand style={{ fontSize: '1.65rem', ...(report.screenID === expanded ? { transform: 'rotate(90deg)' } : {}) }} />
                  </div>
                  <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <Typography variant='h5'>{report.name}</Typography>
                    <Typography style={typoStyles.clickOn}>{report.screenID === expanded ? 'Click to minimize' : 'Click to expand'}</Typography>
                    <Typography style={{ top: '10px' }}>{report.description}</Typography>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <Grid container justifyContent='flex-end'>
                    <Grid item sx={{ paddingRight: 0 }}>
                      <div>
                        <Tooltip title='Edit'>
                          <CustomEditButton variant='contained'
                            style={{ borderRadius: 5, marginLeft: 5 }}
                            sx={{ color: 'white' }}
                            onClick={(event) => {
                              gaLogEvent('Clicked on Tools Fama French Edit Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French Edit')
                              event.stopPropagation()
                              editScreeningReport(report.name, report.customIndex)
                            }}
                            aria-label='Edit'
                          >
                            <EditIcon />
                          </CustomEditButton>
                        </Tooltip>
                        <Tooltip title='Delete'>
                          <DeleteBtn variant='contained' style={{ borderRadius: 5, marginLeft: 5 }}
                            sx={{ color: 'white' }}
                            id={report.screenID}
                            onClick={(event) => {
                              gaLogEvent('Clicked on Tools Fama French Delete Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French Delete')
                              event.stopPropagation()
                              setDeleteID(report.screenID)
                              if (report.screenID && report.screenID > -1) {
                                setShowModal(true)
                              }
                            }}
                          >
                            <DeleteForeverIcon />
                          </DeleteBtn>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div style={{ ...styles.section, borderTop: 'none', borderTopLeftRadius: '0px', borderTopRightRadius: '0px', ...(expanded === report.screenID ? {} : { display: 'none' }) }}>
              <FamaFrenchCard userInfo={userInfo} famaScreenID={report.screenID} expansionState={expanded} screeningName={report.name} customIndexID={report.customIndex} setCustomIndexID={updateReportCustomIndex(report)} signOut={signOut} setErrorResponse={setErrorResponse} checkIsTrial={checkIsTrial} />
            </div>
          </div>
        )
      })
    }
    return reportsArray
  }

  useEffect(() => {
    if (toDelete) {
      deleteScreeningReport()
    }
  }, [toDelete])

  useEffect(() => {
    if (!accessibilityProps.canUseTool) return

    if (!isAddOrEditScreen) {
      setScreeningName('')
      setCustomIndexID(null)
    }

    if (expandedScreenID && !(isAddOrEditScreen || isEdit)) {
      setExpanded(expandedScreenID as string)
    }

    refreshScreenReportList(true, true)
  }, [accessibilityProps.canUseTool, isAddOrEditScreen])

  useEffect(() => {
    refreshScreenReportList(false, false)
  }, [pageNumber])

  useEffect(() => {
    if (location?.state?.prevPage === paths.graph.lineGraph) {
      return
    }

    if (expanded !== false) {
      navigate(paths.financialTools.base + pathParts.tools.famaFrench + `/${expanded}`)
    } else {
      navigate(paths.financialTools.base + pathParts.tools.famaFrench)
    }
  }, [expanded])

  useEffect(() => {
    generateReports()
  }, [reportData])

  useEffect(() => {
    if (screeningName.length > 0) {
      openEditScreen()
    }
  }, [screeningName])

  useEffect(() => {
    if (toDelete) {
      deleteScreeningReport()
    }
  }, [toDelete])

  useEffect(() => {
    if (isCancel) {
      setShowResetModal(true)
    }
  }, [isCancel])

  const famaStateProps: FamaStateProps = {
    setIsAddOrEditScreen: setIsAddOrEditScreen,
    screeningName: screeningName,
    customIndexID: customIndexID,
    isEdit: isEdit,
    cancelModalProps: cancelModalProps,
    showResetModal: showResetModal,
    setShowResetModal: setShowResetModal,
    isCancel: isCancel,
    setIsCancel: setIsCancel
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  const renderAddEditScreen = () => {
    return !isAddOrEditScreen ?
      <div style={{ marginTop: '20px' }}>
        <Tooltip title='Add New Screening'>
          <CustomBtnAddEdit variant='contained'
            style={{ borderRadius: 5, marginTop: '10px', marginBottom: '5px' }}
            onClick={() =>{
              gaLogEvent('Clicked on Tools Add New Screening Button', gaFinaeonWebAppEventCategories.Tools, 'Add New Screening')
              setIsAddOrEditScreen(!isAddOrEditScreen)
            }}
            aria-label='Add New Screening'
          >
            <AddNewScreening style={{ fontSize: '2em' }}></AddNewScreening>
          </CustomBtnAddEdit>
        </Tooltip>
        <Stack spacing={2} direction={'row'}>
          <Pagination count={totalCount && perPageCount ? Math.ceil(totalCount/perPageCount) : 0} page={pageNumber} 
            onChange={handlePageChange} variant="outlined" shape="rounded"
            showFirstButton
            showLastButton
            sx={{ paddingTop: 2, paddingBottom: 2, marginLeft: -0.5, display: 'inline' }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward, first: KeyboardDoubleArrowLeft, last: KeyboardDoubleArrowRight }}
                {...item}
              />
            )}
          />
          {screenReportListRequestInProgress && <IconButton aria-label='Loading Screen Report List...' component='label'>
            <CircularProgress style={globalStyles.circularProgress} title='Loading reports...' aria-label='Loading Screen Report List...' />
          </IconButton>}
        </Stack>
        {generateReports()}
      </div>
      :
      <div>
        <Tooltip title='Cancel Screening'>
          <DeleteBtn variant='contained' style={{ borderRadius: 5, marginTop: '10px', marginBottom: 5 }}
            onClick={() => {
              gaLogEvent('Clicked on Tools Cancel Screening Button', gaFinaeonWebAppEventCategories.Tools, 'Cancel Screening')
              setIsCancel(true)
            }}
          >
            <CancelScreening style={{ fontSize: '2em' }}></CancelScreening>
          </DeleteBtn>
        </Tooltip>
        <FamaFrenchStartScreen userInfo={userInfo} famaStateProps={famaStateProps} signOut={signOut} setErrorResponse={setErrorResponse} checkIsTrial={checkIsTrial} />
      </div>
  }

  const renderBody = () => {
    if (accessibilityProps.loadRequestInProgress) {
      return <IconButton aria-label='Loading reports' component='label'>
        <CircularProgress style={globalStyles.circularProgress} title='Loading reports...' aria-label='Loading reports...' />
      </IconButton>
    } else if (accessibilityProps.canUseTool) {
      return renderAddEditScreen()
    } else if (accessibilityProps.canUseTool === null) {
      return <Alert
        style={{ padding: '0px 0px 0px 8px', marginTop: '16px' }}
        severity='error'
      >
        Access check failed.
      </Alert>
    } else {
      return <Alert
        style={{ padding: '0px 0px 0px 8px', marginTop: '16px' }}
        severity='error'
      >
        Sorry, the Fama French Tool is only available to US and UK stock subscribers.
      </Alert>
    }
  }

  const readOnlyProp: CSSProperties = (screenReportListRequestInProgress ?? false) ? { pointerEvents: 'none'} : {}
  
  return (
    <div style={{ ...readOnlyProp }}>
      <ErrorHandler {...errorHandlerProps} />
      <Grid container spacing={0} columns={3}>
        <Grid item sm={2} md={2} lg={1.25} flexGrow={1}>
          <div style={headerStyles.headerWrapper}>
            <FamaFrenchSVG style={headerStyles.customIcon}></FamaFrenchSVG>
            <h3 style={{ fontWeight: 'bold' }}>Fama French Screening Reports</h3>
          </div>
        </Grid>
      </Grid>
      {showModal ? <FamaFrenchDeleteModal modalProps={modalProps} /> : <></>}

      {renderBody()}
    </div>
  )
}

const styles = {
  section: {
    borderStyle: 'solid', 
    borderWidth: '1px', 
    borderColor: 'lightgray', 
    borderRadius: '4px', 
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    paddingRight: '16px'
  }
}

const headerStyles = {
  customIcon: {
    width: '30px',
    height: '30px',
    marginRight: '10px'
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    background: 'linear-gradient(90deg, #c69adc, #e7c3fa)',
    padding: '12px 20px 8px 20px',
    borderRadius: '40px'
  },
  reportWrapper: {
    paddingTop: '20px'
  },
  iconWrapper: {
    marginTop: '30px'
  }
}

const typoStyles = {
  clickOn: {
    fontSize: 14,
    fontStyle: 'italic',
    marginBottom: '0rem',
    marginTop: '0px'
  }
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowExpand style={{ fontSize: '1.65rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(232, 245, 253, 0.3)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': { position: 'relative', top: theme.spacing(1.5) },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default FamaFrench
