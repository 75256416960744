import { Button, Grid, InputAdornment, MenuItem, styled, TextField, TextFieldProps, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { AppConfigurations, regExFor9DigitsPlus2DecimalPlaces } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { CustomBtnBlue, CustomBtnCloseCancel, AddBtn, RemoveBtn, BtnEnabledDisabled } from '../GlobalSettings/CustomStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'



const TextInput = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}))

const FamaEditModal = ({ modalProps }: any) => {

  const calculateDate = (isStart: boolean): moment.Moment => {
    if (moment(modalProps.modalData.rank.pctChgStart).year() === 1 && moment(modalProps.modalData.rank.pctChgEnd).year() === 1) {
      return isStart ? moment('01-01-1950', 'MM-DD-YYYY') : moment()
    }

    if (moment(modalProps.modalData.rank.pctChgStart).year() > 1 && moment(modalProps.modalData.rank.pctChgEnd).year() > 1) {
      return isStart ? moment(modalProps.modalData.rank.pctChgStart) : moment(modalProps.modalData.rank.pctChgEnd)
    }

    if (isStart) {
      return moment('01-01-1950', 'MM-DD-YYYY')
    }

    return moment()
  }

  const errorMessages = {
    startGreaterThanEnd: 'Start Date cannot be greater than End Date',
    EndlessThanStart: 'End Date cannot be less than Start Date'
  }

  const [showRank, setShowRank] = useState<any>(modalProps.modalData.rank.rankValue > 0 ? true : false)
  const [showSort, setShowSort] = useState<any>(modalProps.modalData.sort.order === 'None' ? false : true)
  const [showRankRange, setShowRankRange] = useState<any>((modalProps.modalData.rank.usePctChg && modalProps.modalData.rank.rankValue > 0) ? true : false)

  const [rank, setRank] = useState<any>((modalProps.modalData.rank.type === 'None') ? 'top' : modalProps.modalData.rank.type.toLowerCase())
  const [rankValue, setRankValue] = useState<any>(modalProps.modalData.rank.rankValue ? modalProps.modalData.rank.rankValue : 25)
  const [rankType, setRankType] = useState<any>(modalProps.modalData.rank.rankBy ? modalProps.modalData.rank.rankBy.toLowerCase() : 'percent')

  const [sortType, setSortType] = useState<any>(modalProps.modalData.sort.level === 2 ? modalProps.modalData.sort.level : 1)
  const [sortOrder, setSortOrder] = useState<any>((modalProps.modalData.sort.order && modalProps.modalData.sort.order !== 'None') ? modalProps.modalData.sort.order.toLowerCase() : 'ascending')
  const [rankRangeStart, setRankRangeStart] = useState<moment.Moment>(calculateDate(true))
  const [rankRangeEnd, setRankRangeEnd] = useState<moment.Moment>(calculateDate(false))
  const [modalMinValue, setModalMinValue] = useState<string>(modalProps.modalData.criteria.min ?? undefined)
  const [modalMaxValue, setModalMaxValue] = useState<string>(modalProps.modalData.criteria.max ?? undefined)

  const [rankInputError, setRankInputError] = useState<boolean | undefined>(false)
  const [criteriaHelperText, setCriteriaHelperText] = useState<string>('(Start Value)')
  const [criteriaHasError, setCriteriaHasError] = useState<boolean>(false)

  const [helpTextStartYear, setHelpTextStartYear] = useState<string>('')
  const [helpTextEndYear, setHelpTextEndYear] = useState<string>('')
  const [dateHasError, setDateHasError] = useState<boolean | undefined>(false)

  const [inputError, setInputError] = useState<boolean | undefined>(false)
  const minimumDate: moment.Moment = moment(AppConfigurations.minimumDate)
  const maximumDate: moment.Moment = moment()

  const isRankApply = (): boolean => {
    if (showRank && rankValue && !isNaN(rankValue) && rankValue > 0) {
      return true
    }
    return false
  }

  const handleClose = () => modalProps.handleClose(false)

  const handleSuccess = () => {
    gaLogEvent('Clicked on Tools Fama French Update Screening Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French handleSuccess')
    const isRankApplicable: boolean = isRankApply()

    let min: number | undefined = parseFloat(modalMinValue)
    if (isNaN(min)) min = undefined
    let max: number | undefined = parseFloat(modalMaxValue)
    if (isNaN(max)) max = undefined

    const modalReturn = {
      modalMinValue: min,
      modalMaxValue: max,
      rank: isRankApplicable ? rank : null,
      rankValue: isRankApplicable ? rankValue : null,
      rankType: isRankApplicable ? rankType : null,
      rankRangeStart: showRankRange ? rankRangeStart : null,
      rankRangeEnd: showRankRange ? rankRangeEnd : null,
      usePctChg: showRankRange ? true : false,
      sortType: showSort ? sortType : null,
      sortOrder: showSort ? sortOrder : null,
      description: modalProps.modalDescription,
      entityID: modalProps.modalData.criteria.entityID
    }
    modalProps.handleSuccess({ ...modalReturn })
  }

  const isStartYearValid = (): boolean => {
    if (!rankRangeStart) {
      return false
    }
    if (!rankRangeStart.isValid()) {
      return false
    }
    if (!rankRangeEnd || !rankRangeEnd.isValid()) {
      return true
    }
    if (rankRangeStart > rankRangeEnd) {
      setHelpTextStartYear(errorMessages.startGreaterThanEnd)
      return false
    }
    return true
  }

  const isEndYearValid = (): boolean => {
    if (!rankRangeEnd) {
      return false
    }
    if (!rankRangeEnd.isValid()) {
      return false
    }
    if (!rankRangeStart || !rankRangeStart.isValid()) {
      return true
    }
    if (rankRangeEnd < rankRangeStart) {
      setHelpTextEndYear(errorMessages.EndlessThanStart)
      return false
    }
    return true
  }

  const validateYearInputs = (): boolean => {
    resetErrorStates()
    if (isStartYearValid() && isEndYearValid()) {
      setDateHasError(false)
      return true
    }

    setDateHasError(true)
    return false
  }

  const validateCriteria = (startValue: string, endValue: string): boolean => {
    let min: number | undefined = parseFloat(startValue)
    if (isNaN(min)) min = undefined
    let max: number | undefined = parseFloat(endValue)
    if (isNaN(max)) max = undefined

    let result: boolean = false
    if ((!min && !max && min !== 0 && max !== 0)) {
      setCriteriaHelperText('Criteria must have a value, either start or end or both')
      setCriteriaHasError(true)
      result = true
    }
    if ((min || min === 0) && (max || max === 0)) {
      if (min > max) {
        setCriteriaHelperText('Min value cannot be greater than max value')
        setCriteriaHasError(true)
        result = true
      }
    }
    if (result === false) {
      setCriteriaHelperText('(Start Value)')
      setCriteriaHasError(false)
    }

    return result
  }

  const resetErrorStates = () => {
    setHelpTextStartYear('')
    setHelpTextEndYear('')
    setCriteriaHelperText('')
  }

  const adornmentLogic = () => {
    const entityID: number = modalProps.modalData.criteria.entityID
    if (entityID === 1778) {
      return {
        inputProps: { 'title': 'Enter Market Cap Value (in Millions)', 'aria-label': 'Enter Market Cap Value (in Millions)' },
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }
    } else if (entityID === 1786) {
      return {
        inputProps: { 'title': 'Enter PE Ratio', 'aria-label': 'Enter PE Ratio' },
        endAdornment: <InputAdornment position='end'>%</InputAdornment>,
      }
    }
    return {
      inputProps: { 'title': 'Enter End Value', 'aria-label': 'Enter End Value' },
    }
  }

  useEffect(() => {
    if (showRank) {
      isRankApply() ? setRankInputError(false) : setRankInputError(true)
    } else {
      setRankInputError(false)
    }
  }, [showRank, rankValue])

  useEffect(() => {
    if (!showRankRange) {
      setDateHasError(false)
      return
    }

    if (validateYearInputs()) {
      resetErrorStates()
    }
  }, [showRankRange, rankRangeStart, rankRangeEnd])

  useEffect(() => {
    validateCriteria(modalMinValue, modalMaxValue)
  }, [modalMinValue, modalMaxValue])


  useEffect(() => {
    if (rankInputError || dateHasError || criteriaHasError) {
      setInputError(true)
    } else {
      setInputError(false)
    }
  }, [rankInputError, dateHasError, criteriaHasError])

  return (
    <>
      <Modal size='lg' show={modalProps.showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalProps.modalDescription}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <Grid container columns={16} sx={{ paddingBottom: 0 }}>
            <Grid item md={4}>
              {showRank ?
                <RemoveBtn
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    setShowRank(!showRank)
                    setRankValue(null)
                    setRank('top')
                    setRankType('percent')
                  }}>
                  Remove Ranking
                </RemoveBtn>

                : <AddBtn
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    setShowRank(!showRank)
                  }}>
                  Add Ranking
                </AddBtn>}
            </Grid>
            <Grid item md={8}>
              {showSort ?
                <RemoveBtn
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    setShowSort(!showSort)
                    setSortOrder('ascending')
                    setSortType(1)
                  }}>
                  Remove Sort
                </RemoveBtn>

                : <AddBtn
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  onClick={() => {
                    setShowSort(!showSort)
                  }}>
                  Add Sort
                </AddBtn>}
            </Grid>
          </Grid>
          <>
            <h4 style={{ paddingBottom: 5, textDecoration: 'underline' }}>Filter:</h4>
            <Grid container columns={16}>
              <Grid item md={8}>
                <TextField
                  id='editMinValue'
                  label={modalProps.modalData.criteria.description}
                  variant='outlined'
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                  }}
                  helperText={criteriaHelperText}
                  value={modalMinValue || ''}
                  onChange={(e: any) => {
                    if (regExFor9DigitsPlus2DecimalPlaces.test(e.target.value)) {
                      setModalMinValue(e.target.value)
                    }
                  }}
                  InputProps={adornmentLogic()}
                  error={criteriaHasError}
                />
              </Grid>
              <Grid item md={8}>
                <TextField
                  id='editMaxValue'
                  variant='outlined'
                  size='small'
                  label={modalMaxValue ? '' : 'No value provided'}
                  helperText='(End Value)'
                  value={modalMaxValue || ''}
                  onChange={(e: any) => {
                    if (regExFor9DigitsPlus2DecimalPlaces.test(e.target.value)) {
                      setModalMaxValue(e.target.value)
                    }
                  }}
                  InputProps={adornmentLogic()}
                />
              </Grid>
            </Grid>
          </>
          {showRank ?
            <>
              <hr style={{ marginBottom: 0 }} />
              <Grid container columns={6}>
                <Grid item md={1}>
                  <h4 style={{ textDecoration: 'underline' }}>Ranking:</h4>
                </Grid>
                <Grid item md={3}>
                  <Tooltip title='Narrow your search using a ranking with the option of narrowing it further based on a date range.'>
                    <HelpOutlineIcon />
                  </Tooltip>
                </Grid>
              </Grid>


              <Grid container columns={15}>
                <Grid item md={3}>
                  <TextField
                    id='editRanking'
                    value={rank}
                    onChange={(e) => setRank(e.target.value)}
                    select
                    label='Rank'
                    sx={{ minWidth: 120 }}
                    size={'small'}
                  >
                    <MenuItem value={'top'}>Top</MenuItem>
                    <MenuItem value={'bottom'}>Bottom</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={5}>
                  <TextInput
                    id='editRankValue'
                    variant='outlined'
                    size='small'
                    type={'number'}
                    label={rankValue ? '' : 'No value provided'}
                    helperText='(rank value)'
                    value={rankValue}
                    InputProps={rankType !== 'percent' ? { inputProps: { 'title': 'Rank Value', 'aria-label': 'Rank Value' } } : {
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                      inputProps: { 'title': 'Rank Percent', 'aria-label': 'Rank Percent' },
                    }}
                    onInput={rankType !== 'percent' ? (e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 6) } : (e) => { (e.target as HTMLInputElement).value = Math.max(0, parseInt((e.target as HTMLInputElement).value)).toString().slice(0, 3) }}
                    onChange={(e: any) => {
                      e.preventDefault()
                      let newValue = e.target.value
                      if (rankType === 'percent') {
                        if (newValue > 100) {
                          newValue = 100
                        }
                      }
                      setRankValue(newValue)
                    }}
                    error={rankInputError}
                  />
                </Grid>
                <Grid item md={5}>
                  <TextField
                    id='editRankType'
                    value={rankType}
                    onChange={(e) => {
                      const newType = e.target.value
                      if (newType === 'percent') {
                        if (rankValue > 100) {
                          setRankValue(100)
                        }
                      }
                      setRankType(e.target.value)
                    }}
                    select
                    label='Type'
                    sx={{ minWidth: 200 }}
                    size={'small'}
                  >
                    <MenuItem value={'percent'}>By Percent</MenuItem>
                    <MenuItem value={'value'}>By Value</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              {showRankRange ? <RemoveBtn
                id='removeDateRange'
                sx={{ marginTop: 1, marginBottom: 1 }}
                onClick={() => {
                  setShowRankRange(!showRankRange)
                }}>
                Remove Date Range
              </RemoveBtn>

                : <AddBtn
                  sx={{ marginTop: 1, marginBottom: 1 }}
                  id='addDateRange'
                  onClick={() => {
                    setShowRankRange(!showRankRange)
                  }}>
                  Add Date Range
                </AddBtn>}
              {showRankRange ?
                <>
                  <p style={{ fontStyle: 'italic', textDecoration: 'underline' }}>Apply Rank Over a Given Time Range (% change over time)</p>
                  <Grid container columns={16}>
                    <Grid item md={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label='Start Date'
                          value={rankRangeStart}
                          onChange={(newValue) => {
                            setRankRangeStart(newValue as moment.Moment)
                          }}
                          minDate={minimumDate}
                          maxDate={maximumDate}
                          sx={{ fontWeight: 'bold' }}
                          slotProps={{
                            textField: {
                              id: 'startYear',
                              variant: 'outlined',
                              size: 'small',
                              InputLabelProps: {
                                shrink: true
                              },
                              helperText: helpTextStartYear,
                              error: dateHasError
                            }
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label='End Date'
                          value={rankRangeEnd}
                          onChange={(newValue) => {
                            setRankRangeEnd(newValue as moment.Moment)
                          }}
                          minDate={minimumDate}
                          maxDate={maximumDate}
                          sx={{ fontWeight: 'bold' }}
                          slotProps={{
                            textField: {
                              id: 'endYear',
                              variant: 'outlined',
                              size: 'small',
                              InputLabelProps: {
                                shrink: true
                              },
                              helperText: helpTextEndYear,
                              error: dateHasError
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </> : <></>}
            </> : <></>}
          {showSort ?
            <>
              <hr style={{ marginBottom: 0 }} />
              <Grid container columns={6}>
                <Grid item md={1}>
                  <h4 style={{ textDecoration: 'underline' }}>Sort:</h4>
                </Grid>
                <Grid item md={3}>
                  <Tooltip title='Sort by primary factor or secondary factors, ascending or descending.'>
                    <HelpOutlineIcon />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container columns={16}>
                <Grid item md={5}>
                  <TextField
                    id='editSortType'
                    value={sortType}
                    onChange={(e) => setSortType(e.target.value)}
                    select
                    label='Sort Type'
                    sx={{ minWidth: 200 }}
                    size={'small'}
                  >
                    <MenuItem value={1}>Primary</MenuItem>
                    <MenuItem value={2}>Secondary</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={8}>
                  <TextField
                    id='editSortOrder'
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    select
                    label='Sort Order'
                    sx={{ minWidth: 200 }}
                    size={'small'}
                  >
                    <MenuItem value={'ascending'}>Ascending</MenuItem>
                    <MenuItem value={'descending'}>Descending</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </> : <></>}
        </Modal.Body>
        <Modal.Footer>
          <CustomBtnCloseCancel id='closeModalButton' variant='outlined' onClick={handleClose}>
            Cancel
          </CustomBtnCloseCancel>
          <BtnEnabledDisabled id='updateScreeningButton' variant='contained' onClick={handleSuccess} disabled={inputError}>
            Update Screening
          </BtnEnabledDisabled>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default FamaEditModal