import MaterialTable from '@material-table/core'
import { IconButton, Tooltip } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { addTickersToWorkbookByID } from '../../../Services/AutoTracService'
import WorkbookSelectionModal, { WorkbookSelectionModalProps } from '../../Common/Modals/WorkbookSelectionModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { pathParts, paths } from '../../../Models/DataModels/Common/RedirectionModel'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddtoWorkbook from '../../Icons/AddtoWorkbookIcon'
import DownLoad from '../../Icons/DownLoad'
import GraphwLines from '../../Icons/GraphwLines'
import AddSelectedSeries from '../../Icons/AddSelectedSeriesIcon'
import DeleteSelected from '../../Icons/DeleteSelectedIcon'
import InfoSvg from '../../Icons/InfoSvgIcon'
import { AppConfigurations } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { TickerData } from '../../../Models/DataModels/Responses/AutoTracResponses'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import HomeSearchClearSavedModal, { HomeSearchClearSavedModalProps } from '../../Common/Modals/HomeSearchClearSavedModal'
import SelectToolbarLineGraphModal from '../../Common/Modals/SelectToolbarLineGraphModal'
import SelectedSearchResultExport, { SelectedSearchResultExportProps } from './SelectedSearchResultExport'
import { getSavedResultsFromStorageAsArray, localStorageKeys, setLocalStorage } from '../../../Services/LocalStorageService'
import TickerInfoModal, { TickerInfoModalProps } from '../../Common/Modals/TickerInfoModal'
import BarGraph from '../../Icons/BarGraph'
import { sessionStorageKeys } from '../../../Services/SessionStorageService'
import PieGraph from '../../Icons/PieGraphIcon'
import SelectToolbarSelectedResultsChartSetDateModal, { SelectToolbarSelectedResultsChartSetDateModalProps } from '../../Common/Modals/SelectToolbarSelectedResultsChartSetDateModal'
import { ChartType, ChartTypes } from '../../../Models/DataModels/Common/ToolsModel'
import { SearchDatabaseType } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { validateChartCount } from '../Charts/SearchResultsChartUtil'
import AddTickersToCustomIndex, { AddTickersToCustomIndexProps } from '../../Common/Utility/AddTickersToCustomIndex'
import DownloadAction, { DownloadActionProps } from '../../Common/Utility/DownloadAction'
import AlertTickerLimitModal, { AlertTickerLimitModalProps } from '../../Common/Modals/AlertTickerLimitModal'
import { CustomBtnBlue } from '../../Common/GlobalSettings/CustomStyles'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import { SortOrderStringType, getComparator, sort } from '../../../Models/DataModels/Common/SortModel'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import ExportOutputFormatSelectionModal from '../../Common/Modals/ExportOutputFormatSelectionModal'
import { DateFormatValue, DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'
import DownLoadGreen from '../../Icons/DownLoadGreen'
import InfoGraySvgIcon from '../../Icons/InfoGraySvgIcon'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export interface SavedResultsListProps {
  userInfo: UserInfo | null,
  engineOption: string,
  checkIsTrial: () => boolean,
  signOut: (logoutReason: LogoutReasonType) => void,
  setShowSaved: (showSelected: boolean) => void,
  isComposite: boolean
}

const SavedResultsList = ({
  userInfo,
  engineOption,
  checkIsTrial,
  signOut,
  setShowSaved,
  isComposite
}: SavedResultsListProps) => {

  const location: any = useLocation()
  const navigate = useNavigate()
  if (checkIsTrial()) navigate(pathParts.search.searchDefault)

  const getSavedResults = () => {
    return getSavedResultsFromStorageAsArray().reduce((accum: any[], entry: any) => {
      entry.tableData = undefined
      accum.push(entry)
      return accum
    }, [])
  }

  const [savedSearchData, setSavedSearchData] = useState<any[]>(getSavedResults())
  const [showWorkbookSelectionModal, setShowWorkbookSelectionModal] = useState<boolean>(false)
  const [selectedWorkbook, setSelectedWorkbook] = useState<string>('')
  const [selectedTickerIDs, setSelectedTickerIDs] = useState<number[]>([])
  const [doAddToWorkbook, setDoAddToWorkbook] = useState<boolean>(false)
  const [tickerLimitAlertMessage, setTickerLimitAlertMessage] = useState(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. Please try with ${AppConfigurations.maximumTickers} series or less.`)
  const [showTickerLimitModal, setShowTickerLimitModal] = useState<boolean>(false)
  const [seriesIDForInfo, setSeriesIDForInfo] = useState<any>()
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [clearMessagesToggle, setClearMessagesToggle] = useState(false)
  const [showClearSavedModal, setShowClearSavedModal] = useState<boolean>(false)
  const [clearSaved, setClearSaved] = useState<boolean>(false)
  const [deleteData, setDeleteData] = useState<any>()
  const [showLineGraphModal, setShowLineGraphModal] = useState<boolean>(false)
  const [chartType, setChartType] = useState<ChartType>(ChartTypes.Pie)
  const [showSelectedResultsChartSetDateModal, setShowSelectedResultsChartSetDateModal] = useState<boolean>(false)
  const [customIndexData, setCustomIndexData] = useState<any[]>([])
  const [showCustomIndexSelectionModal, setShowCustomIndexSelectionModal] = useState<boolean>(false)

  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

  const [pageNumber, setPageNumber] = useState(1)
  const [perPageCount, setPerPageCount] = useState(100)
  const [totalCount, setTotalCount] = useState<number>(savedSearchData?.length || 0)

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
    () =>
      sort(savedSearchData, getComparator(sortOrder, sortColumn))
        .slice(pageNumber > 0 ? (pageNumber - 1) * perPageCount : 0, pageNumber > 0 ? pageNumber * perPageCount : 0),
    [sortOrder, sortColumn, savedSearchData, pageNumber, perPageCount],
  )

  useEffect(() => {
    const count = savedSearchData?.length || 0
    setTotalCount(count)
    count > 0 ? setPageNumber(1) : setPageNumber(0)
  }, [savedSearchData])

  useEffect(() => {
    setPageNumber(1)
  }, [perPageCount])

  const gfdTablePaginationProps: GFDTablePaginationProps = {
    perPageCount: perPageCount,
    pageNumber: pageNumber,
    totalCount: totalCount || 0,
    setPageNumber: setPageNumber,
    setPerPageCount: setPerPageCount
  }

  const processAddToCustomIndex = (data: any[]) => {
    gaLogEvent('Clicked on Saved Results Custom Index Button', gaFinaeonWebAppEventCategories.Search, 'processAddToCustomIndex')
    setCustomIndexData(data)
    setShowCustomIndexSelectionModal(true)
  }

  const messageToggleProps = {
    clearMessagesToggle,
    clearMessages: () => {
      setClearMessagesToggle(!clearMessagesToggle)
    }
  }

  const addTickersToCustomIndexProps: AddTickersToCustomIndexProps = {
    showCustomIndexSelectionModal,
    setShowCustomIndexSelectionModal,
    customIndexData,
    signOut,
    ...messageToggleProps
  }

  const infoModalProps: TickerInfoModalProps = {
    seriesID: seriesIDForInfo,
    clearSeriesID: () => { setSeriesIDForInfo(null) },
    signOut
  }

  const deleteSelectedResults = (data: any) => {
    if (!data || !data?.length) return

    const selectedValues = Object.values(data).map((d: any) => d?.id)

    let storedArray: any[] = getSavedResults()

    storedArray = storedArray.filter((storedValue: any) => {
      return selectedValues.indexOf(storedValue.id) === -1
    })

    setLocalStorage(localStorageKeys.selectedResultsData, JSON.stringify(storedArray))
    setSavedSearchData(storedArray)
  }

  const handleClearSelected = (data: any) => {
    gaLogEvent('Clicked on Saved Results Clear Button', gaFinaeonWebAppEventCategories.Search, 'handleClearSelected')
    setDeleteData(data)
    setShowClearSavedModal(true)
  }

  const handleBackButton = () => {
    gaLogEvent('Clicked on Saved Results Button', gaFinaeonWebAppEventCategories.Search, 'handleBackButton')
    if (location?.state?.engineOption) {
      navigate(pathParts.search.searchSingle + location.state.engineOption)
    } else {
      navigate(pathParts.search.searchSingle + engineOption)
    }
    setShowSaved(false)
  }

  const viewChart = (primaryTicker: string) => {
    navigate(`${paths.graph.lineGraph}/${primaryTicker}`, { state: { prevPage: paths.searchHome.selectedResults, engineOption: engineOption } })
  }

  const handleViewPieChart = (data: any) => {
    gaLogEvent('Clicked on Saved Results Pie Chart Button', gaFinaeonWebAppEventCategories.Search, 'handleViewPieChart')
    if (!validateChartCount(data)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...data.values()]))
    setChartType(ChartTypes.Pie)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const handleViewBarChart = (data: any) => {
    gaLogEvent('Clicked on Saved Results Bar Chart Button', gaFinaeonWebAppEventCategories.Search, 'handleViewBarChart')
    if (!validateChartCount(data)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...data.values()]))
    setChartType(ChartTypes.Bar)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const [savedSearchColumns] = useState<any>([
    { "title": "id", "field": "id", type: 'numeric', "hidden": true },
    { "title": "Symbol", "field": "symbol", "hidden": false, width: '6%' },
    { title: 'Description', field: 'description', width: '38%', type: 'string' },
    { "title": "Country", "field": "country", width: '10%', "hidden": false },
    { "title": "Series Type", "field": "series", width: '16%', "hidden": false },
    { "title": "Start", "field": "startDate", width: '8%', type: 'date', "hidden": false },
    { "title": "End", "field": "endDate", width: '8%', type: 'date', "hidden": false },
    {
      title: "Quick Actions",
      sorting: false,
      field: "button",
      // editable: false,
      render: (rowData: any) =>
        rowData && (
          <>
            <IconButton
              title='Info'
              sx={{ color: 'lightblue', }}
              onClick={() => {
                gaLogEvent('Clicked on Saved Results Quick Actions Info Button', gaFinaeonWebAppEventCategories.Search, 'Quick Actions Saved Results')
                setSeriesIDForInfo(rowData?.id)
              }}
              size='small'
            >
              <InfoGraySvgIcon></InfoGraySvgIcon>
            </IconButton>

            <IconButton
              title='Graph'
              sx={{ color: 'blue' }}
              onClick={() => {
                gaLogEvent('Clicked on Saved Results Quick Actions Graph Button', gaFinaeonWebAppEventCategories.Search, 'Quick Actions Saved Results')
                viewChart(rowData.symbol as string)
              }}
              size='small'
            >
              <GraphwLines></GraphwLines>
            </IconButton>

            <IconButton
              title='Download'
              sx={{ color: 'lightcoral' }}
              onClick={() => {
                gaLogEvent('Clicked on Saved Results Quick Actions Download Button', gaFinaeonWebAppEventCategories.Search, 'Quick Actions Saved Results')
                processDownload([rowData])}
              }
              size='small'
            >
              <DownLoadGreen></DownLoadGreen>
            </IconButton>
          </>
        )
    }
  ])

  useEffect(() => {
    if (doAddToWorkbook) {
      executeAddToWorkbook()
      setDoAddToWorkbook(false)
    }
  }, [doAddToWorkbook])

  useEffect(() => {
    if (clearSaved) {
      deleteSelectedResults(deleteData)
      setClearSaved(false)
    }
  }, [clearSaved])

  const onFinalWorkbookSelection = (returnValue: boolean, workbook: string) => {
    setSelectedWorkbook(workbook)
    setDoAddToWorkbook(returnValue)
  }

  const executeAddToWorkbook = () => {
    if (!doAddToWorkbook
      || !selectedWorkbook
      || !selectedTickerIDs
      || !selectedTickerIDs?.length) return

    addTickersToWorkbookByID({ workbookName: selectedWorkbook, tickerIDs: selectedTickerIDs })
      .then((result: any) => {
        if (result?.messages?.length) {
          const notOKResponseModel: NotOKResponseModel = {
            isBackgroundCall: false,
            statusCode: 200,
            notOKResponse: {
              internal: false,
              success: true,
              logOut: false,
              messages: result?.messages
            }
          }
          setErrorResponse(notOKResponseModel)
        }
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
        })
  }

  const alertTickerLimitModalProps: AlertTickerLimitModalProps = {
    showTickerLimitModal,
    setShowTickerLimitModal,
    tickerLimitAlertMessage
  }

  const processAddToWorkbook = (data: any) => {
    gaLogEvent('Clicked on Saved Results Add To Workbook Button', gaFinaeonWebAppEventCategories.Search, 'processAddToWorkbook')
    if (!data || !data?.length) return

    const tickerIDs = Object.values(data).map((d: any) => d?.id)
    setSelectedTickerIDs(tickerIDs)

    if (tickerIDs.length === 0) return
    if (tickerIDs.length > AppConfigurations.maximumTickers) {
      setTickerLimitAlertMessage(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. ${tickerIDs.length} series have been selected. Please try with ${AppConfigurations.maximumTickers} or less.`)
      setShowTickerLimitModal(true)
      return
    }

    setShowWorkbookSelectionModal(true)
  }

  const processDownload = (data: any) => {
    if (!data || !data?.length) return

    const tickers: TickerData[] = Object.values(data)

    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      checkIsTrial,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  const clearSavedTickersModalProps: HomeSearchClearSavedModalProps = {
    all: false,
    showClearSavedModal,
    setShowClearSavedModal,
    setClearSaved
  }

  const workbookSelectionModalProps: WorkbookSelectionModalProps = {
    userInfo,
    onFinal: onFinalWorkbookSelection,
    showModal: showWorkbookSelectionModal,
    setShowModal: setShowWorkbookSelectionModal,
    signOut,
    ...messageToggleProps
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  const [lineGraphData, setLineGraphData] = useState<any[]>([])

  const selectToolbarLineGraphModalProps = {
    showLineGraphModal: showLineGraphModal,
    setShowLineGraphModal: setShowLineGraphModal,
    selectedResults: lineGraphData,
    engineOption: engineOption
  }

  const handleViewLineGraph = (data: any[]) => {
    gaLogEvent('Clicked on Saved Results Graph Button', gaFinaeonWebAppEventCategories.Search, 'handleViewLineGraph')
    if (data.length === 1) {
      navigate(`${paths.graph.lineGraph}/${data[0].symbol}`, { state: { prevPage: location.pathname, engineOption: engineOption } })
    } else if (data.length > 1) {
      setShowLineGraphModal(true)
      setLineGraphData(data)
    }
  }

  const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)
  const [exportDateFormat, setExportDateFormat] = useState<DateFormatValueType>(DateFormatValue.AmericanWithDashes)
  const [fireExportAction, setFireExportAction] = useState<boolean>(false)

  const dateFormatCommitted = (dateFormat: DateFormatValueType) => {
    setExportDateFormat(dateFormat)
    setFireExportAction(true)
  }

  const setSelectionAndFireExport = (data: any) => {
    gaLogEvent('Clicked on Saved Results Export Button', gaFinaeonWebAppEventCategories.Search, 'setSelectionAndFireExport')
    const tickerIDs = data && data?.length ? Object.values(data).map((d: any) => d?.id) : []
    setSelectedTickerIDs(tickerIDs)
    setShowExportOutputFormatModal(true)
  }

  const selectedSearchResultExportProps: SelectedSearchResultExportProps = {
    selectedResults: selectedTickerIDs,
    displayForActionOnly: true,
    fireAction: fireExportAction,
    resetFireAction: () => { setFireExportAction(false) },
    exportDateFormat: exportDateFormat,
    signOut
  }

  const selectToolbarSelectedResultsChartSetDateModalProps: SelectToolbarSelectedResultsChartSetDateModalProps = {
    chartType: chartType,
    engineOption: engineOption as SearchDatabaseType,
    show: showSelectedResultsChartSetDateModal,
    setShow: setShowSelectedResultsChartSetDateModal,
    lastPathFromCharts: paths.searchHome.selectedResults
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Tooltip title='Go Back'>
        <CustomBtnBlue id='graphSettings' variant='contained' style={{ marginRight: 5 }} onClick={handleBackButton}>
          <ArrowBackIcon />
        </CustomBtnBlue>
      </Tooltip>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <MaterialTable
          columns={savedSearchColumns}
          data={sortedData}
          title='Saved Results'
          options={{ ...MaterialTableOptions }}
          actions={[
            {
              tooltip: 'Delete Selected from Saved Results',
              icon: () => <DeleteSelected style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => handleClearSelected(data)
            },
            {
              tooltip: 'Graph Selected with Lines',
              icon: () => <GraphwLines style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => handleViewLineGraph(data)
            },
            {
              tooltip: 'Graph Selected with Pie Chart',
              icon: () => <PieGraph style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => handleViewPieChart(data)
            },
            {
              tooltip: 'Graph Selected with Bar Chart',
              icon: () => <BarGraph style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => handleViewBarChart(data)
            },
            {
              tooltip: 'Download Selected Series',
              icon: () => <DownLoadGreen style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => {
                gaLogEvent('Clicked on Saved Results Download Button', gaFinaeonWebAppEventCategories.Search, 'Saved Results')
                processDownload(data)
              }
            },
            {
              tooltip: 'Add to Workbook',
              icon: () => <AddtoWorkbook style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => processAddToWorkbook(data)
            },
            {
              tooltip: 'Export Selected',
              icon: () => <SelectedSearchResultExport {...selectedSearchResultExportProps} />,
              onClick: (evt, data) => setSelectionAndFireExport(data)
            },
            {
              tooltip: 'Add Selected Series to a Custom Index',
              icon: () => <AddSelectedSeries style={{ fontSize: '1.25em' }} />,
              onClick: (evt, data) => processAddToCustomIndex(data)
            }
          ]}
          components={{
            Pagination:
              props => (<GFDTablePagination {...gfdTablePaginationProps} />),
            Header:
              props => (
                <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns: savedSearchColumns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
              )
          }}
        />
      </ThemeProvider>
      <AddTickersToCustomIndex {...addTickersToCustomIndexProps} />
      <WorkbookSelectionModal {...workbookSelectionModalProps} />
      {renderDownloadAction()}
      {showClearSavedModal ? <HomeSearchClearSavedModal {...clearSavedTickersModalProps} /> : <></>}
      <TickerInfoModal {...infoModalProps} />
      {showLineGraphModal ? <SelectToolbarLineGraphModal modalProps={selectToolbarLineGraphModalProps} /> : <></>}
      {showSelectedResultsChartSetDateModal && <SelectToolbarSelectedResultsChartSetDateModal {...selectToolbarSelectedResultsChartSetDateModalProps} />}
      <AlertTickerLimitModal {...alertTickerLimitModalProps} />
      <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={dateFormatCommitted} />
      <ErrorHandler {...errorHandlerProps} />
    </div>
  )
}

export default SavedResultsList
