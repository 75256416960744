import React, { ReactElement, useEffect, useState } from 'react'
import { ChartDefinition, ChartMetaData, ChartView } from '../../../../../Models/DataModels/Common/ToolsModel'
import { CartesianGrid, Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { getNextChartDarkColor } from '../../../../../Services/ColorService'
import { CreateCurrencyConversionChartDownloadDataRequest, CurrencyConversionChartRequest, FileDownloadRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import moment from 'moment'
import { createCurrencyConversionChartDownloadData, getCurrencyConversionChart } from '../../../../../Services/ToolsService'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { FormControlLabel, FormGroup, Grid, Paper } from '@mui/material'
import { graphCompanyLogoSVG, rechartsSourceTextLabelObject } from '../../../../Common/Modals/GraphUtil'
import OutputFormatValue, { OutputFormatValueType } from '../../../../../Models/DataModels/Common/OutputFormatModel'
import GraphDownloadOptions, { GraphDownloadOptionsProps } from '../../../../Common/Modals/GraphDownloadOptions/GraphDownloadOptions'
import { CreateCurrencyConversionChartDownloadDataResponse } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import { downloadFileFromServer } from '../../../../../Services/DownloadService'
import { Payload } from 'recharts/types/component/DefaultLegendContent'
import Zoom from '../../../../Icons/ZoomIcon'
import { addUnixTimeStampProperty, unixTimestampDateFormatter, unixTimestampDateFormatterYYYY } from '../../../../GraphAndChartResults/GraphSubcomponents/GraphAndChartUtil'
import { BtnZoom, CheckboxBlue } from '../../../../Common/GlobalSettings/CustomStyles'
import { chartSizes } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { DateFormatValue, DateFormatValueType } from '../../../../../Models/DataModels/Common/DateFormatModel'
import LoadingScreen from '../../../../Common/Modals/LoadingScreen'
import { globalStyles } from '../../../../Common/GlobalSettings/GlobalStyles'
import { formatNumberEnUSLocalString } from '../../../../../Services/UtilityService'

export interface CCChartProps {
    chartDef: ChartDefinition,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void
}

const CCChart = ({
    chartDef,
    setErrorResponse
}: CCChartProps) => {

    const [chartData, setChartData] = useState<ChartView[]>(chartDef?.charts || [])
    const [refAreaLeft, setRefAreaLeft] = useState<any>('')
    const [refAreaRight, setRefAreaRight] = useState<any>('')
    const [zoomedRequest, setZoomedRequest] = useState<CurrencyConversionChartRequest | null>(null)
    const [zoomOutButtonEnabled, setZoomOutButtonEnabled] = useState<boolean>(false)
    const [hiddenCharts, setHiddenCharts] = useState<string[]>([])
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false)
    const [showYearOnly, setShowYearOnly] = useState<boolean>(true)

    const ccRequest: CurrencyConversionChartRequest | null = chartDef?.currencyChartRequest || null

    useEffect(() => {
        setChartData(chartDef?.charts || [])
    }, [chartDef])

    useEffect(() => {
        if (zoomedRequest) {
            setZoomOutButtonEnabled(true)
        } else {
            setZoomOutButtonEnabled(false)
        }
    }, [zoomedRequest])

    const handleLegendClick = (data: Payload) => {
        if (data?.value) {
            if (hiddenCharts.includes(data.value)) {
                const remainingHiddenCharts: string[] = hiddenCharts.filter((hiddenChartName: string) => hiddenChartName !== data.value)
                setHiddenCharts(remainingHiddenCharts)
            } else {
                setHiddenCharts([...hiddenCharts, data.value])
            }
        }
    }

    const isChartDataValid = (): boolean => {
        if (!chartData || chartData.length === 0) {
            return false
        }
        return true
    }

    const unixTimestampDateFormatterSpecifyFormat = (dateValue: number) => {
        if (showYearOnly) {
            return unixTimestampDateFormatterYYYY(dateValue)
        } else {
            const dateFromNumber: moment.Moment = moment(dateValue).utc()
            const dateFormat: DateFormatValueType = ccRequest?.dateFormat || DateFormatValue.American
            return dateFromNumber.format(dateFormat.toUpperCase())
        }
    }

    const generateXAxis = () => {
        return <XAxis type='number' domain={['dataMin', 'dataMax']} dataKey='dateUnixTimeStamp' scale='time' tickFormatter={unixTimestampDateFormatterSpecifyFormat} allowDuplicatedCategory={false} label={rechartsSourceTextLabelObject} />
    }

    const customTicks = (props: any): ReactElement<SVGElement> => {
        const { payload, x, y } = props
        const tickValue: number = payload?.value || 0
        const fontSize: number = tickValue > 99999999 ? 9 : 16
        return <text x={x} y={y} textAnchor='end' fontSize={fontSize}>{tickValue}</text>
    }

    const generateYAxis = () => {
        return <YAxis orientation='left' scale={'linear'} domain={['auto', 'auto']} interval={'preserveStartEnd'} allowDecimals={false} tickCount={3} tick={customTicks} />
    }

    const generateLines = (): JSX.Element[] => {
        const arrayOfJSXLines: any = []
        if (chartData && chartData.length > 0) {
            chartData.forEach((entry: ChartView, indexEntry: number) => {
                if (entry.metaDataArray) {
                    const currentTitle: string = entry.title
                    const metaData: ChartMetaData = entry.metaDataArray[0] || null
                    const lineName: string = metaData?.chartName || ''
                    const linesData: any[] = entry.dataSources && entry.dataSources.length > 0 ? entry.dataSources[0] : []
                    const linesDataWithUnixTimestamp: any[] = addUnixTimeStampProperty(linesData)
                    const color = getNextChartDarkColor(indexEntry)
                    arrayOfJSXLines.push(<Line key={`${lineName}Line`} data={linesDataWithUnixTimestamp} name={currentTitle} type='monotone' dataKey={lineName + '.value'} stroke={color} dot={false} connectNulls hide={hiddenCharts.includes(currentTitle)} />)
                }
            })
        }
        return arrayOfJSXLines
    }

    const doCurrencyChartRequest = (request: CurrencyConversionChartRequest) => {
        setShowLoadingModal(true)
        getCurrencyConversionChart(request).then(
            (response: ChartDefinition) => {
                setChartData(response?.charts || [])
                setShowLoadingModal(false)
            },
            //Reject promise
            (notOKResponseModel: NotOKResponseModel) => {
                setErrorResponse(notOKResponseModel)
                setShowLoadingModal(false)
            }
        )
    }

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('')
            setRefAreaRight('')
            return
        }

        let refStartDate: moment.Moment = moment(refAreaLeft)
        let refEndDate: moment.Moment = moment(refAreaRight)

        if (!refStartDate.isValid() || !refEndDate.isValid()) {
            return
        }

        if (refStartDate > refEndDate) {
            refStartDate = moment(refAreaRight)
            refEndDate = moment(refAreaLeft)
        }

        if (ccRequest) {
            let ccChartRequest: CurrencyConversionChartRequest = structuredClone(ccRequest)
            ccChartRequest.startDate = refStartDate.toISOString()
            ccChartRequest.endDate = refEndDate.toISOString()
            doCurrencyChartRequest(ccChartRequest)
            setZoomedRequest(ccChartRequest)
        }
        setRefAreaLeft('')
        setRefAreaRight('')
    }

    const zoomOut = () => {
        setChartData(chartDef?.charts || [])
        setZoomedRequest(null)
    }

    if (!isChartDataValid()) {
        return <></>
    }

    const downloadDataFile = (format: OutputFormatValueType) => {
        const chartRequest: CurrencyConversionChartRequest | null = zoomedRequest || ccRequest
        if (!chartRequest) {
            return
        }
        const chartDataDownloadRequest: CreateCurrencyConversionChartDownloadDataRequest = {
            chartRequest: chartRequest,
            outputFormat: format
        }
        setShowLoadingModal(true)
        createCurrencyConversionChartDownloadData(chartDataDownloadRequest).then(
            (fileCreateResponse: CreateCurrencyConversionChartDownloadDataResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
                setShowLoadingModal(false)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
                setShowLoadingModal(false)
            }
        )
    }

    const downloadCSV = () => {
        downloadDataFile(OutputFormatValue.CSV)
    }

    const downloadExcel = () => {
        downloadDataFile(OutputFormatValue.Excel)
    }

    const imageID: string = 'currencyConverterGraph'

    const graphDownloadOptionsProps: GraphDownloadOptionsProps = {
        downloadCSV: downloadCSV,
        downloadExcel: downloadExcel,
        imageIDValue: imageID
    }

    const handleButtonStyle = () => {
        if (zoomOutButtonEnabled) {
            return { backgroundColor: globalStyles.siteThemeColor, color: '#FFF' }
        } else {
            return { backgroundColor: 'grey', color: '#FFF' }
        }
    }

    const CCToolTip = (props: any): JSX.Element => {
        const { payload, label } = props
        const payloadArray: any[] = payload

        if (Array.isArray(payloadArray) && payloadArray.length > 0) {
            const currencyConversionDisplayElements: JSX.Element[] = []
            payloadArray.forEach((value: any, index: number) => {
                const keyName: string = value?.name || ''
                const ccValue: string = formatNumberEnUSLocalString(value?.value as unknown as number) || ''
                const ccElement: JSX.Element = <p key={`ccElement${index}`}><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{keyName}:</span> {ccValue}</p>
                currencyConversionDisplayElements.push(ccElement)
            })

            return (
                <Paper elevation={3} style={{ padding: 5 }} >
                    <div className='cc-tooltip'>
                        <p><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Date:</span> {unixTimestampDateFormatter(label)}</p>
                        {currencyConversionDisplayElements}
                    </div>
                </Paper>
            )
        }

        return <></>
    }

    return (
        <>
            <LoadingScreen showModal={showLoadingModal} setShowModal={setShowLoadingModal} />
            {chartData?.length > 0 &&
                <Grid container columns={12} justifyContent='flex-end' sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    <Grid item md={4}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <BtnZoom startIcon={<Zoom />} variant='contained' style={handleButtonStyle()} onClick={zoomOut} disabled={!zoomOutButtonEnabled}>Zoom Out</BtnZoom>
                            </Grid>
                            <Grid item style={{ paddingLeft: '16px' }}>
                                <FormGroup>
                                    <FormControlLabel control={
                                        <CheckboxBlue
                                            id='showYearOnlyCheckBox'
                                            checked={showYearOnly}
                                            onChange={(e: any) => {
                                                setShowYearOnly(e.target.checked)
                                            }}
                                        />}
                                        label='Show Years Only' />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        <GraphDownloadOptions {...graphDownloadOptionsProps} />
                    </Grid>
                </Grid>
            }
            <ResponsiveContainer id={imageID} width='100%' height={chartSizes.standard}>
                <LineChart
                    width={500}
                    height={500}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onMouseDown={(e: any) => {
                        if (e && e.activeLabel) {
                            setRefAreaLeft(e.activeLabel)
                        }
                    }}
                    onMouseMove={(e: any) => {
                        if (e && e.activeLabel) {
                            refAreaLeft && setRefAreaRight(e.activeLabel)
                        }
                    }}
                    onMouseUp={(e: any) => {
                        zoom()
                    }}
                >
                    <CartesianGrid />
                    {generateXAxis()}
                    {generateYAxis()}
                    <Legend onClick={handleLegendClick} />
                    <Tooltip content={<CCToolTip />} cursor={{ stroke: 'red', strokeWidth: 1 }} />
                    {generateLines()}
                    {refAreaLeft && refAreaRight && (
                        <ReferenceArea
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    )}
                    {graphCompanyLogoSVG()}
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default CCChart
