import { CircularProgress, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { LogoutReasonType } from '../../../../../Models/DataModels/Requests/AuthRequests'
import { GFDToastError, GFDToastInfo, GFDToastSuccess } from '../../../../Common/Utility/GFDToastify'
import { SyncCustomIndexRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import { syncCustomIndex } from '../../../../../Services/CustomIndexService'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import FamaFrenchIcon from '../../../../Icons/FamaFrenchIcon'
import { globalStyles } from '../../../../Common/GlobalSettings/GlobalStyles'
import { Sync } from '@mui/icons-material'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../../../Common/Modals/AlertModal'
import { CustomIndex } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../../Google/analytics'

export interface ScreenReportActionsProps {
    customIndex: CustomIndex,
    setSuspendAutoRefresh: (value: boolean) => void,
    onSync: () => void,
    setEditScreenReportId: (screenReportID: number) => void,
    setScreenReportCustomIndexId: (customIndexId: number | null) => void,
    clearAlert: () => void,
    setResponse: (response: NotOKResponseModel) => void,
    signOut: (logoutReason: LogoutReasonType) => void
}

const ScreenReportActions = ({
  customIndex,
    setSuspendAutoRefresh,
    onSync,
    setEditScreenReportId,
    setScreenReportCustomIndexId,
    clearAlert,
    setResponse,
    signOut
}: ScreenReportActionsProps) => {

    const [syncInProgress, setSyncInProgress] = useState<boolean>(false)
    const [showSyncConfirmationModal, setShowSyncConfirmationModal] = useState<boolean>(false)
    
    const alertModalProps: AlertModalProps = {
        showModal: showSyncConfirmationModal,
        setShowModal: setShowSyncConfirmationModal,
        AlertTitle: 'Are you sure?',
        AlertContent: `Are you sure you want to Sync ${customIndex?.symbol} with it's Fama French Screen Report? This will result in the Custom Index members being updated, and the index data will be regenerated. Do you want to proceed?`,
        AlertButtons: [
          {
            type: AlertButtonType.Cancel,
            display: 'Cancel',
            onClick: () => {
              setSuspendAutoRefresh(false)
              setShowSyncConfirmationModal(false)
            },
            isPrimary: false,
          },
          {
            type: AlertButtonType.OK,
            display: 'Yes',
            onClick: () => {
              setShowSyncConfirmationModal(false)
              syncCustomIndexMembers(customIndex?.screenReportID || 0)
              setSuspendAutoRefresh(false)
            },
            isPrimary: true,
          },
        ],
        onAlertClose: () => {
          setSuspendAutoRefresh(false)
          return true
        }
      }

    const syncCustomIndexMembers = (famaScreenID: number) => {
        if (syncInProgress) {
          GFDToastInfo(`Sync already in progress for Custom Index [${customIndex?.symbol}]`)
          return
        }
    
        setSyncInProgress(true)
    
        const request: SyncCustomIndexRequest = {
          screenReportID: famaScreenID
        }
    
        syncCustomIndex(request).then(
          (res: any) => {
            setSyncInProgress(false)
            if (onSync) onSync()
            GFDToastSuccess(`Sync done for Custom Index [${customIndex?.symbol}]`)
          },
          //Reject promise
          (notOKResponseModel: NotOKResponseModel) => {
            setSyncInProgress(false)
            GFDToastError(`Sync failed for Custom Index [${customIndex?.symbol}]`)
            setResponse(notOKResponseModel)
          })
      }
    
    const renderScreenReportActions = (screenReportID: number, customIndexID: number) => {
    return <>
        <IconButton title={'Fama French Custom Index'} size='small' style={{ backgroundColor: '#e7c3fa' }}
        onClick={() => {
            gaLogEvent('Clicked on Tools Custom Index Fama French Custom Index Button', gaFinaeonWebAppEventCategories.Tools, 'Custom Index Fama French Custom Index')
            clearAlert()
            setEditScreenReportId(screenReportID)
            setScreenReportCustomIndexId(customIndexID)
        }}
        >
        <FamaFrenchIcon style={{ fontSize: '1.2em' }}/>
        </IconButton>
        {syncInProgress ?
        <IconButton title='Sync in progress' aria-label='Sync in progress' component='label'
            size='small' style={{ marginLeft: '5px' }}
        >
            <CircularProgress style={{...globalStyles.circularProgress, display: 'inline'}} />
        </IconButton>
        :
        <IconButton title='Sync Index Members' aria-label='Sync Index Members' component='label'
            size='small' style={{ backgroundColor: '#e7c3fa', marginLeft: '5px' }}
            onClick={() => {
            gaLogEvent('Clicked on Tools Custom Index Sync Index Members Button', gaFinaeonWebAppEventCategories.Tools, 'Custom Index Sync Index Members')
            clearAlert()
            setSuspendAutoRefresh(true)
            setShowSyncConfirmationModal(true)
            }}
        >
            <Sync style={{ fontSize: '1.2em' }}></Sync>
        </IconButton>
        }
    </>
    }

    return (
        <>
            {customIndex?.screenReportID ? renderScreenReportActions(customIndex?.screenReportID, customIndex?.tickerID) : <></>}
            {showSyncConfirmationModal && <AlertModal {...alertModalProps} />}
        </>
    )
}

export default ScreenReportActions
