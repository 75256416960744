import MaterialTable from '@material-table/core'
import { IconButton, CircularProgress, Tooltip, Grid } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import MaterialTableOptions from '../../Common/GlobalSettings/MaterialTableOptions'
import { SearchFields, SortFieldType, SortFields, SortOrderString, SortOrderType } from '../../../Models/DataModels/Common/SortModel'
import { SubSearch, SubSearchProps } from '../../Common/Utility/SubSearch'
import SelectToolbar from '../../Common/Utility/SelectToolbar'
import GFDTablePagination, { GFDTablePaginationProps } from '../../Common/Utility/GFDTablePagination'
import { AppConfigurations, limitInfoMessage } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { LogoutReasonType } from '../../../Models/DataModels/Requests/AuthRequests'
import { SearchDatabaseType, SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import { SubscriptionFilter, SubscriptionFilterProps } from '../../Common/Utility/SubscriptionFilter'
import { SubscriptionFilterType } from '../../../Models/DataModels/Common/SubscriptionFilter'
import { getLocallyStoredCountries } from '../../../Services/DropDownValuesService'
import { LabelValuePair } from '../../../Models/DataModels/Common/LabelValuePairModel'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from '../../../Models/DataModels/Common/RedirectionModel'
import { SearchRequestModel } from '../../../Models/DataModels/Common/SearchModels'
import SearchResultExport, { SearchResultExportProps } from './SearchResultExport'
import { addTickersToWorkbookByID, maximumTickerLimit } from '../../../Services/AutoTracService'
import { getSavedResultsFromStorageAsMap, localStorageKeys, setLocalStorage } from '../../../Services/LocalStorageService'
import { sessionStorageKeys } from '../../../Services/SessionStorageService'
import QuickActions, { QuickActionsProps } from '../../Common/Modals/QuickActions'
import EnhancedTableHead from '../../Common/Utility/EnhancedTableHead'
import AddSeriesIcon from '../../Icons/AddSeriesIcon'
import { GFDToastInfo } from '../../Common/Utility/GFDToastify'
import GraphwLines from '../../Icons/GraphwLines'
import PieGraph from '../../Icons/PieGraphIcon'
import BarGraph from '../../Icons/BarGraph'
import DownLoad from '../../Icons/DownLoad'
import AddtoWorkbookIcon from '../../Icons/AddtoWorkbookIcon'
import SelectedSearchResultExport, { SelectedSearchResultExportProps } from './SelectedSearchResultExport'
import AddSelectedSeries from '../../Icons/AddSelectedSeriesIcon'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { ChartType, ChartTypes } from '../../../Models/DataModels/Common/ToolsModel'
import { TickerData } from '../../../Models/DataModels/Responses/AutoTracResponses'
import AddTickersToCustomIndex, { AddTickersToCustomIndexProps } from '../../Common/Utility/AddTickersToCustomIndex'
import AlertTickerLimitModal, { AlertTickerLimitModalProps } from '../../Common/Modals/AlertTickerLimitModal'
import DownloadAction, { DownloadActionProps } from '../../Common/Utility/DownloadAction'
import WorkbookSelectionModal, { WorkbookSelectionModalProps } from '../../Common/Modals/WorkbookSelectionModal'
import { ErrorHandler, ErrorHandlerProps } from '../../Common/Utility/ErrorHandler'
import SelectToolbarSelectedResultsChartSetDateModal, { SelectToolbarSelectedResultsChartSetDateModalProps } from '../../Common/Modals/SelectToolbarSelectedResultsChartSetDateModal'
import { validateChartCount } from '../Charts/SearchResultsChartUtil'
import SelectToolbarLineGraphModal from '../../Common/Modals/SelectToolbarLineGraphModal'
import { Save } from '@mui/icons-material'
import { UserInfo } from '../../../Models/DataModels/Common/UserInfoModel'
import { DateFormatValue, DateFormatValueType } from '../../../Models/DataModels/Common/DateFormatModel'
import ExportOutputFormatSelectionModal from '../../Common/Modals/ExportOutputFormatSelectionModal'
import TrialUserLock, { trialUserSeriesSelectMessage } from '../../Common/Utility/TrialUserLock'
import DownLoadGreen from '../../Icons/DownLoadGreen'
import { gaFinaeonWebAppEventCategories, gaLogEvent } from '../../Google/analytics'

export interface SortProps {
  sortField: SortFieldType,
  sortOrder: SortOrderType
}

export interface PageProps {
  title: string,
  inProgress: boolean,
  perPageCount: number,
  pageNumber: number,
  totalCount: number,
  setPageNumber: (page: number) => void,
  setPerPageCount: (perPage: number) => void,
  subSearchTerm: string,
  setSubSearchTerm: (term: string) => void,
  sortProps: SortProps,
  setSortProps: (props: SortProps) => void,
  subscriptionFilter: SubscriptionFilterType,
  setSubscriptionFilter: (type: SubscriptionFilterType) => void
}

export interface SearchResultsProps {
  userInfo: UserInfo | null,
  engineOption: SearchDatabaseType,
  tableData: any,
  checkIsTrial: () => boolean,
  checkIsAnonymous: () => boolean,
  pageData: PageProps,
  signOut: (logoutReason: LogoutReasonType) => void,
  setShowSaved: Dispatch<SetStateAction<boolean>>,
  expanded: boolean,
  searchRequestModel: SearchRequestModel | null
}

const SearchResults = ({
  userInfo, engineOption, tableData, checkIsTrial, checkIsAnonymous, pageData, signOut, setShowSaved, expanded, searchRequestModel
}: SearchResultsProps) => {
  const KEY_COLUMN = 'uniqueID';

  const [tickerLimit, setTickerLimit] = useState<number>(AppConfigurations.maximumTickers)
  const [calculatedMax, setCalculatedMax] = useState<any>()

  const location: any = useLocation()
  const navigate = useNavigate()

  const getDefaultSort = (column: string) => {
    if (SearchFields.includes(column) &&
      pageData.sortProps.sortField === SortFields[column]) {
      return SortOrderString[pageData.sortProps.sortOrder]
    }
    return undefined
  }

  const eventsInTimeColumns: any[] = [
    { title: 'Start', field: 'startDate', sorting: true, width: '8%', type: 'date' },
    { title: 'End', field: 'endDate', sorting: true, width: '8%', type: 'date' },
    { title: 'Description', field: 'description', sorting: true, width: '74%', type: 'string' },
    { title: 'Country', field: 'country', sorting: true, width: '10%', type: 'string' },
    { title: 'Topics', field: 'topics', sorting: true, type: 'string' }
  ]

  const renderQuickActions = (rowData: any) => {
    const quickActionProps: QuickActionsProps = {
      rowData: rowData,
      graphAction: viewChart,
      maxTickerLimit: tickerLimit,
      signOut: signOut,
      checkIsTrial: checkIsTrial
    }
    return <QuickActions {...quickActionProps} />
  }

  const renderQuickActionsColumn = useCallback((rowData: any) => renderQuickActions(rowData), [])

  const generalColumns: any[] = [
    { title: 'AccessibleSeriesID', field: 'accessibleSeriesID', hidden: true, type: 'numeric' },
    { title: 'ID', field: 'id', hidden: true, width: '8%', type: 'numeric' },
    { title: 'Symbol', field: 'symbol', sorting: true, type: 'string', width: '6%' },
    { title: 'Description', field: 'description', sorting: true, width: '38%', type: 'string' },
    { title: 'Country', field: 'country', sorting: true, width: '10%', type: 'string' },
    { title: 'Series Type', field: 'series', sorting: true, width: '16%', type: 'string' },
    { title: 'Start', field: 'startDate', sorting: true, type: 'date', width: '8%' },
    { title: 'End', field: 'endDate', sorting: true, type: 'date', width: '8%' },
    {
      title: "Quick Actions",
      sorting: false,
      field: "button",
      width: '14%',
      render: renderQuickActionsColumn
    },
    { title: KEY_COLUMN, field: KEY_COLUMN, hidden: true, type: 'string' }
  ]

  const generalColumnsWithSavedIndicator: any[] = [
    {
      title: '',
      sorting: false,
      field: 'saved',
      width: '2%',
      render: (rowData: any) => rowData.id && savedResults.has(rowData.id) ? <Tooltip title='Saved'>
        <IconButton aria-label='Saved' component='label' disabled={true}>
          <Save style={{ fontSize: '1.2em', color: '#3f51b5' }} />
        </IconButton>
      </Tooltip> : <></>
    },
    ...generalColumns
  ]

  const getColumns = (): any[] => {
    const columns: any[] = engineOption === SearchDatabaseTypes.EventsInTime ? eventsInTimeColumns :
      (!checkIsAnonymous() && !checkIsTrial()) ? generalColumnsWithSavedIndicator
        : generalColumns
    return columns
  }

  const viewChart = (primaryTicker: string) => {
    navigate(`${paths.graph.lineGraph}/${primaryTicker}`, { state: { prevPage: location.pathname } })
  }

  const [countryList, setCountryList] = useState<LabelValuePair[]>([])

  const adjustEventsInTimeCountryColumn = () => {
    if (data) {
      if (engineOption === SearchDatabaseTypes.EventsInTime) {
        data?.forEach(dataEntry => {
          if (dataEntry?.other_isos) {
            const countries = dataEntry?.other_isos?.split(',')
            dataEntry.country = countries?.reduce((accum: string[], countryEntry: string | null | undefined) => {
              const value = countryList?.find(listEntry => listEntry.value === countryEntry?.trim())?.label || ''
              if (value) accum.push(value)
              return accum
            }, [])?.join(', ') || dataEntry.country
          }
        })
      }
    }
  }

  const [data, setData] = useState<any[]>([])

  const [savedResults] = useState<Map<number, any>>(getSavedResultsFromStorageAsMap())
  const [savedIDs, setSavedIDs] = useState<number[]>([...savedResults.keys()])
  const [columnDefinitions, setColumnDefinitions] = useState<any[]>(getColumns())

  const [localFilter, setLocalFilter] = useState<string>('')

  useEffect(() => {
    getLocallyStoredCountries(SearchDatabaseTypes.GFDatabase)
      .then((value: LabelValuePair[] | null) => value && setCountryList(value), () => { })
    maximumTickerLimit().then((value: number) => setTickerLimit(value), () => { })
    sessionStorage.removeItem(sessionStorageKeys.selectedResultsForChartsData)

    setCalculatedMax((window.innerHeight - 465).toString() + 'px')
  }, [])

  useEffect(() => {
    setData([])
    setColumnDefinitions(getColumns())
  }, [engineOption])

  const makeUnique = (input: any[]) => {
    let result;
    try {
      result = input?.map((entry: any, index) => {
        entry[KEY_COLUMN] = `${entry.id}_${index}`
        return entry
      })
    } catch(error) {
      console.log(error)
      result = input
    }
    return result
  }

  useEffect(() => {
    setLocalFilter('')
    setData(makeUnique(tableData?.masterlist || []))
  }, [tableData])

  useEffect(() => {
    adjustEventsInTimeCountryColumn()
  }, [data])

  useEffect(() => {
    if (savedResults.size > 0 && expanded) {
      setCalculatedMax((window.innerHeight - 485).toString() + 'px')
    } else if (savedResults.size > 0) {
      setCalculatedMax((window.innerHeight - 365).toString() + 'px')
    } else if (expanded) {
      setCalculatedMax((window.innerHeight - 465).toString() + 'px')
    }
    else {
      setCalculatedMax((window.innerHeight - 345).toString() + 'px')
    }
  }, [savedResults.size])

  useEffect(() => {
    if (expanded) {
      setCalculatedMax((window.innerHeight - 465).toString() + 'px')
    } else {
      setCalculatedMax((window.innerHeight - 345).toString() + 'px')
    }
  }, [expanded])

  window.addEventListener("resize", () => setCalculatedMax((window.innerHeight - 465).toString() + 'px'))


  const onSort = (columnNumber: number) => {
    const props: SortProps = {
      sortField: pageData.sortProps.sortField,
      sortOrder: pageData.sortProps.sortOrder
    }

    try {

      const column = columnDefinitions[columnNumber]
      const columnName = column.field
      const field: SortFieldType = SortFields[columnName] || SortFieldType.None

      if (field !== props.sortField) {
        props.sortField = field

        if (column.defaultSort === 'asc') {
          props.sortOrder = SortOrderType.Descending
        } else if (column.defaultSort === 'desc') {
          props.sortField = SortFieldType.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      } else {
        if (props.sortOrder === SortOrderType.Ascending) {
          props.sortOrder = SortOrderType.Descending
        } else if (props.sortOrder === SortOrderType.Descending) {
          props.sortField = SortFieldType.None
          props.sortOrder = SortOrderType.None
        } else {
          props.sortOrder = SortOrderType.Ascending
        }
      }

      pageData.setSortProps(props)
    }
    catch (e) {
      console.log('Exception while sorting: ', e)
    }
  }

  const onSubSearchResults = (term: string) => {
    setLocalFilter('')
    pageData.setSubSearchTerm(term || '')
  }

  const onSubSearchPage = (term: string) => {
    setLocalFilter(term)
  }

  const getFilteredPage = () => {
    return localFilter ? data?.filter(row => Object.values(row).some((field: any) => field?.toString()?.toLowerCase()?.includes(localFilter))) : data
  }

  const clearSubSearch = () => {
    if (localFilter) {
      setLocalFilter('')
    } else {
      pageData.setSubSearchTerm('')
    }
  }

  const subSearchProps: SubSearchProps = {
    pageFilterTerm: localFilter,
    subSearchTerm: pageData.subSearchTerm,
    onSubSearchResults,
    onSubSearchPage,
    clearSubSearch
  }

  const gfdTablePaginationProps: GFDTablePaginationProps = {
    perPageCount: pageData.perPageCount,
    pageNumber: pageData.pageNumber,
    totalCount: pageData.totalCount,
    setPageNumber: pageData.setPageNumber,
    setPerPageCount: pageData.setPerPageCount
  }

  const subscriptionFilterProps: SubscriptionFilterProps = {
    subscriptionFilter: pageData.subscriptionFilter,
    setSubscriptionFilter: pageData.setSubscriptionFilter
  }

  const exportProps: SearchResultExportProps = {
    searchRequestModel,
    subSearchTerm: pageData.subSearchTerm,
    sortProps: pageData.sortProps,
    subscriptionFilter: pageData.subscriptionFilter,
    totalCount: pageData.totalCount,
    displayText: 'Export Search List',
    signOut
  }

  const addSelectedToSavedResultsStorage = (selectedTickers: any[]) => {
    gaLogEvent('Clicked on Toolbar Add to Saved Results Button', gaFinaeonWebAppEventCategories.Search, 'addSelectedToSavedResultsStorage')
    for (var entry of selectedTickers) {
      if (entry && entry?.accessibleSeriesID) {
        if (!savedResults.has(entry.id)) {
          if (savedResults.size >= AppConfigurations.maximumTickers) {
            GFDToastInfo(limitInfoMessage)
            break
          }
          savedResults.set(entry.id, entry)
        }
      }
    }

    const ids = [...savedResults.values()]
    setSavedIDs(ids)
    setLocalStorage(localStorageKeys.selectedResultsData, JSON.stringify([...savedResults.values()]))
  }

  // /////
  const [selectedTickerIDs, setSelectedTickerIDs] = useState<number[]>([])
  const [tickerLimitAlertMessage, setTickerLimitAlertMessage] = useState(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. Please try with ${AppConfigurations.maximumTickers} series or less.`)
  const [showTickerLimitModal, setShowTickerLimitModal] = useState<boolean>(false)
  const [showWorkbookSelectionModal, setShowWorkbookSelectionModal] = useState<boolean>(false)
  const [selectedWorkbook, setSelectedWorkbook] = useState<string>('')
  const [doAddToWorkbook, setDoAddToWorkbook] = useState<boolean>(false)
  const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
  const [showLineGraphModal, setShowLineGraphModal] = useState<boolean>(false)
  const [lineGraphModalData, setLineGraphModalData] = useState<any[]>([])
  const [chartType, setChartType] = useState<ChartType>(ChartTypes.Pie)
  const [showSelectedResultsChartSetDateModal, setShowSelectedResultsChartSetDateModal] = useState<boolean>(false)
  const [customIndexData, setCustomIndexData] = useState<any[]>([])
  const [showCustomIndexSelectionModal, setShowCustomIndexSelectionModal] = useState<boolean>(false)

  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

  const processAddToCustomIndex = (selectedTickers: any[]) => {
    gaLogEvent('Clicked on Toolbar Custom Index Button', gaFinaeonWebAppEventCategories.Search, 'processAddToCustomIndex')
    setCustomIndexData(selectedTickers)
    setShowCustomIndexSelectionModal(true)
  }

  // clearing messages is pertinent only if the component is part of the same window,
  // but in this context, modals are used 
  const messageToggleProps = {
    clearMessagesToggle: false,
    clearMessages: () => { } // not required for modal, setting state here causes containing page to refresh, causing lag
  }

  const addTickersToCustomIndexProps: AddTickersToCustomIndexProps = {
    showCustomIndexSelectionModal,
    setShowCustomIndexSelectionModal,
    customIndexData,
    signOut,
    ...messageToggleProps
  }

  const alertTickerLimitModalProps: AlertTickerLimitModalProps = {
    showTickerLimitModal,
    setShowTickerLimitModal,
    tickerLimitAlertMessage
  }

  const processDownload = (selectedTickers: any[]) => {
    gaLogEvent('Clicked on Toolbar Download Button', gaFinaeonWebAppEventCategories.Search, 'processDownload')
    if (!selectedTickers || !selectedTickers?.length) return

    const tickers: TickerData[] = Object.values(selectedTickers)

    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite: false,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      checkIsTrial,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  const processAddToWorkbook = (selectedTickers: any[]) => {
    gaLogEvent('Clicked on Toolbar Add To Workbook Button', gaFinaeonWebAppEventCategories.Search, 'processAddToWorkbook')
    if (!selectedTickers) return

    const tickerIDs = Object.values(selectedTickers).map((d: any) => d?.id)
    setSelectedTickerIDs(tickerIDs)

    const count = tickerIDs.length

    if (count === 0) return
    if (count > AppConfigurations.maximumTickers) {
      setTickerLimitAlertMessage(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. ${count} series have been selected. Please try with ${AppConfigurations.maximumTickers} or less.`)
      setShowTickerLimitModal(true)
      return
    }

    setShowWorkbookSelectionModal(true)
  }

  const onFinalWorkbookSelection = (returnValue: boolean, workbook: string) => {
    setSelectedWorkbook(workbook)
    setDoAddToWorkbook(returnValue)
  }

  const workbookSelectionModalProps: WorkbookSelectionModalProps = {
    userInfo,
    onFinal: onFinalWorkbookSelection,
    showModal: showWorkbookSelectionModal,
    setShowModal: setShowWorkbookSelectionModal,
    signOut,
    ...messageToggleProps
  }

  const executeAddToWorkbook = () => {
    if (!doAddToWorkbook
      || !selectedWorkbook
      || !selectedTickerIDs
      || !selectedTickerIDs?.length) return

    addTickersToWorkbookByID({ workbookName: selectedWorkbook, tickerIDs: selectedTickerIDs })
      .then((result: any) => {
        if (result?.messages?.length) {
          const notOKResponseModel: NotOKResponseModel = {
            isBackgroundCall: false,
            statusCode: 200,
            notOKResponse: {
              internal: false,
              success: true,
              logOut: false,
              messages: result?.messages
            }
          }
          setErrorResponse(notOKResponseModel)
        }
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
        })
  }

  const errorHandlerProps: ErrorHandlerProps = {
    response: errorResponse,
    signOut: signOut
  }

  useEffect(() => {
    if (doAddToWorkbook) {
      executeAddToWorkbook()
      setDoAddToWorkbook(false)
    }
  }, [doAddToWorkbook])

  const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)
  const [exportDateFormat, setExportDateFormat] = useState<DateFormatValueType>(DateFormatValue.AmericanWithDashes)
  const [fireExportAction, setFireExportAction] = useState<boolean>(false)

  const dateFormatCommitted = (dateFormat: DateFormatValueType) => {
    setExportDateFormat(dateFormat)
    setFireExportAction(true)
  }

  const setSelectionAndFireExport = (data: any) => {
    gaLogEvent('Clicked on Toolbar Export Selected Button', gaFinaeonWebAppEventCategories.Search, 'setSelectionAndFireExport')
    const tickerIDs = data && data?.length ? Object.values(data).map((d: any) => d?.id) : []
    setSelectedTickerIDs(tickerIDs)
    setShowExportOutputFormatModal(true)
    // setFireExportAction(true)  }
  }
  const selectedSearchResultExportProps: SelectedSearchResultExportProps = {
    selectedResults: selectedTickerIDs,
    displayForActionOnly: true,
    fireAction: fireExportAction,
    resetFireAction: () => { setFireExportAction(false) },
    exportDateFormat: exportDateFormat,
    signOut
  }

  const onViewChartClick = (selectedTickers: any[]) => {
    gaLogEvent('Clicked on Toolbar Graph Button', gaFinaeonWebAppEventCategories.Search, 'onViewChartClick')
    const selectedResultsArray: any[] = [...selectedTickers]
    if (selectedResultsArray.length === 1) {
      navigate(`${paths.graph.lineGraph}/${selectedResultsArray[0].symbol}`, { state: { prevPage: location.pathname } })
    } else if (selectedResultsArray.length > 1) {
      setLineGraphModalData(selectedResultsArray)
      setShowLineGraphModal(true)
    }
  }

  const selectToolbarLineGraphModalProps = {
    showLineGraphModal: showLineGraphModal,
    setShowLineGraphModal: setShowLineGraphModal,
    selectedResults: lineGraphModalData
  }

  const selectToolbarSelectedResultsChartSetDateModalProps: SelectToolbarSelectedResultsChartSetDateModalProps = {
    chartType: chartType,
    engineOption: engineOption,
    show: showSelectedResultsChartSetDateModal,
    setShow: setShowSelectedResultsChartSetDateModal,
    lastPathFromCharts: location.pathname
  }

  const handleViewBarChart = (data: any[]) => {
    gaLogEvent('Clicked on Toolbar Bar Chart Button', gaFinaeonWebAppEventCategories.Search, 'handleViewBarChart')
    if (!validateChartCount(data)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...data.values()]))
    setChartType(ChartTypes.Bar)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const handleViewPieChart = (data: any[]) => {
    gaLogEvent('Clicked on Toolbar Pie Chart Button', gaFinaeonWebAppEventCategories.Search, 'handleViewPieChart')
    if (!validateChartCount(data)) {
      return
    }
    sessionStorage.setItem(sessionStorageKeys.selectedResultsForChartsData, JSON.stringify([...data.values()]))
    setChartType(ChartTypes.Pie)
    setShowSelectedResultsChartSetDateModal(true)
  }

  const allowSelection: boolean = (engineOption === SearchDatabaseTypes.EventsInTime || checkIsAnonymous() ? false : true)

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ThemeProvider theme={DefaultMaterialTheme}>
        <Grid container>
          <Grid item md={4}>
            <div style={{ marginLeft: '24px' }}>
              <SubSearch {...subSearchProps} />
            </div>
          </Grid>
          <Grid item md={2}>
            <Grid container sx={{ justifyContent: 'center' }}>
              <Grid item>
                {<SelectToolbar savedIDs={savedIDs} setSavedIDs={setSavedIDs} savedResults={savedResults} setShowSavedResults={setShowSaved} />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container sx={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <SearchResultExport {...exportProps} />
              </Grid>
              <Grid item>
                {engineOption !== SearchDatabaseTypes.EventsInTime && <SubscriptionFilter {...subscriptionFilterProps} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <MaterialTable
          columns={columnDefinitions}
          data={localFilter ? getFilteredPage() : data}
          title={<>
            {pageData.title}
            <Tooltip title='Search in Progress'>
              <IconButton aria-label='Search in Progress' component='label' sx={{
                p: '10px',
                color: '#1976d2',
                visibility: pageData.inProgress ? "visible" : "hidden"
              }}>
                <CircularProgress aria-label='Search in Progress' />
              </IconButton>
            </Tooltip>
          </>}
          options={
            {
              idSynonym: KEY_COLUMN,
              ...MaterialTableOptions,
              maxBodyHeight: calculatedMax,
              selection: checkIsTrial() ? false : allowSelection,
              selectionProps: rowData => ({
                disabled: (rowData?.id !== rowData?.accessibleSeriesID) || checkIsTrial(),
                inputProps: {
                  'title': 'Select/Deselect',
                  'aria-label': 'Select/Deselect'
                }
              }),
              search: false
            }
          }
          actions={
            checkIsTrial() ? [
              {
                icon: '',
                tooltip: '',
                onClick: (event, rowData) => { }
              }
            ] : allowSelection ? [
              {
                tooltip: 'Add to Saved Results',
                icon: () => <AddSeriesIcon style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => addSelectedToSavedResultsStorage(selectedTickers)
              },
              {
                tooltip: 'Graph Selected with Lines',
                icon: () => <GraphwLines style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => onViewChartClick(selectedTickers)
              },
              {
                tooltip: 'Graph Selected with Pie Chart',
                icon: () => <PieGraph style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => handleViewPieChart(selectedTickers)
              },
              {
                tooltip: 'Graph Selected with Bar Chart',
                icon: () => <BarGraph style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => handleViewBarChart(selectedTickers)
              },
              {
                tooltip: 'Download Selected Series',
                icon: () => <DownLoadGreen style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => processDownload(selectedTickers)
              },
              {
                tooltip: 'Add to Workbook',
                icon: () => <AddtoWorkbookIcon style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => processAddToWorkbook(selectedTickers)
              },
              {
                tooltip: 'Export Selected',
                icon: () => <SelectedSearchResultExport {...selectedSearchResultExportProps} />,
                onClick: (evt, selectedTickers) => setSelectionAndFireExport(selectedTickers)
              },
              {
                tooltip: 'Add Selected Series to a Custom Index',
                icon: () => <AddSelectedSeries style={{ fontSize: '1.25em' }} />,
                onClick: (evt, selectedTickers) => processAddToCustomIndex(selectedTickers)
              }
            ] : []}
          components={{
            Pagination:
              props => (<GFDTablePagination {...gfdTablePaginationProps} />),
            Header:
              props => {
                const allowSelectionProps = allowSelection ? { allowSelection, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount } : {}
                return <EnhancedTableHead {...{ sortData: { getDefaultSort, onSort }, columns: columnDefinitions, ...allowSelectionProps, checkIsTrial: checkIsTrial }} />
              },
            ...(checkIsTrial() && {
              Action: props => (
                <TrialUserLock checkIsTrial={checkIsTrial} message={trialUserSeriesSelectMessage} />
              )
            })
          }}
        />
      </ThemeProvider>
      <AddTickersToCustomIndex {...addTickersToCustomIndexProps} />
      <WorkbookSelectionModal {...workbookSelectionModalProps} />
      {renderDownloadAction()}
      {showLineGraphModal ? <SelectToolbarLineGraphModal modalProps={selectToolbarLineGraphModalProps} /> : <></>}
      {showSelectedResultsChartSetDateModal && <SelectToolbarSelectedResultsChartSetDateModal {...selectToolbarSelectedResultsChartSetDateModalProps} />}
      <AlertTickerLimitModal {...alertTickerLimitModalProps} />
      <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={dateFormatCommitted} />
      <ErrorHandler {...errorHandlerProps} />
    </div>
  )
}

export default SearchResults
