import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import GFDatabase from '../Engines/GFDatabase'
import GFDIndices from '../Engines/GFDIndices'
import RealEstateDatabase from '../Engines/RealEstateDatabase'
import USStocks from '../Engines/USStocks'
import UKStocks from '../Engines/UKStocks'
import GFDUniverse from '../Engines/GFDUniverse'
import ConstituentMembership from '../Engines/ConstituentMembership'
import EventsInTime from '../Engines/EventsInTime'
import { Button, Tooltip } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { SearchDatabaseTypes } from '../../../Models/DataModels/Common/FieldPopulationModel'
import GFDatabase2Point0 from '../Engines/GFDatabase2Point0'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

const SearchEngine = ({ engineOption, searchEngineOptions, searchTitle, expandedOptions }: any) => {

    const handleChangeAccordian = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        expandedOptions.setExpanded((newExpanded && panel === engineOption) ? true : false)
    }

    const generateEngineOptions = () => {
        switch (engineOption) {
            case SearchDatabaseTypes.GFDatabase2Point0:
                return <GFDatabase2Point0 searchEngineOptions={searchEngineOptions.GFDatabaseData2Point0} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.GFDatabase:
                return <GFDatabase searchEngineOptions={searchEngineOptions.GFDatabaseData} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.GFDIndices:
                return <GFDIndices searchEngineOptions={searchEngineOptions.GFDIndicesData} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.RealEstate:
                return <RealEstateDatabase searchEngineOptions={searchEngineOptions.realEstateData} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.USStocks:
                return <USStocks searchEngineOptions={searchEngineOptions.stocksDataUS} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.UKStocks:
                return <UKStocks searchEngineOptions={searchEngineOptions.stocksDataUK} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.GFDUniverse:
                return <GFDUniverse searchEngineOptions={searchEngineOptions.GFDUniverseData} theme={redHelperTextTheme} />
            case SearchDatabaseTypes.ConstituentMembership:
                return <ConstituentMembership searchEngineOptions={searchEngineOptions.constituentMembershipData} />
            case SearchDatabaseTypes.EventsInTime:
                return <EventsInTime searchEngineOptions={searchEngineOptions.eventsInTimeData} theme={redHelperTextTheme} />
            default:
                return <React.Fragment />
        }
    }

    return (
        <React.Fragment>
            <Accordion expanded={expandedOptions.expanded} onChange={handleChangeAccordian(engineOption)} >
                <Tooltip title='Click to Expand or Hide Options'>
                    <Button
                        variant='text'
                        sx={{
                            width: '100%', padding: 0, backgroundColor: 'gainsboro', color: 'black',
                            "&:hover": {
                                backgroundColor: '#cdf9f4'
                            }
                        }}
                        onClick={() => {
                            gaLogEvent('Clicked on Expand/Hide Options Button', gaFinaeonWebAppEventCategories.Search, 'expandedOptions')
                            expandedOptions.setExpanded(!expandedOptions.expanded)
                        }}
                    >
                        {expandedOptions.expanded ? 'Hide ' + searchTitle + ' options' : 'Expand ' + searchTitle + ' options'}
                    </Button>
                </Tooltip>
                <AccordionDetails>
                    {generateEngineOptions()}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

const redHelperTextTheme = createTheme({
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: "red",
                }
            }
        },
    }
})

export default SearchEngine
