import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import DownLoad from '../../../Icons/DownLoad'
import DownLoadGreen from '../../../Icons/DownLoadGreen'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../Google/analytics'

export interface GraphDataDownloadButtonProps {
    downloadCSV: () => void,
    downloadExcel: () => void
}

const GraphDataDownloadButton = ({
    downloadCSV,
    downloadExcel
}: GraphDataDownloadButtonProps) => {

    const [anchorDownloadDataMenu, setAnchorDownloadDataMenu] = useState<null | HTMLElement>(null)
    const openDownloadDataMenu = Boolean(anchorDownloadDataMenu)
    
    const handleDownloadDataMenuMouseOver = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDownloadDataMenu(event.currentTarget)
    }

    const handleDownloadDataMenuClose = () => {
        setAnchorDownloadDataMenu(null)
    }

    return (
        <div>
            <IconButton
                id='graphDataDownloadButton'
                onMouseOver={handleDownloadDataMenuMouseOver}
                size='small'
                sx={{ ml: 2 }}
                title='Download Data'
                aria-label='Download Data'
                aria-controls={openDownloadDataMenu ? 'graphDataDownloadButton' : undefined}
                aria-haspopup='true'
                aria-expanded={openDownloadDataMenu ? 'true' : undefined}
            >
                <DownLoadGreen style={{ width: '32px', height: '32px' }}></DownLoadGreen>
            </IconButton>
            <Menu
                id='graphDownloadMenu'
                aria-labelledby='graphDataDownloadButton'
                anchorEl={anchorDownloadDataMenu}
                open={openDownloadDataMenu}
                onClose={handleDownloadDataMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    onMouseLeave: handleDownloadDataMenuClose
                }}
                disablePortal
            >
                <MenuItem onClick={() => {
                    gaLogEvent('Clicked on Graph Download CSV Option', gaFinaeonWebAppEventCategories.GraphDownload, 'graphDownloadMenu')
                    handleDownloadDataMenuClose()
                    downloadCSV()
                }}>CSV</MenuItem>
                <MenuItem onClick={() => {
                    gaLogEvent('Clicked on Graph Download XLSX Option', gaFinaeonWebAppEventCategories.GraphDownload, 'graphDownloadMenu')
                    handleDownloadDataMenuClose()
                    downloadExcel()
                }}>XLSX</MenuItem>
            </Menu>
        </div>
    )
}

export default GraphDataDownloadButton
