
import MaterialTable from '@material-table/core'
import { Grid, Typography, Tooltip, IconButton, CircularProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { useEffect, useMemo, useState } from 'react'
import MaterialTableOptions from '../../../../Common/GlobalSettings/MaterialTableOptions'
import { ExportScreenResults, SaveScreenStatisticsRequest } from '../../../../../Models/DataModels/Requests/ScreeningRequests'
import { exportScreenResults, saveScreenResults } from '../../../../../Services/ScreeningService'
import FamaSaveModal from '../../../../Common/Modals/FamaSaveModal'
import SaveIcon from '@mui/icons-material/Save'
import DownLoad from '../../../../Icons/DownLoad'
import AddtoWorkbook from '../../../../Icons/AddtoWorkbookIcon'
import ExporttoList from '../../../../Icons/ExporttoListIcon'
import QuickActions, { QuickActionsProps } from '../../../../Common/Modals/QuickActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { FileDownloadRequest, SyncCustomIndexRequest } from '../../../../../Models/DataModels/Requests/ToolsRequests'
import { downloadFileFromServer } from '../../../../../Services/DownloadService'
import { CreateDataFileResponse } from '../../../../../Models/DataModels/Responses/ToolsResponses'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import WorkbookSelectionModal, { WorkbookSelectionModalProps } from '../../../../Common/Modals/WorkbookSelectionModal'
import AlertModal, { AlertButtonType, AlertModalProps } from '../../../../Common/Modals/AlertModal'
import { TickerData } from '../../../../../Models/DataModels/Responses/AutoTracResponses'
import { addTickersToWorkbookByID } from '../../../../../Services/AutoTracService'
import DownloadAction, { DownloadActionProps } from '../../../../Common/Utility/DownloadAction'
import { BtnClearReset, CustomBtnBlue } from '../../../../Common/GlobalSettings/CustomStyles'
import { spreadAddKeyPropertiesToData } from './FamaFrenchUtil'
import DefaultMaterialTheme from '../../../../Common/GlobalSettings/DefaultMaterialTheme'
import { SortOrderStringType, getComparator, sort } from '../../../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../../../../Common/Utility/EnhancedTableHead'
import { GFDToastError, GFDToastInfo, GFDToastSuccess, GFDToastWarning } from '../../../../Common/Utility/GFDToastify'
import { syncCustomIndex } from '../../../../../Services/CustomIndexService'
import DownLoadGreen from '../../../../Icons/DownLoadGreen'
import { formatNumberEnUSLocalString } from '../../../../../Services/UtilityService'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../../Google/analytics'

const FamaFrenchResults = ({ userInfo, famaProps, famaValueProps, table, famaStateProps, signOut, showResetConfirmation, lastScreenRequest, setErrorResponse, checkIsTrial }: any) => {

  const location: any = useLocation()
  const navigate = useNavigate()

  const [data, setData] = useState<any[]>([])
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false)
  const [exportInProgress, setExportInProgress] = useState<boolean>(false)
  const [saveScreenReportInProgress, setSaveScreenReportInProgress] = useState<boolean>(false)

  const [showWorkbookSelectionModal, setShowWorkbookSelectionModal] = useState<boolean>(false)
  const [selectedWorkbook, setSelectedWorkbook] = useState<string>('')
  const [selectedTickerIDs, setSelectedTickerIDs] = useState<number[]>([])
  const [doAddToWorkbook, setDoAddToWorkbook] = useState<boolean>(false)
  const [tickerLimitAlertMessage, setTickerLimitAlertMessage] = useState(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. Please try with ${AppConfigurations.maximumTickers} series or less.`)
  const [showTickerLimitModal, setShowTickerLimitModal] = useState<boolean>(false)
  const [clearMessagesToggle, setClearMessagesToggle] = useState(false)

  const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
  const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

  const [availableEntitiyIDs, setAvailableEntitiyIDs] = useState<number[]>([])

  const renderQuickActions = (rowData: any) => {
    const quickActionProps: QuickActionsProps = {
      rowData: rowData,
      maxTickerLimit: AppConfigurations.maximumTickers,
      signOut: signOut,
      checkIsTrial: checkIsTrial
    }
    return <QuickActions {...quickActionProps} />
  }

  const columns = [
    {
      'title': 'ID',
      'field': 'id',
      'hidden': true
    },
    {
      'title': 'Symbol',
      'field': 'symbol',
      'hidden': false
    },
    {
      'title': 'Description',
      'field': 'description',
      'hidden': false
    },
    {
      'title': 'MarketCap',
      'field': 'marketCap',
      'hidden': !availableEntitiyIDs.includes(1778),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.marketCap)
    },
    {
      'title': 'PE Ratio',
      'field': 'PERatio',
      'hidden': !availableEntitiyIDs.includes(1786),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.PERatio)
    },
    {
      'title': 'Beta',
      'field': 'beta',
      'hidden': !availableEntitiyIDs.includes(1812),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.beta)
    },
    {
      'title': 'Momentum M3',
      'field': 'momentumM3',
      'hidden': !availableEntitiyIDs.includes(1800),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.momentumM3)
    },
    {
      'title': 'Momentum M12',
      'field': 'momentumM12',
      'hidden': !availableEntitiyIDs.includes(1804),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.momentumM12)
    },
    {
      'title': 'Momentum M36',
      'field': 'momentumM36',
      'hidden': !availableEntitiyIDs.includes(1808),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.momentumM36)
    },
    {
      'title': 'Div Yield',
      'field': 'divYield',
      'hidden': !availableEntitiyIDs.includes(1782),
      render: (rowData: any) => formatNumberEnUSLocalString(rowData?.divYield)
    },
    {
      title: "Quick Actions",
      sorting: false,
      field: "button",
      render: (rowData: any) => renderQuickActions(rowData)
    }
  ]

  const goToFamaScreening = () => {
    famaProps.setFamaScreening(true)
    famaProps.setFamaResults(false)
  }

  const openSaveReportModal = () => {
    gaLogEvent('Clicked on Tools Fama French Save Report Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French openSaveReportModal')
    setShowSaveModal(true)
  }
  const closeSaveReportModal = () => {
    if (saveScreenReportInProgress) {
      return
    }
    setShowSaveModal(false)
  }

  const handleSuccessSaveModal = (screeningName: string, description: string) => {
    saveScreenReport(screeningName, description)
  }

  const resetUI = () => {
    setSaveScreenReportInProgress(false)
    setShowSaveModal(false)
    setShowSyncConfirmationModal(false)
    setSyncInProgress(false)
    setScreeningNameForConfirmationModal(null)
    famaProps.setFamaResults(false)
    famaStateProps.setIsAddOrEditScreen(false)
  }

  const syncCustomIndexMembers = () => {
    if (syncInProgress) {
      GFDToastWarning(`Sync request already in progress`)
      return
    }
    if (!famaValueProps.screeningID) {
      GFDToastWarning(`Screening Report ID not available`)
      resetUI()
      return
    }

    const request: SyncCustomIndexRequest = {
      screenReportID: famaValueProps.screeningID
    }

    setSyncInProgress(true)
    syncCustomIndex(request).then(
      (res: any) => {
        GFDToastSuccess(`Sync done for Custom Index associated with [${screeningNameForConfirmationModal}]`)
        resetUI()
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        GFDToastError(`Sync failed for Custom Index associated with [${screeningNameForConfirmationModal}]`)
        setErrorResponse(notOKResponseModel)
        resetUI()
      })
  }

  const [screeningNameForConfirmationModal, setScreeningNameForConfirmationModal] = useState<string | null>(null)
  const [showSyncConfirmationModal, setShowSyncConfirmationModal] = useState<boolean>(false)
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false)

  const saveConfirmAlertModalProps: AlertModalProps = {
    showModal: showSyncConfirmationModal,
    setShowModal: setShowSyncConfirmationModal,
    showProgress: syncInProgress,
    showCloseButton: false,
    progressTitle: 'Sync in progress',
    AlertTitle: 'Sync Custom Index?',
    AlertContent: `Report ${screeningNameForConfirmationModal} successfully saved. Would you like to Sync the Custom Index associated with ${screeningNameForConfirmationModal}? This will result in the Custom Index being updated and regenerated. Do you want to proceed?`,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'Cancel',
        onClick: () => {
          resetUI()
        },
        isPrimary: false,
      },
      {
        type: AlertButtonType.OK,
        display: 'Yes',
        onClick: () => {
          syncCustomIndexMembers()
        },
        isPrimary: true,
      },
    ],
    onAlertClose: () => {
      return true
    }
  }

  const saveScreenReport = (screeningName: string, description: string) => {
    if (saveScreenReportInProgress) {
      GFDToastInfo('Previous save screen report request is in progress')
      return
    }

    setSaveScreenReportInProgress(true)
    const request: SaveScreenStatisticsRequest = {
      universe: {
        countryOfListing: famaValueProps.screeningStatistics.universe.countryOfListing,
        countryOfOperation: famaValueProps.screeningStatistics.universe.countryOfOperation,
        indices: famaValueProps.screeningStatistics.universe.indices,
        industry: famaValueProps.screeningStatistics.universe.industry,
        sector: famaValueProps.screeningStatistics.universe.sector
      },
      factors: famaValueProps.screeningStatistics.factors,
      screenOnDate: famaValueProps.screeningStatistics.screenOnDate,
      screenID: famaValueProps.screeningID ?? 0,
      name: screeningName,
      description: description,
      totalPassed: famaValueProps.screeningStatistics.totalPassed
    }

    saveScreenResults(request).then(
      () => {
        if (famaStateProps?.customIndexID) {
          setSaveScreenReportInProgress(false)
          setShowSaveModal(false)
          setScreeningNameForConfirmationModal(screeningName)
          setShowSyncConfirmationModal(true)
        } else {
          GFDToastSuccess(`Report ${screeningName} saved`)
          resetUI()
        }
      },
      (notOKResponseModel: NotOKResponseModel | null) => {
        setSaveScreenReportInProgress(false)
        setErrorResponse(notOKResponseModel)
      })
  }

  const onFinalWorkbookSelection = (returnValue: boolean, workbook: string) => {
    setSelectedWorkbook(workbook)
    setDoAddToWorkbook(returnValue)
  }

  const executeAddToWorkbook = () => {
    if (!doAddToWorkbook
      || !selectedWorkbook
      || !selectedTickerIDs
      || !selectedTickerIDs?.length) return

    addTickersToWorkbookByID({ workbookName: selectedWorkbook, tickerIDs: selectedTickerIDs })
      .then((result: any) => {
        if (result?.messages?.length) {
          const notOKResponseModel: NotOKResponseModel = {
            isBackgroundCall: false,
            statusCode: 200,
            notOKResponse: {
              internal: false,
              success: true,
              logOut: false,
              messages: result?.messages
            }
          }
          setErrorResponse(notOKResponseModel)
        }
      },
        //Reject promise
        (notOKResponseModel: NotOKResponseModel) => {
          setErrorResponse(notOKResponseModel)
        })
  }

  const processAddToWorkbook = (data: any) => {
    if (!data || !data?.length) return

    const tickerIDs = Object.values(data).map((d: any) => d?.id)
    setSelectedTickerIDs(tickerIDs)

    if (tickerIDs.length === 0) return
    if (tickerIDs.length > AppConfigurations.maximumTickers) {
      setTickerLimitAlertMessage(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. ${tickerIDs.length} series have been selected. Please try with ${AppConfigurations.maximumTickers} or less.`)
      setShowTickerLimitModal(true)
      return
    }

    setShowWorkbookSelectionModal(true)
  }

  const processDownload = (data: any) => {
    if (!data || !data?.length) return

    const tickers: TickerData[] = Object.values(data)
    setTickersToDownload(tickers)
    setTriggerProcessDownload(true)
  }

  const processExport = (selectedSeries: number[] | null) => {
    if (!lastScreenRequest) {
      return
    }

    const exportRequest: ExportScreenResults = {
      screeningRequest: lastScreenRequest,
      seriesIDs: selectedSeries || null,
      screeningReportName: famaValueProps.screeningName
    }

    setExportInProgress(true)
    exportScreenResults(exportRequest).then(
      (fileCreateResponse: CreateDataFileResponse) => {
        const downloadRequest: FileDownloadRequest = {
          filename: fileCreateResponse.filename,
          mimeType: fileCreateResponse.mimeType
        }
        downloadFileFromServer(downloadRequest, setErrorResponse)
        setExportInProgress(false)
      },
      (notOKResponseModel: NotOKResponseModel | null) => {
        setErrorResponse(notOKResponseModel)
        setExportInProgress(false)
      }
    )
  }

  const handleSelectedExportButton = (selectedData: any[]) => {
    if (!selectedData || selectedData.length === 0) {
      return
    }
    const selecteSeriesIDs: number[] = selectedData.map((value: any) => value.seriesID)
    processExport(selecteSeriesIDs)
  }

  const handleExportAllButton = () => {
    gaLogEvent('Clicked on Tools Fama French Results Export All Button', gaFinaeonWebAppEventCategories.Tools, 'handleExportAllButton')
    processExport(null)
  }

  const renderExportAllButton = (): JSX.Element => {
    if (!data || data.length === 0) {
      return <></>
    }

    return (
      <>
        <Tooltip title={'Export All Fama French Results'} >
          <span>
            <IconButton
              disabled={exportInProgress}
              aria-label='Export All Fama French Results'
              onClick={handleExportAllButton}
            >
              <ExporttoList
                style={{ fontSize: '1.25em' }}
              />
            </IconButton>
          </span>
        </Tooltip>
        {exportInProgress && (
          <IconButton aria-label='Export in Progress' component='label' sx={{
            p: '10px',
            color: '#1976d2',
            visibility: 'visible'
          }}>
            <CircularProgress title='Export in Progress' aria-label='Export in Progress' />
          </IconButton>
        )}
      </>
    )
  }

  const saveModalProps = {
    handleSuccess: handleSuccessSaveModal,
    handleClose: closeSaveReportModal,
    saveScreenReportInProgress,
    showModal: showSaveModal,
    saveScreenReport: saveScreenReport,
    famaValueProps: famaValueProps
  }

  const renderDownloadAction = () => {
    const downloadActionProps: DownloadActionProps = {
      showButton: false,
      downloadTriggerProps: {
        triggerProcessDownload,
        resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
        resetRowData: () => setTickersToDownload(null)
      },
      isComposite: false,
      rowData: tickersToDownload,
      maxTickerLimit: AppConfigurations.maximumTickers || 300,
      checkIsTrial,
      signOut: signOut
    }
    return <DownloadAction {...downloadActionProps} />
  }

  const messageToggleProps = {
    clearMessagesToggle,
    clearMessages: () => {
      setClearMessagesToggle(!clearMessagesToggle)
    }
  }

  const workbookSelectionModalProps: WorkbookSelectionModalProps = {
    userInfo,
    onFinal: onFinalWorkbookSelection,
    showModal: showWorkbookSelectionModal,
    setShowModal: setShowWorkbookSelectionModal,
    signOut,
    ...messageToggleProps
  }

  const alertTickerLimitModalProps: AlertModalProps = {
    showModal: showTickerLimitModal,
    setShowModal: setShowTickerLimitModal,
    AlertTitle: 'Too many series',
    AlertContent: tickerLimitAlertMessage,
    AlertButtons: [
      {
        type: AlertButtonType.Cancel,
        display: 'OK',
        isPrimary: true
      }
    ],
    onAlertClose: () => {
      return true
    }
  }

  useEffect(() => {
    if (table.tableData.length > 0) {
      const resWithExtraData: any[] = spreadAddKeyPropertiesToData(table.tableData)
      setData(resWithExtraData)
    } else {
      setData([])
    }
  }, [table.tableData])

  useEffect(() => {
    if (doAddToWorkbook) {
      executeAddToWorkbook()
      setDoAddToWorkbook(false)
    }
  }, [doAddToWorkbook])

  useEffect(() => {
    if (data && data.length > 0) {
      if (data[0]?.entityIDs) {
        setAvailableEntitiyIDs(data[0].entityIDs)
      } else {
        setAvailableEntitiyIDs([])
      }
    }
  }, [data])

  const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
  const [sortColumn, setSortColumn] = useState<string>('')
  const sortedData: any[] = useMemo(
    () =>
      sort(data, getComparator(sortOrder, sortColumn)),
    [sortOrder, sortColumn, data],
  )

  return (
    <div>
      <span style={{ fontStyle: 'italic' }}><p>{famaValueProps.screeningName ? 'Screening Report: ' + famaValueProps.screeningName : ''}</p></span>
      <div style={{ width: '100%', height: '100%' }}>
        <ThemeProvider theme={DefaultMaterialTheme}>
          <MaterialTable
            columns={columns}
            data={sortedData}
            title={<Typography variant='h6'>Fama-French Screening Results <span>{renderExportAllButton()}</span></Typography>}
            options={{
              ...MaterialTableOptions,
              maxBodyHeight: (window.innerHeight - 500)
            }}
            actions={[
              {
                tooltip: 'Add to Workbook',
                icon: () => <AddtoWorkbook style={{ fontSize: '1.25em' }} />,
                onClick: (evt, data) => {
                  gaLogEvent('Clicked on Tools Fama French Add to Workbook Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French Results Add To Workbook')
                  processAddToWorkbook(data)
                }
              },
              {
                tooltip: 'Download Selected Series',
                icon: () => <DownLoadGreen style={{ fontSize: '1.25em' }} />,
                onClick: (evt, data) => {
                  gaLogEvent('Clicked on Tools Fama French Download Selected Series Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French Results Download Selected Series')
                  processDownload(data)
                }
              },
              {
                tooltip: 'Export Selected',
                icon: () => <ExporttoList style={{ fontSize: '1.25em' }} />,
                onClick: (evt, data) => {
                  gaLogEvent('Clicked on Tools Fama French Export Selected Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French Results Export Selected')
                  handleSelectedExportButton(data)
                }
              }
            ]}
            components={{
              Header:
                props => (
                  <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
                )
            }}
          />
        </ThemeProvider>
      </div>

      <div style={{ paddingTop: 10 }}>
        <Grid container columns={16}>
          <Grid item md={4}>
            <BtnClearReset id='resetAllResultButton' variant='outlined' onClick={showResetConfirmation}>Reset All</BtnClearReset>
            <BtnClearReset id='previousResultsButton' variant='outlined' style={{ marginLeft: 5 }} onClick={goToFamaScreening}>Previous</BtnClearReset>
          </Grid>
          <Grid item md={8}>
          </Grid>
          <Grid item md={4}>
            <CustomBtnBlue
              id='saveReportButton'
              title='Save Screening Factors and Criteria'
              style={{ display: 'inline-block', marginLeft: '15px' }}
              variant='contained'
              onClick={openSaveReportModal}
              startIcon={<SaveIcon />}
            >
              Save Report
            </CustomBtnBlue>
          </Grid>
        </Grid>
      </div>

      {showSaveModal ? <FamaSaveModal modalProps={saveModalProps} /> : <></>}
      <WorkbookSelectionModal {...workbookSelectionModalProps} />
      {renderDownloadAction()}
      <AlertModal {...alertTickerLimitModalProps} />
      {showSyncConfirmationModal && <AlertModal {...saveConfirmAlertModalProps} />}
    </div>
  )
}

export default FamaFrenchResults
