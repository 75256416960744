import Header, { HeaderProps } from './Components/Header/Header'
import Login, { LoginProps } from './Components/Login/Login'
import SearchHome, { HomeProps } from './Components/Search/SearchHome'
import './App.css'
import Account, { AccountProps } from './Components/Account/Account'
import AutoTrac, { AutoTracProps } from './Components/AutoTrac/AutoTrac'
import Admin, { AdminProps } from './Components/Admin/Admin'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import FinancialTools, { FinancialToolsProps } from './Components/FinancialTools/FinancialTools'
import { useEffect, useState } from 'react'
import { pathParts, paths } from './Models/DataModels/Common/RedirectionModel'
import GFDToastContainer from './Components/Common/Utility/GFDToastify'
import Register, { RegisterProps } from './Components/Register/Register'
import ResetForgotPassword, { ResetForgotPasswordProps } from './Components/ResetPassword/ResetForgotPassword'
import SignalRNotification, { SignalRNotificationProps } from './Components/Common/Utility/SignalRNotification'
import { isAnonymous, UserInfo } from './Models/DataModels/Common/UserInfoModel'
import { NotificationHubUser } from './Models/DataModels/SignalR/NotificationHubUser'
import { getAuthExpirationFromCookie, getAuthTokenFromCookie, getUserInfoFromCookie, removeAllTokensFromCookie, setAuthTokenToCookie, setRefreshTokenToCookie, setUserInfoToCookie } from './Services/CookieAccessService'
import { SignalRMessageRequest, SignalRSignOutRequest } from './Models/DataModels/SignalR/NotificationHubRequests'
import { LogoutReasonType, LogoutRequest } from './Models/DataModels/Requests/AuthRequests'
import { logoutUser } from './Services/AuthenticationService'
import { LoginResponse, RefreshResponse } from './Models/DataModels/Responses/AuthResponses'
import moment from 'moment'
import ForgotPassword, { ForgotPasswordProps } from './Components/ResetPassword/ForgotPassword'
import Help, { HelpProps } from './Components/Help/Help'
import Graph, { GraphProps } from './Components/GraphAndChartResults/Graph'
import SearchResultsChartDisplay, { SearchResultsChartDisplayProps } from './Components/Search/Charts/SearchResultsChartDisplay'
import SessionRefreshHandler, { SessionRefreshHandlerProps } from './Components/Common/Modals/SessionTimer/SessionRefreshHandler'
import APIashx from './Components/API/APIashx'
import Bulkashx from './Components/API/Bulkashx'
import { AppConfigurations, ConfigKeys } from './Models/DataModels/Common/AppConfigurationsModel'
import SiteNotification from './Components/Maintenance/SiteNotification'
import MaintenanceMode from './Components/Maintenance/MaintenanceMode'
import FinaeonGoogleAnalytics from './Components/Google/FinaeonGoogleAnalytics'
import { initGA } from './Components/Google/analytics'

function App() {

  const [configData, setConfigData] = useState<any | null>(null)
  const [maintenanceMode, setMaintenanceMode] = useState<boolean>(false)
  const [maintenanceModeMessage, setMaintenanceModeMessage] = useState<any | null>(null)
  const [siteNotificationMessage, setSiteNotificationMessage] = useState<any | null>(null)
  const [loginTime, setLoginTime] = useState<string>('')
  const [loggedIn, setLoggedIn] = useState<boolean>()
  const [userInfo, setUserInfo] = useState<UserInfo | null>(getUserInfoFromCookie() || null)
  const [activeUsers, setActiveUsers] = useState<NotificationHubUser[]>([])
  const [authToken, setAuthToken] = useState<string | null>(getAuthTokenFromCookie())
  const [signalRMessageRequest, setSignalRMessageRequest] = useState<SignalRMessageRequest | null>()
  const [signalRSignOutRequest, setSignalRSignOutRequest] = useState<SignalRSignOutRequest | null>()
  const [showSaved, setShowSaved] = useState<boolean>(false)

  const login = (response: LoginResponse) => {
    setAuthTokenToCookie(response.token)
    setRefreshTokenToCookie(response.refresh)
    setUserInfoToCookie(response.user)

    setAuthToken(response.token || null)
    setUserInfo(response.user || null)

    const timeNow = moment().format('YYYY-MM-DD HH:mm:ss')
    setLoginTime(timeNow)
    setLoggedIn(true)
  }

  const processRefreshTokenResponse = (response: RefreshResponse) => {
    removeAllTokensFromCookie()

    setAuthTokenToCookie(response.token)
    setRefreshTokenToCookie(response.refresh)
    setUserInfoToCookie(response.user)

    setAuthToken(response.token || null)
    setUserInfo(response.user || null)
  }

  const signOut = (logoutReason: LogoutReasonType) => {
    setLoggedIn(false) // set this first to deregister user
    const currentToken: string | null = getAuthTokenFromCookie()
    const currentTokenExpiration: Date | null = getAuthExpirationFromCookie()
    if (currentToken && currentTokenExpiration && new Date() < currentTokenExpiration) {
      logoutUser({ logoutReason } as LogoutRequest)
    }
    removeAllTokensFromCookie()
    setAuthToken(null)
    setUserInfo(null)
    setShowSaved(false)
  }

  const checkIsManager = () => {
    return userInfo?.specialCategory?.trim()?.toLowerCase() === 'manager'
  }

  const checkIsAnonymous = () => {
    return isAnonymous(userInfo)
  }

  const checkIsTrial = () => {
    return userInfo?.isTrialUser ?? true
  }

  const checkIsBillable = () => {
    return userInfo?.isBillable ?? false
  }

  const signalRFields = {
    activeUsers: activeUsers,
    setActiveUsers: setActiveUsers,

    signalRMessageRequest: signalRMessageRequest,
    setSignalRMessageRequest: setSignalRMessageRequest,

    signalRSignOutRequest: signalRSignOutRequest,
    setSignalRSignOutRequest: setSignalRSignOutRequest
  }

  const headerProps: HeaderProps = {
    login,
    userInfo,
    checkIsManager,
    checkIsAnonymous,
    checkIsTrial,
    signOut,
    setShowSaved
  }

  const loginProps: LoginProps = {
    login: login,
    signOut: signOut
  }

  const registerProps: RegisterProps = {
    signOut: signOut
  }

  const forgotPasswordProps: ForgotPasswordProps = {
    signOut: signOut
  }

  const resetForgotPasswordProps: ResetForgotPasswordProps = {
    signOut: signOut
  }

  const signalRNotificationProps: SignalRNotificationProps = {
    loggedIn: loggedIn,
    loginTime: loginTime,
    signOut: signOut,

    ...signalRFields,

    authToken,
    userInfo: userInfo,
  }

  const adminProps: AdminProps = {
    ...signalRFields,

    authToken,
    login: login,
    signOut: signOut,
    processRefreshTokenResponse
  }

  const searchHomeProps: HomeProps = {
    userInfo,
    checkIsTrial,
    checkIsAnonymous,
    signOut,
    showSaved,
    setShowSaved,
    processRefreshTokenResponse
  }

  const financialToolsBaseProps: FinancialToolsProps = {
    userInfo,
    checkIsTrial,
    signOut: signOut,
    processRefreshTokenResponse
  }

  const sessionRefreshHandlerProps: SessionRefreshHandlerProps = {
    processRefreshTokenResponse,
    signOut: signOut
  }

  const autoTracProps: AutoTracProps = {
    userInfo,
    checkIsTrial,
    signOut,
    processRefreshTokenResponse
  }

  const graphAndChartProps: GraphProps = {
    checkIsTrial: checkIsTrial,
    signOut: signOut,
    processRefreshTokenResponse
  }

  const searchResultsChartDisplayProps: SearchResultsChartDisplayProps = {
    checkIsTrial: checkIsTrial,
    signOut: signOut,
    processRefreshTokenResponse
  }

  const accountProps: AccountProps = {
    checkIsBillable,
    checkIsAnonymous,
    signOut,
    processRefreshTokenResponse
  }

  const helpProps: HelpProps = {
    signOut,
    processRefreshTokenResponse
  }

  useEffect(() => {
    fetch('/config.json', {
      headers: new Headers({
        'Cache-Control': 'no-store'
      })
    })
      .then(response => response.json())
      .then(data => setConfigData(data))
      .catch(error => console.error('Error fetching the config.json file:', error));
  }, [])

  useEffect(() => {
    if (configData) {
      const maintenanceMode: boolean = configData[ConfigKeys.maintenanceMode] || false
      setMaintenanceMode(maintenanceMode)
      const maintenanceModeMessage: string | null = configData[ConfigKeys.maintenanceModeMessage] || null
      setMaintenanceModeMessage(maintenanceModeMessage)
      const siteNotificationMessage: string | null = configData[ConfigKeys.siteNotificationMessage] || null
      setSiteNotificationMessage(siteNotificationMessage)
    }
  }, [configData])

  useEffect(() => {
    initGA()
  }, [])

  return (
    <div className='App'>
      <link
        rel='stylesheet'
        href='https://fonts.googleapis.com/icon?family=Material+Icons'
      />

      {maintenanceMode ? <MaintenanceMode maintenanceMode={maintenanceMode} maintenanceModeMessage={maintenanceModeMessage} /> : (
        <BrowserRouter>
          <FinaeonGoogleAnalytics />
          <SiteNotification siteNotificationMessage={siteNotificationMessage} />
          <Header {...headerProps} />
          <Routes>
            <Route path={paths.login} element={<Login {...loginProps} />} />
            <Route path={paths.autoTrac} element={<AutoTrac {...autoTracProps} />} />
            <Route path={paths.forgotPassword} element={<ForgotPassword {...forgotPasswordProps} />} />
            <Route path={paths.resetForgotPassword} element={<ResetForgotPassword {...resetForgotPasswordProps} />} />

            {/* Home */}
            <Route path={paths.searchHome.base} element={<SearchHome {...searchHomeProps} />}>
              <Route path={pathParts.search.searchSingle + ':engine'} element={<SearchHome {...searchHomeProps} />} />
              <Route path={'/:searchSection'} element={<SearchHome {...searchHomeProps} />} />
            </Route>

            {/* Account */}
            <Route path={paths.account.base} element={<Account {...accountProps} />}>
              <Route path=':accountSection' element={<Account {...accountProps} />} />
            </Route>

            {/* Tools */}
            <Route path={paths.financialTools.base} element={<FinancialTools {...financialToolsBaseProps} />}>
              <Route path=':tool' element={<FinancialTools {...financialToolsBaseProps} />} >
                <Route path=':expandedScreenID' element={<FinancialTools {...financialToolsBaseProps} />} />
              </Route>
            </Route>

            <Route path={paths.admin.base} element={<Admin {...adminProps} />}>
              <Route path=':adminSection' element={<Admin {...adminProps} />} />
            </Route>

            {/* Graph */}
            <Route path={paths.graph.base}>
              <Route path={pathParts.graph.lineGraph} element={<Graph {...graphAndChartProps} />}>
                <Route path=':symbol' element={<Graph {...graphAndChartProps} />} />
              </Route>
              <Route path={pathParts.graph.barChart} element={<SearchResultsChartDisplay {...searchResultsChartDisplayProps} />} />
              <Route path={pathParts.graph.pieChart} element={<SearchResultsChartDisplay {...searchResultsChartDisplayProps} />} />
            </Route>

            <Route path={paths.help.base} element={<Help {...helpProps} />}>
              <Route path=':helpSection' element={<Help {...helpProps} />}>
                <Route path=':helpSubSection' element={<Help {...helpProps} />} />
              </Route>
            </Route>

            <Route path={paths.register} element={<Register {...registerProps} />} />

            <Route path={paths.api.base}>
              <Route path={pathParts.api.api} element={<APIashx />} />
              <Route path={pathParts.api.bulk} element={<Bulkashx />} />
            </Route>

          </Routes>
          <SessionRefreshHandler {...sessionRefreshHandlerProps} />
          <GFDToastContainer />
          <SignalRNotification {...signalRNotificationProps} />
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
