import { CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { CSSProperties, useEffect, useState } from 'react'
import FamaEditModal from '../../../../Common/Modals/FamaEditModal'
import { CriteriaData, Factor, RankData, ScreenStatisticsRequest, SortData } from '../../../../../Models/DataModels/Requests/ScreeningRequests'
import { getScreenResults } from '../../../../../Services/ScreeningService'
import Edit from '../../../../Icons/EditIcon'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import moment from 'moment'
import { CustomBtnBlue, BtnClearReset } from '../../../../Common/GlobalSettings/CustomStyles'
import { errorRedColor, globalStyles } from '../../../../Common/GlobalSettings/GlobalStyles'
import { NotOKResponseModel } from '../../../../../Models/DataModels/Common/NotOKResponseModel'
import { GFDToastError } from '../../../../Common/Utility/GFDToastify'
import { AppConfigurations } from '../../../../../Models/DataModels/Common/AppConfigurationsModel'
import { formatNumberEnUSLocalString } from '../../../../../Services/UtilityService'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../../../Google/analytics'

const FamaFrenchScreeningTable = ({ famaProps, famaValueProps, table, showResetConfirmation, setLastScreenRequest, setErrorResponse }: any) => {

  //Modal Values
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<any>()
  const [modalDescription, setModalDescription] = useState<string>()
  const [totalPassed, setTotalPassed] = useState<number>(0)

  const [totalPassedHelperText, setTotalPassedHelperText] = useState<string>('')
  const [totalPassedHasError, setTotalPassedHasError] = useState<boolean>(false)

  const [fetchResultsInProgress, setFetchResultsInProgress] = useState<boolean>(false)

  const generateFactorsOnEdit = (editedCriteriaId: number, newMinValue: number, newMaxValue: number, rankValues: any, sortValues: any) => {
    if (editedCriteriaId) {
      if (editedCriteriaId === 1778) {
        famaValueProps.marketCap.setMarketCapStart(newMinValue)
        famaValueProps.marketCap.setMarketCapEnd(newMaxValue)
      }
      if (editedCriteriaId === 1786) {
        famaValueProps.ratio.setRatioStart(newMinValue)
        famaValueProps.ratio.setRatioEnd(newMaxValue)
      }
      if (editedCriteriaId === 1812) {
        famaValueProps.beta.setBetaStart(newMinValue)
        famaValueProps.beta.setBetaEnd(newMaxValue)
      }
      if (editedCriteriaId === 1782) {
        famaValueProps.dividend.setDividendYieldStart(newMinValue)
        famaValueProps.dividend.setDividendYieldEnd(newMaxValue)
      }
      if (editedCriteriaId === 1800) {
        famaValueProps.movement.setM3Start(newMinValue)
        famaValueProps.movement.setM3End(newMaxValue)
      }
      if (editedCriteriaId === 1804) {
        famaValueProps.movement.setM12Start(newMinValue)
        famaValueProps.movement.setM12End(newMaxValue)
      }
      if (editedCriteriaId === 1808) {
        famaValueProps.movement.setM36Start(newMinValue)
        famaValueProps.movement.setM36End(newMaxValue)
      }
    }

    if (editedCriteriaId) {
      const criteria: CriteriaData = {
        entityID: editedCriteriaId,
        min: newMinValue,
        max: newMaxValue
      }

      const rank: RankData =
        Object.keys(rankValues).length > 0 ? {
          type: rankValues?.type ?? null,
          rankBy: rankValues?.rankBy ?? null,
          rankValue: rankValues?.rankValue ?? null,
          usePctChg: rankValues?.usePctChg ?? false,
          pctChgStart: rankValues?.pctChgStart ?? null,
          pctChgEnd: rankValues?.pctChgEnd ?? null
        } : {
          type: 'None'
        }

      const sort: SortData =
        Object.keys(sortValues).length > 0 ? {
          level: sortValues?.level ?? null,
          order: sortValues?.order ?? 'None'
        } : {}

      const factor: Factor = {
        criteria: criteria,
        rank: rank,
        sort: sort
      }
      let editArray = famaValueProps.screeningStatistics.factors
      let searchIndex: any = -1

      editArray.forEach((item: any, index: any) => {
        // reset existing sort 
        if (sortValues?.level && sortValues?.level === item.sort?.level) {
          item.sort.level = null
          item.sort.order = 'None'
        }
        if (item.criteria.entityID === editedCriteriaId) {
          searchIndex = index
        }
      })

      if (searchIndex !== -1) {
        if (!newMinValue && !newMaxValue) {
          editArray = editArray.filter((x: any, index: any) => index !== searchIndex)
        } else {
          editArray[searchIndex] = factor
        }
      }
      famaValueProps.setArrayOfFactors(editArray)
    }
  }

  const handleSuccess = (result: any) => {
    let rankObject: any
    let sortObject: any

    if (result.rank !== null) {
      rankObject = {
        type: result.rank,
        rankBy: result.rankType,
        rankValue: result.rankValue,
        usePctChg: result.usePctChg,
        pctChgStart: result.rankRangeStart,
        pctChgEnd: result.rankRangeEnd
      }
    } else {
      rankObject = {}
    }
    if (result.sortType !== null) {
      sortObject = {
        level: result.sortType,
        order: result.sortOrder
      }
    } else {
      sortObject = {}
    }

    generateFactorsOnEdit(result.entityID, result.modalMinValue, result.modalMaxValue, rankObject, sortObject)
    setShowModal(false)
  }

  const validateTotalPassed = (totalPassed: any) => {
    if (totalPassed === 0) {
      setTotalPassedHasError(true)
      setTotalPassedHelperText('Total passed must be greater than 0')
      return true
    }
    if (totalPassed > (AppConfigurations.maximumCustomIndexMembers || 100)) {
      setTotalPassedHasError(true)
      setTotalPassedHelperText(`Total passed must be less than or equal to ${AppConfigurations.maximumCustomIndexMembers || 100}`)
      return true
    }
    setTotalPassedHasError(false)
    setTotalPassedHelperText('')

    return false
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const modalProps = {
    showModal: showModal,
    setShowModal: setShowModal,
    handleClose: handleClose,
    handleSuccess: handleSuccess,
    setModalDescription: setModalDescription,
    setModalData: setModalData,
    modalDescription: modalDescription,
    modalData: modalData
  }

  const createDateRangeString = (usePercentChange: any, startDateObject: any, endDateObject: any): string => {
    if (usePercentChange && startDateObject && endDateObject) {
      return `${startDateObject}-${endDateObject}`
    }
    return ''
  }

  const setScreeningStats = () => {
    let arrayOfStats: any = []
    if (famaValueProps.screeningStatistics.factors) {
      famaValueProps.screeningStatistics.factors.forEach((item: any) => {
        let dateStart
        let dateEnd
        if (item.rank.pctChgEnd && item.rank.pctChgStart) {
          dateStart = moment(Date.parse(item.rank.pctChgStart)).format('L')
          dateEnd = moment(Date.parse(item.rank.pctChgEnd)).format('L')
        }
        arrayOfStats.push(
          <TableRow
            key={item.criteria.entityID}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              <IconButton title='Edit' aria-label='Example' onClick={() => showEditModal(item)}>
                <Edit style={{ fontSize: '1.2em' }} />
              </IconButton>
            </TableCell>
            <TableCell component='th' scope='item'>
              {item.criteria.description}
            </TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.min)}</TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.max)}</TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.low)}</TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.high)}</TableCell>
            <TableCell align='right'>{item.rank.type === 'None' ? 'None' : (item.rank.type || '') + ' ' + (item.rank.rankValue || '') + ' ' + (item.rank.rankBy || '') + ' ' + (createDateRangeString(item.rank.usePctChg, dateStart, dateEnd) || '')}</TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.total)}</TableCell>
            <TableCell align='right'>{formatNumberEnUSLocalString(item.criteria.passed)}</TableCell>

            <TableCell align='right'>{!item.sort.level || item.sort.order === 'None' ? 'None'
              :
              (item.sort.level === 1 ? 'Primary' : 'Secondary') + ' ' + (item?.sort?.order || '')}
            </TableCell>
          </TableRow>
        )
      })
    }
    return arrayOfStats
  }

  const goToFamaInital = () => {
    gaLogEvent('Clicked on Tools Fama French Previous Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French goToFamaInital')
    famaProps.setFamaInital(true)
    famaProps.setFamaScreening(false)
  }
  const goToFamaResults = () => {
    gaLogEvent('Clicked on Tools Fama French Display Results Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French goToFamaResults')
    const request: ScreenStatisticsRequest = {
      universe: {
        countryOfListing: famaValueProps.screeningStatistics.universe.countryOfListing,
        countryOfOperation: famaValueProps.screeningStatistics.universe.countryOfOperation,
        indices: famaValueProps.screeningStatistics.universe.indices,
        industry: famaValueProps.screeningStatistics.universe.industry,
        sector: famaValueProps.screeningStatistics.universe.sector
      },
      factors: famaValueProps.screeningStatistics.factors,
      screenOnDate: famaValueProps.screeningStatistics.screenOnDate
    }

    setFetchResultsInProgress(true)
    getScreenResults(request).then(
      (res: any) => {
        table.setTableData(res?.passedItems)
        famaProps.setFamaResults(true)
        famaProps.setFamaScreening(false)
        setLastScreenRequest(request)
        setFetchResultsInProgress(false)
      },
      //Reject promise
      (notOKResponseModel: NotOKResponseModel) => {
        setLastScreenRequest(null)
        setFetchResultsInProgress(false)
        GFDToastError('Request failed')
        setErrorResponse(notOKResponseModel)
      })
  }
  const showEditModal = (item: any) => {
    modalProps.setModalDescription('Edit ' + item.criteria.description + ' Options: ')
    modalProps.setModalData(item)
    modalProps.setShowModal(true)
  }

  useEffect(() => {
    validateTotalPassed(famaValueProps.screeningStatistics.totalPassed)
    setTotalPassed(famaValueProps.screeningStatistics.totalPassed)
  }, [])

  useEffect(() => {
    validateTotalPassed(famaValueProps.screeningStatistics.totalPassed)
    setTotalPassed(famaValueProps.screeningStatistics.totalPassed)
  }, [famaValueProps.screeningStatistics.totalPassed])


  const readOnlyProp: CSSProperties = (famaValueProps.screeningInProgress ?? false) || fetchResultsInProgress ? { pointerEvents: 'none' } : {}

  const disableDisplayResults = totalPassed <= 0 || totalPassed > 300

  return (
    <div>
      <h3>Criteria</h3>
      <Typography style={{ fontStyle: 'italic', marginTop: '8px', marginBottom: '8px', marginRight: '16px' }}>
        {famaValueProps.screeningName ? 'Screening Report: ' + famaValueProps.screeningName : ''}
        <IconButton
          title='Screening in Progress'
          aria-label='Screening in Progress'
          component='label' sx={{
            p: '10px',
            color: '#1976d2',
            visibility: famaValueProps.screeningInProgress ? 'visible' : 'hidden'
          }}>
          <CircularProgress title='Screening in Progress' aria-label='Screening in Progress' />
        </IconButton>
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, ...readOnlyProp }} size='small' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align='right'>Min</TableCell>
              <TableCell align='right'>Max</TableCell>
              <TableCell align='right'>Low</TableCell>
              <TableCell align='right'>High</TableCell>
              <TableCell align='right'>Ranking</TableCell>
              <TableCell align='right'>Total</TableCell>
              <TableCell align='right'>Passed</TableCell>
              <TableCell align='right'>Sort</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {setScreeningStats()}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container columns={12} style={{ marginTop: '10px', ...readOnlyProp }} >
        <Grid item md={6}>
          <BtnClearReset id='resetAllScreenigButton' variant='outlined' onClick={() => {
            gaLogEvent('Clicked on Tools Fama French Reset All Button', gaFinaeonWebAppEventCategories.Tools, 'Fama French resetAllScreenigButton')
            showResetConfirmation()
          }}>Reset All</BtnClearReset>
          <BtnClearReset id='previousScreningButton' variant='outlined' style={{ marginLeft: 5 }} onClick={goToFamaInital}>Previous</BtnClearReset>
        </Grid>
        <Grid item md={2} sx={famaTableScreenStyles.alignRight}>
          {totalPassedHasError ?
            <ul>
              <li style={{ color: errorRedColor }}><span style={{ color: errorRedColor }}>{totalPassedHelperText}</span></li>
            </ul> : <></>}
        </Grid>
        <Grid item md={2} sx={famaTableScreenStyles.alignRight}>
          <p style={{ paddingRight: 2, fontWeight: 'bold' }}>Total Passed: {totalPassed}</p>
          <Tooltip title='Total passed is the total number of records resulting from the screening criteria.  If you change your screening criteria this number will change, you need at least 1 total passed in order to use the rest of the screener.'>
            <HelpOutlineIcon sx={{ fill: '#8e95ce', fontSize: '18px !important' }} />
          </Tooltip>
        </Grid>
        <Grid item md={2} sx={famaTableScreenStyles.alignRight} >
          <CustomBtnBlue variant='contained' onClick={goToFamaResults} disabled={disableDisplayResults} style={disableDisplayResults ? globalStyles.disabledButton : {}}>Display Results</CustomBtnBlue>
          <IconButton title='Request in Progress'
            aria-label='Request in Progress' component='label' sx={{
              p: '10px',
              color: '#1976d2',
              visibility: fetchResultsInProgress ? 'visible' : 'hidden'
            }}>
            <CircularProgress title='Request in Progress' aria-label='Request in Progress' />
          </IconButton>
        </Grid>
      </Grid>
      {showModal ? <FamaEditModal modalProps={modalProps} /> : <></>}
    </div>
  )
}

const famaTableScreenStyles = {
  alignRight: {
    display: 'flex !important',
    justifyContent: 'flex-end !important'
  },
  bottomRow: {
    display: 'flex',
    gridRow: '1 / span 2',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 5
  }
}

export default FamaFrenchScreeningTable