import { Divider, CircularProgress, IconButton, Paper, Tooltip,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { fetchRecentlyLoadedUsers } from '../../../Services/AccountService'
import { RecentlyLoadedUser, RecentlyLoadedUsersResponse } from '../../../Models/DataModels/Responses/AccountResponses'
import { NotOKResponseModel } from '../../../Models/DataModels/Common/NotOKResponseModel'
import { useEffect, useState } from 'react'
import DefaultMaterialTheme from '../../Common/GlobalSettings/DefaultMaterialTheme'
import Impersonate from '../../Icons/ImpersonateIcon'
import RefreshDownloadQueue from '../../Icons/RefreshDownloadQueueIcon'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../../Google/analytics'

export const RECENTLY_LOADED_USERS_ERROR = 'Failed to fetch recently loaded users!'

export interface RecentlyLoadedUsersCardProps {
  setErrorResponse: (error: NotOKResponseModel | null) => void,
  impersonateUser: (userID: number, name: string) => void
}

export const RecentlyLoadedUsersCard = ({
  setErrorResponse,
  impersonateUser
}: RecentlyLoadedUsersCardProps) => {
  const [recentlyLoadedUsers, setRecentlyLoadedUsers] = useState<RecentlyLoadedUser[]>([])
  const [recentlyLoadedUsersError, setRecentlyLoadedUsersError] = useState<string | null>(null)
  const [loadingRecentlyLoadedUsers, setLoadingRecentlyLoadedUsers] = useState<boolean>(false)

  const loadRecentlyLoadedUsers = () => {
    gaLogEvent('Clicked on Recently Loaded Users Button', gaFinaeonWebAppEventCategories.Admin, 'loadRecentlyLoadedUsers')
    if (loadingRecentlyLoadedUsers) return

    setRecentlyLoadedUsers([])
    setLoadingRecentlyLoadedUsers(true)

    fetchRecentlyLoadedUsers()
    .then((response: RecentlyLoadedUsersResponse) => {
      setRecentlyLoadedUsersError(null)
      setRecentlyLoadedUsers(response.recentlyLoadedUsers)
      setLoadingRecentlyLoadedUsers(false)
    },
    (notOKResponseModel: NotOKResponseModel) => {
      setRecentlyLoadedUsersError(RECENTLY_LOADED_USERS_ERROR)
      setLoadingRecentlyLoadedUsers(false)
      setErrorResponse(notOKResponseModel)
    })
  }

  useEffect(() => {
    loadRecentlyLoadedUsers()
  }, [])

  const renderRecentlyLoadedUsers = () => {
      if (loadingRecentlyLoadedUsers) {
        return <>
          <Tooltip title='Fetching Recently Loaded Users'>
            <IconButton aria-label='Fetching Recently Loaded Users' component='label' sx={{ 
              p: '10px',
              color: '#1976d2'}}
            >
              <CircularProgress aria-label='Fetching Recently Loaded Users'/>
            </IconButton>
          </Tooltip>
        </>
      }
  
      if (recentlyLoadedUsersError) {
        return <>
          <label style={styles.error}>{RECENTLY_LOADED_USERS_ERROR}</label>
        </>
      }
  
      return <>
        <ThemeProvider theme={DefaultMaterialTheme}>
          <TableContainer style={styles.tableContainer} component={Paper}>
            <Table style={styles.table} size='small' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Organization</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentlyLoadedUsers.map((row, index) => (
                  <TableRow
                    key={index}  
                  >
                    <TableCell component='th' scope='row'>
                      <Tooltip title="Impersonate">
                        <IconButton
                          sx={{position: 'static', color: '#1976d2'}}
                          onClick={() => {
                            gaLogEvent('Clicked on Impersonate Button', gaFinaeonWebAppEventCategories.Admin, 'Impersonate')
                            impersonateUser(row.userID, `${row.firstName} ${row.lastName}`)
                          }}
                        >
                          <Impersonate style={{fontSize: '1.2em'}}></Impersonate>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                    <TableCell>{`${row.organization}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ThemeProvider>
      </>
  }

  return <>
    <h5>Recently Loaded Users
      <IconButton title='Refresh Recently Loaded Users' aria-label='Refresh Recently Loaded Users' component='label' onClick={loadRecentlyLoadedUsers}>
        <RefreshDownloadQueue />
      </IconButton>
    </h5>
    <Divider sx={{ borderColor: 'black', marginTop: '8px', marginBottom: '8px' }} />
    {renderRecentlyLoadedUsers()}
  </>
}

const styles = {
    error: {
      color: '#d32f2f'
    },
    table: {
      width: 'fit-content',
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    tableContainer: {
      borderRadius: '0px'
    },
}
