import { CircularProgress, Grid, IconButton, TablePagination, Tooltip, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { CapitalizationData } from '../../../../../../Models/DataModels/Common/ToolsModel'
import MaterialTable from '@material-table/core'
import MaterialTableOptions from '../../../../../Common/GlobalSettings/MaterialTableOptions'
import WorkbookSelectionModal, { WorkbookSelectionModalProps } from '../../../../../Common/Modals/WorkbookSelectionModal'
import AlertModal, { AlertModalProps, AlertButtonType } from '../../../../../Common/Modals/AlertModal'
import { addTickersToWorkbookByName } from '../../../../../../Services/AutoTracService'
import { WorkbookTickersByNameRequest } from '../../../../../../Models/DataModels/Requests/AutoTracRequests'
import { GFDToastError, GFDToastSuccess } from '../../../../../Common/Utility/GFDToastify'
import { LogoutReasonType } from '../../../../../../Models/DataModels/Requests/AuthRequests'
import AddtoWorkbook from '../../../../../Icons/AddtoWorkbookIcon'
import ExporttoList from '../../../../../Icons/ExporttoListIcon'
import DownLoad from '../../../../../Icons/DownLoad'
import { AppConfigurations } from '../../../../../../Models/DataModels/Common/AppConfigurationsModel'
import OutputFormatValue from '../../../../../../Models/DataModels/Common/OutputFormatModel'
import { CreateDataFileRequest, FileDownloadRequest, GetCapitalizationListRequest } from '../../../../../../Models/DataModels/Requests/ToolsRequests'
import { createDownloadDataFile, exportCapitalizationList } from '../../../../../../Services/ToolsService'
import { CreateDataFileResponse } from '../../../../../../Models/DataModels/Responses/ToolsResponses'
import { downloadFileFromServer } from '../../../../../../Services/DownloadService'
import { NotOKResponseModel } from '../../../../../../Models/DataModels/Common/NotOKResponseModel'
import { getUserInfoFromCookie } from '../../../../../../Services/CookieAccessService'
import { UserInfo } from '../../../../../../Models/DataModels/Common/UserInfoModel'
import { TickerData } from '../../../../../../Models/DataModels/Responses/AutoTracResponses'
import DownloadAction, { DownloadActionProps } from '../../../../../Common/Utility/DownloadAction'
import DefaultMaterialTheme from '../../../../../Common/GlobalSettings/DefaultMaterialTheme'
import { SortOrderStringType, getComparator, sort } from '../../../../../../Models/DataModels/Common/SortModel'
import EnhancedTableHead from '../../../../../Common/Utility/EnhancedTableHead'
import ExportOutputFormatSelectionModal from '../../../../../Common/Modals/ExportOutputFormatSelectionModal'
import { DateFormatValueType } from '../../../../../../Models/DataModels/Common/DateFormatModel'
import moment from 'moment'
import { globalStyles } from '../../../../../Common/GlobalSettings/GlobalStyles'
import DownLoadGreen from '../../../../../Icons/DownLoadGreen'
import { formatNumberEnUSLocalString } from '../../../../../../Services/UtilityService'

export interface CapitalizationResultListViewProps {
    userInfo: UserInfo | null,
    listResult: CapitalizationData[],
    checkIsTrial: () => boolean,
    signOut: (logoutReason: LogoutReasonType) => void,
    setErrorResponse: (errorResponse: NotOKResponseModel | null) => void,
    currentRequest: any
}

const CapitalizationResultListView = ({
    userInfo,
    listResult,
    checkIsTrial,
    signOut,
    setErrorResponse,
    currentRequest
}: CapitalizationResultListViewProps) => {

    const mcapArray: number[] = listResult.map((element: CapitalizationData) => element.value)
    const minMCap: number = Math.min(...mcapArray)
    const maxMCap: number = Math.max(...mcapArray)

    const [variableMCapResult, setVariableMCapResult] = useState<CapitalizationData[]>(listResult)
    const [paginationDataHolder, setPaginationDataHolder] = useState<CapitalizationData[][]>([])
    const [currentMCapResult, setCurrentMCapResult] = useState<CapitalizationData[]>([])

    const [pageNumberIndex, setPageNumberIndex] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)

    const [showWorkbookSelectionModal, setShowWorkbookSelectionModal] = useState<boolean>(false)
    const [seriesLimitAlertMessage, setSeriesLimitAlertMessage] = useState(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. Please try with ${AppConfigurations.maximumTickers} series or less.`)
    const [showSeriesLimitModal, setShowSeriesLimitModal] = useState<boolean>(false)
    const [selectedWorkbook, setSelectedWorkbook] = useState<string>('')
    const [selectedSeries, setSelectedSeries] = useState<string[]>([])
    const [doAddToWorkbook, setDoAddToWorkbook] = useState<boolean>(false)

    const [clearMessagesToggle, setClearMessagesToggle] = useState(false)

    const [exportInProgress, setExportInProgress] = useState<boolean>(false)

    const [tickersToDownload, setTickersToDownload] = useState<TickerData[] | null>(null)
    const [triggerProcessDownload, setTriggerProcessDownload] = useState<boolean>(false)

    const [showExportOutputFormatModal, setShowExportOutputFormatModal] = useState<boolean>(false)

    const [tempSelectedData, setTempSelectedData] = useState<CapitalizationData[]>([])
    const [exportAllClicked, setExportAllClicked] = useState<boolean>(true)

    const renderDownloadAction = () => {
        const downloadActionProps: DownloadActionProps = {
            showButton: false,
            downloadTriggerProps: {
                triggerProcessDownload,
                resetTriggerProcessDownload: () => setTriggerProcessDownload(false),
                resetRowData: () => setTickersToDownload(null)
            },
            isComposite: false,
            rowData: tickersToDownload,
            maxTickerLimit: AppConfigurations.maximumTickers || 300,
            checkIsTrial,
            signOut: signOut
        }
        return <DownloadAction {...downloadActionProps} />
    }

    const messageToggleProps = {
        clearMessagesToggle,
        clearMessages: () => {
            setClearMessagesToggle(!clearMessagesToggle)
        }
    }

    const alertTickerLimitModalProps: AlertModalProps = {
        showModal: showSeriesLimitModal,
        setShowModal: setShowSeriesLimitModal,
        AlertTitle: 'Too many series',
        AlertContent: seriesLimitAlertMessage,
        AlertButtons: [
            {
                type: AlertButtonType.Cancel,
                display: 'OK',
                isPrimary: true
            }
        ],
        onAlertClose: () => {
            return true
        }
    }

    const processResponse = (downloadRequest: FileDownloadRequest) => {
        const onComplete = (result: boolean) => {
            if (result) GFDToastSuccess(`${downloadRequest?.filename} downloaded!`)
            setExportInProgress(false)
        }

        downloadFileFromServer(downloadRequest, processErrorResponse, onComplete)
    }

    const processErrorResponse = (notOKResponseModel: NotOKResponseModel | null) => {
        setExportInProgress(false)
        if (notOKResponseModel?.statusCode !== 499) {
            setErrorResponse(notOKResponseModel)
        }
    }
    const onFinalWorkbookSelection = (returnValue: boolean, workbook: string) => {
        setSelectedWorkbook(workbook)
        setDoAddToWorkbook(returnValue)
    }

    const executeAddToWorkbook = () => {
        if (!doAddToWorkbook
            || !selectedWorkbook
            || !selectedSeries
            || !selectedSeries?.length) return

        const addToWorkbookBySeriesRequest: WorkbookTickersByNameRequest = {
            workbookName: selectedWorkbook,
            tickerNames: selectedSeries
        }

        addTickersToWorkbookByName(addToWorkbookBySeriesRequest).then(
            (result: any) => {
                GFDToastSuccess('Added series to workbook')
            },
            //Reject promise
            () => {
                GFDToastError('Failed to add series to workbook')
            }
        )
    }

    const workbookSelectionModalProps: WorkbookSelectionModalProps = {
        userInfo,
        onFinal: onFinalWorkbookSelection,
        showModal: showWorkbookSelectionModal,
        setShowModal: setShowWorkbookSelectionModal,
        signOut,
        ...messageToggleProps
    }

    const processAddToWorkbook = (data: CapitalizationData[]) => {
        if (!data || !data?.length) return

        const series = Object.values(data).map((d: CapitalizationData) => d?.symbol)
        setSelectedSeries(series)

        if (series.length === 0) return
        if (series.length > AppConfigurations.maximumTickers) {
            setSeriesLimitAlertMessage(`A maximum of ${AppConfigurations.maximumTickers} series is allowed per workbook. ${series.length} series have been selected. Please try with ${AppConfigurations.maximumTickers} or less.`)
            setShowSeriesLimitModal(true)
            return
        }

        setShowWorkbookSelectionModal(true)
    }

    const createDownloadData = (data: CapitalizationData[], dateFormat: DateFormatValueType): string[][] => {
        const downloadDataContents: string[][] = []
        downloadDataContents.push(['value', 'date', 'filename', 'description', 'operating country', 'listing country', 'currency', 'sector', 'industry'])
        data.forEach((value: CapitalizationData) => {
            downloadDataContents.push([value.value.toString(), moment(value.date).format(dateFormat.toLocaleUpperCase()), value.symbol, value.name, value.operatingCountry, value.listingCountry, value.currency, value.sector, value.industry])
        })
        return downloadDataContents
    }

    const createExportFilename = (): string => {
        const userData: UserInfo | null = getUserInfoFromCookie()
        return `${userData?.firstName || ''}_${userData?.lastName || ''}_NavListExport_excel`.replace(/[/\\?%*:|"<>]/g, '')
    }

    const downloadDataFile = (data: CapitalizationData[], selectedDateFormat: DateFormatValueType) => {
        const dataFileRequest: CreateDataFileRequest = {
            fileName: createExportFilename(),
            fileData: createDownloadData(data, selectedDateFormat),
            outputFormat: OutputFormatValue.Excel
        }
        createDownloadDataFile(dataFileRequest).then(
            (fileCreateResponse: CreateDataFileResponse) => {
                const downloadRequest: FileDownloadRequest = {
                    filename: fileCreateResponse.filename,
                    mimeType: fileCreateResponse.mimeType
                }
                downloadFileFromServer(downloadRequest, setErrorResponse)
            },
            (error: NotOKResponseModel) => {
                setErrorResponse(error)
            }
        )
    }

    const processExportResultToList = (selectedDateFormat: DateFormatValueType) => {
        downloadDataFile(tempSelectedData, selectedDateFormat)
    }

    const processDownload = (data: CapitalizationData[]) => {
        if (!data || !data?.length) return


        const tickers: TickerData[] = data.map((d: CapitalizationData) => {
            const ticker: TickerData = {
                id: d.seriesID,
                symbol: d.symbol,
                description: d.name,
                dataCount: 0
            }
            return ticker
        })
        setTickersToDownload(tickers)
        setTriggerProcessDownload(true)
    }

    const exportCapitalizationResults = (outputDateFormat: DateFormatValueType) => {

        const getCapListRequest: GetCapitalizationListRequest = {
            ...currentRequest,
            dateFormat: outputDateFormat
        }

        setExportInProgress(true)
        exportCapitalizationList(getCapListRequest).then((res: any) => {
            const downloadRequest: FileDownloadRequest = {
                filename: res.filename,
                mimeType: res.mimeType
            }
            downloadFileFromServer(downloadRequest, setErrorResponse)
            setExportInProgress(false)
        },
            (error: NotOKResponseModel) => {
                setExportInProgress(false)
                setErrorResponse(error)
            })
    }

    const setTableData = () => {
        if (variableMCapResult.length === 0) {
            setPaginationDataHolder([])
            setCurrentMCapResult([])
            setPageNumberIndex(0)
            return
        }

        let tableDataHolder: CapitalizationData[][] = []

        if (variableMCapResult.length < rowsPerPage) {
            tableDataHolder.push(variableMCapResult)
            setPaginationDataHolder(tableDataHolder)
            setCurrentMCapResult(tableDataHolder[0])
            setPageNumberIndex(0)
            return
        }

        let tempSetData: CapitalizationData[] = []
        for (var i = 0; i < variableMCapResult.length; i++) {
            let currentCapData: CapitalizationData = variableMCapResult[i]
            tempSetData.push(currentCapData)
            if (tempSetData.length % rowsPerPage === 0) {
                tableDataHolder.push(tempSetData)
                tempSetData = []
            }
        }
        if (tempSetData.length > 0) {
            tableDataHolder.push(tempSetData)
            tempSetData = []
        }

        setPaginationDataHolder(tableDataHolder)
        setCurrentMCapResult(tableDataHolder[0])
        setPageNumberIndex(0)
    }

    const filterResult = (sRange: number | number[]) => {
        if (!Array.isArray(sRange)) {
            return
        }
        let filteredResult: CapitalizationData[] = listResult.filter((element: CapitalizationData) => (element.value >= sRange[0] && element.value <= sRange[1]))
        setVariableMCapResult(filteredResult)
    }

    useEffect(setTableData, [rowsPerPage, variableMCapResult])
    useEffect(() => {
        if (doAddToWorkbook) {
            executeAddToWorkbook()
            setDoAddToWorkbook(false)
        }
    }, [doAddToWorkbook])

    const columns: any[] = [
        { title: 'Symbol', field: 'symbol', sorting: true },
        { title: 'Name', field: 'name', sorting: true },
        { title: 'Operating Country', field: 'operatingCountry', sorting: true },
        { title: 'Listing Country', field: 'listingCountry', sorting: true },
        { title: 'Sector', field: 'sector', sorting: true },
        { title: 'Industry', field: 'industry', sorting: true },
        { title: 'Date', field: 'date', type: 'date', sorting: true },
        { title: 'Market Cap', field: 'value', type: 'numeric', sorting: true, render: (rowData: CapitalizationData) => formatNumberEnUSLocalString(rowData.value) },
        { title: 'Year over Year Percent Change', field: 'pctChange', type: 'numeric', sorting: true, render: (rowData: CapitalizationData) => formatNumberEnUSLocalString(rowData.pctChange) }
    ]

    const [sortOrder, setSortOrder] = useState<SortOrderStringType>()
    const [sortColumn, setSortColumn] = useState<string>('')
    const sortedData: any[] = useMemo(
        () =>
            sort(currentMCapResult, getComparator(sortOrder, sortColumn)),
        [sortOrder, sortColumn, currentMCapResult],
    )

    const titleArea = (): JSX.Element => {
        return (
            <Typography variant='h6'>
                Market Cap
                {listResult.length > 0 && (
                    <span>
                        {exportInProgress ?
                            <Tooltip title='Export in Progress'>
                                <IconButton aria-label='Export in Progress' component='label' sx={{
                                    p: '10px',
                                    color: globalStyles.siteThemeColor
                                }}>
                                    <CircularProgress aria-label='Export in Progress' />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={'Export All Search Results'} >
                                <span>
                                    <IconButton
                                        disabled={exportInProgress}
                                        style={styles.iconButton}
                                        aria-label='Export All Search Results'
                                        onClick={() => {
                                            setShowExportOutputFormatModal(true)
                                            setExportAllClicked(true)
                                        }}
                                    >
                                        <ExporttoList style={{ fontSize: '1.25em' }} />
                                    </IconButton>
                                </span>
                            </Tooltip>}
                    </span>
                )}
            </Typography>
        )
    }

    return (
        <>
            <ThemeProvider theme={DefaultMaterialTheme}>
                <MaterialTable
                    columns={columns}
                    data={sortedData}
                    title={titleArea()}
                    options={{ maxBodyHeight: '500px', ...MaterialTableOptions }}
                    actions={[
                        {
                            tooltip: 'Add to Workbook',
                            icon: () => <AddtoWorkbook style={{ fontSize: '1.25em' }} />,
                            onClick: (evt, data) => processAddToWorkbook(data as CapitalizationData[])
                        },
                        {
                            tooltip: 'Export Search Results to List',
                            icon: () => <ExporttoList style={{ fontSize: '1.25em' }} />,
                            onClick: (evt, data) => {
                                setTempSelectedData(data)
                                setExportAllClicked(false)
                                setShowExportOutputFormatModal(true)
                            }
                        },
                        {
                            tooltip: 'Download Selected Series',
                            icon: () => <DownLoadGreen style={{ fontSize: '1.25em' }} />,
                            onClick: (evt, data) => processDownload(data as CapitalizationData[])
                        }
                    ]}
                    components={{
                        Pagination: props => (
                            <>
                                <TablePagination
                                    showFirstButton
                                    showLastButton
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    rowsPerPage={rowsPerPage}
                                    count={variableMCapResult.length}
                                    page={pageNumberIndex}
                                    onPageChange={(e: any, page: number) => {
                                        setPageNumberIndex(page)
                                        setCurrentMCapResult(paginationDataHolder[page])
                                    }}
                                    onRowsPerPageChange={(event: { target: { value: any } }) => {
                                        setRowsPerPage(parseInt(event.target.value, 10))
                                    }}
                                />
                            </>
                        ),
                        Header:
                            props => (
                                <EnhancedTableHead {...{ sortData: { sortColumn, sortOrder, setSortColumn, setSortOrder }, columns, allowSelection: true, onSelectAll: props?.onAllSelected, selectedCount: props?.selectedCount, rowCount: props?.dataCount }} />
                            )
                    }}
                />
            </ThemeProvider>
            <WorkbookSelectionModal {...workbookSelectionModalProps} />
            <AlertModal {...alertTickerLimitModalProps} />
            {renderDownloadAction()}
            <ExportOutputFormatSelectionModal show={showExportOutputFormatModal} setShow={setShowExportOutputFormatModal} exportAction={exportAllClicked ? exportCapitalizationResults : processExportResultToList} />
        </>
    )
}

const styles = {
    iconButton: {
        marginLeft: '8px',
        marginRight: '8px',
        marginTop: '-7px'
    }
}

export default CapitalizationResultListView
