import React, { ReactElement } from 'react'
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts'
import { Paper } from '@mui/material'
import moment from 'moment'
import { DateRangeSelections, ChartScaleTypes, PeriodicityTypes, ChartView } from '../../../Models/DataModels/Common/ToolsModel'
import { GraphRequest } from '../../../Models/DataModels/Requests/GraphRequests'
import SplitIcon from '../../Icons/SplitIcon'
import DividendsIcon from '../../Icons/DividendsIcon'
import { chartSizes } from '../../../Models/DataModels/Common/AppConfigurationsModel'
import { ScaleType } from 'recharts/types/util/types'
import { formatNumberEnUSLocalString } from '../../../Services/UtilityService'

export const specialDotSeparator: string = '___dot___'

export const replaceSpecialDotSeparator = (displayName: string | null | undefined): string => {
    return displayName?.replaceAll(specialDotSeparator, '.') || ''
}

export const CustomizedDot = (props: any): JSX.Element => {
    const { cx, cy, stroke, payload, value } = props
    for (let dataKey in payload) {
        if (dataKey !== 'date') {
            if (payload[dataKey]?.split?.length > 0) {
                return (
                    <svg x={cx - 10} y={cy - 30} width={16} height={30}>
                        <SplitIcon />
                    </svg>
                )
            } else if (payload[dataKey]?.dividend?.length > 0) {
                return (
                    <svg x={cx - 10} y={cy - 30} width={16} height={30}>
                        <DividendsIcon />
                    </svg>
                )
            }
        }
    }
    return (<></>)
}

const getValue = (dataSet: any): string => {
    if (Array.isArray(dataSet.value) && dataSet.value.length > 1) {
        return dataSet.value[1]
    }
    return dataSet.value
}

export const CustomTooltip = (props: any) => {
    const { active, payload, label } = props

    let dataSymbols: any[] = []
    let specialData: any[] = []
    let dateData: any[] = []
    let pricesData: JSX.Element[] = []

    if (active && payload && Array.isArray(payload) && payload.length > 0) {
        const firstPayload: any = payload[0]
        const dataKey: string | null = firstPayload?.dataKey || null
        if (dataKey === 'openClose') {
            const firstPayloadPayload: any = firstPayload.payload
            const payLoadKeys: string[] = Object.keys(firstPayloadPayload)
            payLoadKeys.forEach((payloadKey: string, index: number) => {
                const seriesDataObject: any = firstPayloadPayload[payloadKey]
                const seriesValue: number | null = seriesDataObject?.value || null
                if (seriesValue) {
                    const dataLabelValue: string = `${payloadKey}: ${formatNumberEnUSLocalString(seriesValue)}`
                    dataSymbols.push(<p key={`Data${index}`} className='label' style={{ color: 'black' }}>{dataLabelValue}</p>)
                }
            })
        } else {
            payload.forEach((dataSet: any, index: number) => {
                const dataLabelValue: string = dataSet.dataKey.startsWith('area[') ? dataSet.name : `${dataSet.name}: ${formatNumberEnUSLocalString(getValue(dataSet) as unknown as number)}`
                const colorCode: string = parseStoredColorFromPayloadForWorkaround(dataSet, dataSet.color)
                dataSymbols.push(<p key={`Data${index}`} className='label' style={{ color: colorCode }}>{dataLabelValue}</p>)
            })
        }
    }

    if (active && payload && payload.length) {
        payload.forEach((dataSet: any, index: number) => {
            if (dataSet.payload[dataSet.name]?.split?.length > 0) {
                specialData.push(<p key={`DataSplit${index}`} className='label' style={{ color: dataSet.stroke }}>{dataSet.name} Split: {dataSet.payload[dataSet.name].split}</p>)
            }
            if (dataSet.payload[dataSet.name]?.dividend?.length > 0) {
                specialData.push(<p key={`DataDividend${index}`} className='label' style={{ color: dataSet.stroke }}>{dataSet.name} Dividend: {dataSet.payload[dataSet.name].dividend}</p>)
            }
        })
    }

    if (active && payload && payload.length) {
        dateData.push(<p key='dateP' className='label'> Date: {payload[0].payload.date}</p>)
    }

    if (active && payload && payload.length && payload.length > 0) {
        const dataSet: any = payload[0]
        if (dataSet.payload && dataSet.payload[dataSet.name]?.prices) {
            const openValue: number = dataSet.payload[dataSet.name].prices.open
            const closeValue: number = dataSet.payload[dataSet.name].prices.close
            const isGrowing: boolean = closeValue > openValue
            pricesData.push(
                <div key={'onlyPrimaryOHLC'} className='label' style={{ color: dataSet.stroke }}>
                    <p>High: {formatNumberEnUSLocalString(dataSet.payload[dataSet.name].prices.high)}</p>
                    {isGrowing ?
                        <>
                            <p>Close: {formatNumberEnUSLocalString(closeValue)}</p>
                            <p>Open: {formatNumberEnUSLocalString(openValue)}</p>
                        </>
                        :
                        <>
                            <p>Open: {formatNumberEnUSLocalString(openValue)}</p>
                            <p>Close: {formatNumberEnUSLocalString(closeValue)}</p>
                        </>
                    }
                    <p>Low: {formatNumberEnUSLocalString(dataSet.payload[dataSet.name].prices.low)}</p>
                </div>
            )
        }
    }

    if (active && payload && payload.length) {
        return (
            <Paper elevation={3} style={{ padding: 5 }} >
                <div className='custom-tooltip'>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Date:</p>
                    {dateData}
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Tickers:</p>
                    {dataSymbols}
                    {specialData.length > 0 ? <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Splits/Dividends:</p> : <></>}
                    {specialData}
                    {pricesData.length > 0 ? <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Prices:</p> : <></>}
                    {pricesData.length > 0 && pricesData}
                </div>
            </Paper>
        )
    }

    return null
}

export const VolumeTooltip = (props: any) => {
    const { active, payload }: any = props
    if (active && payload && payload.length > 0) {
        const volumePayload: any = payload[0]
        return (
            <Paper elevation={3} style={{ padding: 5 }} >
                <div>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Date:</p>
                    <p key='dateVolumeP' className='label'> Date: {volumePayload.payload.date}</p>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Volume:</p>
                    <p key='volumeP' className='label' style={{ color: volumePayload.stroke }}>{volumePayload.name}: {formatNumberEnUSLocalString(volumePayload.value)}</p>
                </div>
            </Paper>
        )
    }
    return <></>
}

const tickValueToDisplayValue = (tickValue: number): string => {
    return tickValue > 99999999 || tickValue < -99999999 ? tickValue.toExponential() : formatNumberEnUSLocalString(tickValue)
}

export const customTicks = (props: any): ReactElement<SVGElement> => {
    const { payload, x, y } = props
    const tickValue: number = payload?.value || 0
    const displayValue: string = tickValueToDisplayValue(tickValue)
    return <text x={x} y={y} textAnchor='end'>{displayValue}</text>
}

export const customTicksRightScale = (props: any): ReactElement<SVGElement> => {
    const { payload, x, y } = props
    const tickValue: number = payload?.value || 0
    const displayValue: string = tickValueToDisplayValue(tickValue)
    return <text x={x} y={y} textAnchor='start'>{displayValue}</text>
}

export const EmptyChart = (): JSX.Element => {
    return (
        <ResponsiveContainer width='100%' height={chartSizes.standard}>
            <ComposedChart
                width={500}
                height={400}
                data={[]}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='name' scale='band' />
                <YAxis />
                <Tooltip />
                <Line type='monotone' dataKey='b' stroke='#ff7300' />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

export const findGraphPriceDataMinNumericValue = (dataLine: any[]): number => {
    if (!dataLine || dataLine.length === 0) {
        return -1
    }
    const minValue: number = dataLine.reduce((minValue, currentElement: any) => {
        const elementKeys: string[] = Object.keys(currentElement)
        const validProperties: string[] = elementKeys.filter((value: string) => value !== 'date' && value !== 'area')
        const currentMin: number = validProperties.length === 0 ? -1 : Math.min(currentElement[validProperties[0]].prices.low, currentElement[validProperties[0]].prices.open, currentElement[validProperties[0]].prices.close)
        return !minValue || currentMin < minValue ? currentMin : minValue
    }, null)
    return minValue
}

export const findGraphPriceDataMaxNumericValue = (dataLine: any[]): number => {
    if (!dataLine || dataLine.length === 0) {
        return -1
    }
    const maxValue: number = dataLine.reduce((maxValue, currentElement: any) => {
        const elementKeys: string[] = Object.keys(currentElement)
        const validProperties: string[] = elementKeys.filter((value: string) => value !== 'date' && value !== 'area')
        const currentMax: number = validProperties.length === 0 ? -1 : Math.max(currentElement[validProperties[0]].prices.high, currentElement[validProperties[0]].prices.open, currentElement[validProperties[0]].prices.close)
        return !maxValue || currentMax > maxValue ? currentMax : maxValue
    }, null)
    return maxValue
}

export const prepareCandlestickData = (symbol: string, apiDataArray: any[]): any[] => {

    const dataSourceWithOHLC: any[] = []
    apiDataArray.forEach((entry: any) => {
        const entryProps: string[] = Object.keys(entry)
        if (entryProps.includes(symbol)) {
            dataSourceWithOHLC.push(
                {
                    ...entry,
                    high: entry[symbol]?.prices?.high || 0,
                    low: entry[symbol]?.prices?.low || 0,
                    openClose: [(entry[symbol]?.prices?.open || 0), (entry[symbol]?.prices?.close || 0)]
                }
            )
        }
    })
    return dataSourceWithOHLC
}

export const Candlestick = (props: any): JSX.Element => {
    const {
        fill,
        x,
        y,
        width,
        height,
        low,
        high,
        openClose: [open, close]
    } = props

    const isGrowing: boolean = open < close
    const color = isGrowing ? 'green' : 'red'
    const customWidth: number = width * .7
    const ratio = (open - close) === 0 ? 1 : Math.abs(height / (open - close))

    return (
        <g stroke={color} fill={color} strokeWidth='2'>
            <path
                d={`
                    M ${x},${y}
                    L ${x},${y + height}
                    L ${x + customWidth},${y + height}
                    L ${x + customWidth},${y}
                    L ${x},${y}
                `}
            />
            {/* bottom line */}
            {isGrowing ? (
                <path
                    d={`
                        M ${x + customWidth / 2}, ${y + height}
                        v ${(open - low) * ratio}
                    `}
                />
            ) : (
                <path
                    d={`
                        M ${x + customWidth / 2}, ${y}
                        v ${(close - low) * ratio}
                    `}
                />
            )}
            {/* top line */}
            {isGrowing ? (
                <path
                    d={`
                        M ${x + customWidth / 2}, ${y}
                        v ${(close - high) * ratio}
                    `}
                />
            ) : (
                <path
                    d={`
                        M ${x + customWidth / 2}, ${y + height}
                        v ${(open - high) * ratio}
                    `}
                />
            )}
        </g>
    )
}

export const OHLCstick = (props: any): JSX.Element => {
    const {
        fill,
        x,
        y,
        width,
        height,
        low,
        high,
        openClose: [open, close]
    } = props

    const isGrowing: boolean = open < close
    const color = isGrowing ? 'green' : 'red'
    const customWidth: number = width * .5
    const ratio = (open - close) === 0 ? 1 : Math.abs(height / (open - close))

    return (
        <g stroke={color} fill={'transparent'} strokeWidth='1.5'>
            {/* high low line */}
            <path
                d={`
                    M ${x + customWidth / 2}, ${y - (Math.abs(high - close) * ratio)}
                    v ${(high - low) * ratio}
                `}
            />
            {/* close line */}
            <path
                d={`
                    M ${x + customWidth / 2}, ${y}
                    L ${x + customWidth}, ${y}
                `}
            />
            {/* open line */}
            <path
                d={`
                    M ${x}, ${y + height}
                    L ${x + customWidth / 2}, ${y + height}}
                `}
            />
        </g>
    )
}

export const HLCstick = (props: any): JSX.Element => {
    const {
        fill,
        x,
        y,
        width,
        height,
        low,
        high,
        openClose: [open, close]
    } = props

    const isGrowing: boolean = open < close
    const color = isGrowing ? 'green' : 'red'
    const customWidth: number = width * .7
    const ratio = (open - close) === 0 ? 1 : Math.abs(height / (open - close))

    return (
        <g stroke={color} fill={'transparent'} strokeWidth='1.5'>
            {/* high low line */}
            <path
                d={`
                    M ${x + customWidth / 2}, ${y - (Math.abs(high - close) * ratio)}
                    v ${(high - low) * ratio}
                `}
            />
            {/* close line */}
            <path
                d={`
                    M ${x + customWidth / 2}, ${y}
                    L ${x + customWidth}, ${y}
                `}
            />
        </g>
    )
}

export const convertRequestToPartialModalData = (previousRequest: GraphRequest | null, primaryChartView: ChartView | null): any => {
    const modalData: any = {
        primaryTicker: '',
        chartSettingsSetters: {
            currentDateMode: 'selectDate',
            currentDateSelection: DateRangeSelections.Max,
            currentStartDate: moment('1-1-1950', 'MM-DD-YYYY'),
            currentEndDate: moment(),
            periodicity: PeriodicityTypes.Quarterly,
            graphType: 'line',
            scaleType: ChartScaleTypes.Log,
            currencyType: 'NATIVE',
            showSplits: false,
            splitAdjusted: true,
            totalReturn: false,
            percentGDP: false,
            showDividends: false,
            inflationAdjusted: false,
            annualPctChg: false,
            movingAvg: [],
            customAvg: 0,
            includeVolumeChart: false
        },
        comparisonSettingsSetters: {
            comparisonType: 'none',
            comparisonScale: 'indirect',
            selectedValues: [],
            tickerList: [],
            ticker: '',
            searchTerm: '',
            clearText: false,
            symbolList: []
        },
        technicalIndicatorsSetters: {
            chartIndicator: 'none',
            chartIndicatorPeriod: 20,
            chartIndicatorStdDev: 2,
            chartIndicatorShift: 2.5,
            technicalIndicator: 'none',
            technicalIndicatorPeriod: 20,
            technicalIndicatorShortPeriod: 3,
            technicalIndicatorLongPeriod: 10,
            technicalIndicatorPeriodA: 20,
            technicalIndicatorPeriodEMA: 2,
            technicalIndicatorPeriodK: 14,
            technicalIndicatorPeriodD: 3,
            valueIndicator: 'none',
            isValueIndicatorOnPrimaryGraph: false,
            financialRatio: 'none'
        },
        chronologySettings: {
            chronology: 'none'
        }
    }

    if (previousRequest?.primaryTicker) {
        modalData.primaryTicker = previousRequest.primaryTicker
    }
    if (previousRequest?.settings) {
        modalData.chartSettingsSetters.currentDateMode = previousRequest.settings.isCustomDate ? 'customDate' : 'selectDate'
        modalData.chartSettingsSetters.currentDateSelection = previousRequest.settings.years ? previousRequest.settings.years : DateRangeSelections.Max
        modalData.chartSettingsSetters.currentStartDate = previousRequest.settings.startDate && moment(previousRequest.settings.startDate).isValid() && moment(previousRequest.settings.startDate).format('YYYY') !== '0001' ? moment(previousRequest.settings.startDate) : moment('1-1-1950', 'MM-DD-YYYY')
        modalData.chartSettingsSetters.currentEndDate = previousRequest.settings.endDate && moment(previousRequest.settings.endDate).isValid() && moment(previousRequest.settings.endDate).format('YYYY') !== '9999' ? moment(previousRequest.settings.endDate) : moment()
        modalData.chartSettingsSetters.periodicity = previousRequest.settings.periodicity ? previousRequest.settings.periodicity.toLowerCase() : PeriodicityTypes.Quarterly
        modalData.chartSettingsSetters.graphType = previousRequest.settings.chartType ? previousRequest.settings.chartType.toLowerCase() : 'line'
        modalData.chartSettingsSetters.scaleType = previousRequest.settings.scaleType || ChartScaleTypes.Log
        modalData.chartSettingsSetters.currencyType = previousRequest.settings.currency ? previousRequest.settings.currency : 'NATIVE'
        modalData.chartSettingsSetters.showSplits = previousRequest.settings.showSplits
        modalData.chartSettingsSetters.splitAdjusted = previousRequest.settings.splitAdjusted
        modalData.chartSettingsSetters.totalReturn = previousRequest.settings.totalReturn
        modalData.chartSettingsSetters.percentGDP = previousRequest.settings.percentGDP
        modalData.chartSettingsSetters.showDividends = previousRequest.settings.showDividends
        modalData.chartSettingsSetters.inflationAdjusted = previousRequest.settings.inflationAdjusted
        modalData.chartSettingsSetters.annualPctChg = previousRequest.settings.annualPercentChange
        modalData.chartSettingsSetters.movingAvg = previousRequest.settings.movingAverages ? previousRequest.settings.movingAverages : []
        modalData.chartSettingsSetters.includeVolumeChart = previousRequest.settings.includeVolumeChart
        if (primaryChartView) {
            const optionScaleType: ScaleType = previousRequest.settings.scaleType === ChartScaleTypes.Log ? 'log' : 'linear'
            if (optionScaleType === 'log' && primaryChartView.scaleType === ChartScaleTypes.Linear) {
                modalData.chartSettingsSetters.scaleType = ChartScaleTypes.Linear
            }
        }
    }
    if (previousRequest?.comparison) {
        modalData.comparisonSettingsSetters.comparisonType = previousRequest.comparison.type ? previousRequest.comparison.type.toLowerCase() : 'none'
        modalData.comparisonSettingsSetters.comparisonScale = previousRequest.comparison.scale ? previousRequest.comparison.scale.toLowerCase() : 'indirect'
        modalData.comparisonSettingsSetters.symbolList = previousRequest.comparison.symbols ? previousRequest.comparison.symbols : []
        if (previousRequest.comparison.symbols && previousRequest.comparison.symbols.length > 0) {
            const symbols: string[] = previousRequest.comparison.symbols
            const partialTickerList: any[] = symbols.map((symbol: string) => {
                return {
                    series: symbol,
                    description: symbol
                }
            })
            modalData.comparisonSettingsSetters.tickerList = partialTickerList
        }
    }
    if (previousRequest?.indicators) {
        let chartIndicatorObject: any = {}
        if (previousRequest.indicators.chartIndicators) {
            if (previousRequest.indicators.chartIndicators.averageTrueRange) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'averageTrueRange'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.averageTrueRange.period
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.averageTrueRange.period
                }
            } else if (previousRequest.indicators.chartIndicators.bollingerBands) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'bollingerBands'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.bollingerBands.period
                modalData.technicalIndicatorsSetters.chartIndicatorStdDev = previousRequest.indicators.chartIndicators.bollingerBands.stddev
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.bollingerBands.period,
                    stddev: previousRequest.indicators.chartIndicators.bollingerBands.stddev
                }
            } else if (previousRequest.indicators.chartIndicators.detrendedPriceOscillator) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'detrendedPriceOscillator'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.detrendedPriceOscillator.period
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.detrendedPriceOscillator.period
                }
            } else if (previousRequest.indicators.chartIndicators.envelopes) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'envelopes'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.envelopes.period
                modalData.technicalIndicatorsSetters.chartIndicatorShift = previousRequest.indicators.chartIndicators.envelopes.shift
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.envelopes.period,
                    shift: previousRequest.indicators.chartIndicators.envelopes.shift
                }
            } else if (previousRequest.indicators.chartIndicators.movingAverage) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'movingAverage'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.movingAverage.period
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.movingAverage.period
                }
            } else if (previousRequest.indicators.chartIndicators.exponentialMovingAverage) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'exponentialMovingAverage'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.exponentialMovingAverage.period
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.exponentialMovingAverage.period
                }
            } else if (previousRequest.indicators.chartIndicators.triangularMovingAverage) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'triangularMovingAverage'
                modalData.technicalIndicatorsSetters.chartIndicatorPeriod = previousRequest.indicators.chartIndicators.triangularMovingAverage.period
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {
                    period: previousRequest.indicators.chartIndicators.triangularMovingAverage.period
                }
            } else if (previousRequest.indicators.chartIndicators.medianPrice) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'medianPrice'
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {}
            } else if (previousRequest.indicators.chartIndicators.typicalPrice) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'typicalPrice'
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {}
            } else if (previousRequest.indicators.chartIndicators.weightedClose) {
                modalData.technicalIndicatorsSetters.chartIndicator = 'weightedClose'
                chartIndicatorObject[modalData.technicalIndicatorsSetters.chartIndicator] = {}
            } else {
                modalData.technicalIndicatorsSetters.chartIndicator = 'none'
            }
            modalData.technicalIndicatorsSetters.chartIndicatorObject = chartIndicatorObject
        }
        let techIndicatorObject: any = {}
        if (previousRequest.indicators.technicalIndicators) {
            if (previousRequest.indicators.technicalIndicators.accumulationDistribution) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'accumulationDistribution'
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {}
            } else if (previousRequest.indicators.technicalIndicators.chaikinOscillator) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'chaikinOscillator'
                modalData.technicalIndicatorsSetters.technicalIndicatorShortPeriod = previousRequest.indicators.technicalIndicators.chaikinOscillator.periodShort
                modalData.technicalIndicatorsSetters.technicalIndicatorLongPeriod = previousRequest.indicators.technicalIndicators.chaikinOscillator.periodLong
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    periodShort: previousRequest.indicators.technicalIndicators.chaikinOscillator.periodShort,
                    periodLong: previousRequest.indicators.technicalIndicators.chaikinOscillator.periodLong
                }
            } else if (previousRequest.indicators.technicalIndicators.commodityChannelIndex) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'commodityChannelIndex'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.commodityChannelIndex.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.commodityChannelIndex.period
                }
            } else if (previousRequest.indicators.technicalIndicators.averageDirectionalMovement) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'averageDirectionalMovement'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.averageDirectionalMovement.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.averageDirectionalMovement.period
                }
            } else if (previousRequest.indicators.technicalIndicators.forceIndex) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'forceIndex'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriodEMA = previousRequest.indicators.technicalIndicators.forceIndex.periodEMA
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    periodEMA: previousRequest.indicators.technicalIndicators.forceIndex.periodEMA
                }
            } else if (previousRequest.indicators.technicalIndicators.moneyFlow) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'moneyFlow'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.moneyFlow.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.moneyFlow.period
                }
            } else if (previousRequest.indicators.technicalIndicators.MACD) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'MACD'
                modalData.technicalIndicatorsSetters.technicalIndicatorShortPeriod = previousRequest.indicators.technicalIndicators.MACD.periodShort
                modalData.technicalIndicatorsSetters.technicalIndicatorLongPeriod = previousRequest.indicators.technicalIndicators.MACD.periodLong
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    periodShort: previousRequest.indicators.technicalIndicators.MACD.periodShort,
                    periodLong: previousRequest.indicators.technicalIndicators.MACD.periodLong
                }
            } else if (previousRequest.indicators.technicalIndicators.onBalanceVolume) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'onBalanceVolume'
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {}
            } else if (previousRequest.indicators.technicalIndicators.rateOfChange) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'rateOfChange'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.rateOfChange.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.rateOfChange.period
                }
            } else if (previousRequest.indicators.technicalIndicators.relativeStrengthIndex) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'relativeStrengthIndex'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.relativeStrengthIndex.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.relativeStrengthIndex.period
                }
            } else if (previousRequest.indicators.technicalIndicators.standardDeviation) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'standardDeviation'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.standardDeviation.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.standardDeviation.period
                }
            } else if (previousRequest.indicators.technicalIndicators.stochasticIndicator) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'stochasticIndicator'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriodK = previousRequest.indicators.technicalIndicators.stochasticIndicator.periodK
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriodD = previousRequest.indicators.technicalIndicators.stochasticIndicator.periodD
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    periodK: previousRequest.indicators.technicalIndicators.stochasticIndicator.periodK,
                    periodD: previousRequest.indicators.technicalIndicators.stochasticIndicator.periodD
                }
            } else if (previousRequest.indicators.technicalIndicators.williamsR) {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'williamsR'
                modalData.technicalIndicatorsSetters.technicalIndicatorPeriod = previousRequest.indicators.technicalIndicators.williamsR.period
                techIndicatorObject[modalData.technicalIndicatorsSetters.technicalIndicator] = {
                    period: previousRequest.indicators.technicalIndicators.williamsR.period
                }
            } else {
                modalData.technicalIndicatorsSetters.technicalIndicator = 'none'
            }
            modalData.technicalIndicatorsSetters.technicalIndicatorObject = techIndicatorObject
        }
        if (previousRequest.indicators.valueIndicators) {
            modalData.technicalIndicatorsSetters.valueIndicator = previousRequest.indicators.valueIndicators.valueIndicatorCode
            modalData.technicalIndicatorsSetters.isValueIndicatorOnPrimaryGraph = previousRequest.indicators.valueIndicators.isPrimaryGraph || false
        }
        if (previousRequest.indicators.financialRatios) {
            modalData.technicalIndicatorsSetters.financialRatio = previousRequest.indicators.financialRatios.financialRatioCode
        }
    }
    if (previousRequest?.chronology) {
        modalData.chronologySettings.chronology = previousRequest.chronology.type ? previousRequest.chronology.type.toLowerCase() : 'none'
    }
    return modalData
}

export const addUnixTimeStampProperty = (datapoints: any[]): any[] => {
    const datapointsWithUnixtimeStampDate: any[] = datapoints.map((datapoint: any) => (
        {
            ...datapoint,
            dateUnixTimeStamp: moment.utc(datapoint.date).valueOf()
        }
    ))
    return datapointsWithUnixtimeStampDate
}

export const unixTimestampDateFormatter = (dateValue: number) => {
    const dateFromNumber: moment.Moment = moment(dateValue).utc()
    return dateFromNumber.format('MM/DD/YYYY')
}

export const unixTimestampDateFormatterYYYY = (dateValue: number) => {
    const dateFromNumber: moment.Moment = moment(dateValue).utc()
    return dateFromNumber.format('YYYY')
}

export const reformatDate = (text: string, format: string) => {
    try {
        const dateFromText: moment.Moment = moment(text).utc()
        return dateFromText.format(format)
    } catch {
        return text
    }
}

export const isContainsDuplicateNumber = (numberArray: number[]) => {
    const uniqueNumberSet = new Set(numberArray)
    return uniqueNumberSet.size !== numberArray.length
}

export const splitColorIDPrefix: string = 'splitColor'

const gradientOffset = (graphMax: number, graphMin: number) => {
    const dataMax: number = graphMax
    const dataMin: number = graphMin

    if (dataMax <= 0) {
        return 0;
    }
    if (dataMin >= 0) {
        return 1;
    }

    return dataMax / (dataMax - dataMin)
}

export const negativeColorRenderingComponent = (splitColorID: string, postiveColor: string, graphMax: number, graphMin: number): JSX.Element => {
    const defSplitColorID: string = `def${splitColorID}`
    const off = gradientOffset(graphMax, graphMin)
    const negativeValuesDefs: JSX.Element = (
        <defs key={defSplitColorID}>
            <linearGradient id={splitColorID} x1='0' y1='0' x2='0' y2='1'>
                <stop offset={off} stopColor={postiveColor || 'green'} stopOpacity={1} />
                <stop offset={off} stopColor='red' stopOpacity={1} />
            </linearGradient>
        </defs>
    )
    return negativeValuesDefs
}

export const rechartsBugWorkaroundIDPrefix: string = 'rechartsBugWorkaroundID'

export const parseStoredColorFromPayloadForWorkaround = (payload: any, defaultColor: string): string => {
    let suggestedColor: string = defaultColor
    const fillValue: string = payload?.fill || defaultColor
    if (fillValue && fillValue.startsWith(rechartsBugWorkaroundIDPrefix)) {
        const fillValueSplittedByColon: string[] = fillValue.split(':')
        if (fillValueSplittedByColon.length > 1) {
            const fillColorCode: string = fillValueSplittedByColon[1]
            suggestedColor = fillColorCode
        } else {
            suggestedColor = defaultColor
        }
    } else {
        suggestedColor = defaultColor
    }
    return suggestedColor
}
