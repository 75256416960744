import { Box, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { GFDToastError, GFDToastSuccess } from '../Common/Utility/GFDToastify'
import { ForgotPasswordRequest, LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { forgotPassword } from '../../Services/AuthenticationService'
import { ErrorHandler, ErrorHandlerProps } from '../Common/Utility/ErrorHandler'
import { NotOKResponseModel } from '../../Models/DataModels/Common/NotOKResponseModel'
import { useNavigate } from 'react-router'
import { paths } from '../../Models/DataModels/Common/RedirectionModel'
import { CustomBtnBlue } from '../Common/GlobalSettings/CustomStyles'
import { globalStyles } from '../Common/GlobalSettings/GlobalStyles'
import { gaLogEvent, gaFinaeonWebAppEventCategories } from '../Google/analytics'

export interface ForgotPasswordProps {
    signOut: (logoutReason: LogoutReasonType) => void
}

const ForgotPassword = ({ signOut }: ForgotPasswordProps) => {

    const [email, setEmail] = useState<string>('')
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [isEmailError, setIsEmailError] = useState<boolean>(false)

    const navigate = useNavigate()
    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    const validateChangeEmail = (email: string) => {
        const emailRequirementsRegex: RegExp = new RegExp('[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?')

        if (!emailRequirementsRegex.test(email)) {
            setIsEmailError(true)
            if (email.length < 1) {
                setIsEmailError(false)
            }
        } else {
            setIsEmailError(false)
        }
    }

    const handleInputChange = (email: string) => {
        setEmail(email)
        validateChangeEmail(email)
    }

    const onResetPasswordClick = () => {
        gaLogEvent('Clicked on Reset Password', gaFinaeonWebAppEventCategories.ForgotPassword, 'onResetPasswordClick')
        if (!email || isEmailError) {
            GFDToastError('You must enter a valid email')
            return
        } else {
            const forgotPasswordReq: ForgotPasswordRequest = { email: email }
            forgotPassword(forgotPasswordReq).then(
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        GFDToastSuccess('Link to reset password sent to email')
                        navigate(paths.login)
                    } else {
                        GFDToastError('Could not send reset password link to email')
                    }
                },
                (notOKResponseModel: NotOKResponseModel) => {
                    setErrorResponse(notOKResponseModel)
                }
            )
        }
    }

    const displayHelperText = () => {
        if (isEmailError) {
            return helperTextObj.errorText
        } else {
            return helperTextObj.helperText
        }
    }

    const helperTextObj = {
        errorText: 'You must enter a valid email',
        helperText: 'Enter your email'
    }

    return (
        <React.Fragment>
            <ErrorHandler {...errorHandlerProps} />
            <Box sx={newStyle.page}>
                <div style={resetPasswordStyles.panelBoxHeader}>Reset your password</div>
                <div style={resetPasswordStyles.panelBox}>
                    <Grid container direction='column' alignItems='center'>
                        <Grid item paddingY={'5%'} marginRight={'5%'}>
                            <TextField id='emailField' label='Email' variant='outlined'
                                sx={{
                                    paddingBottom: 1,
                                    '& .MuiInputBase-root': {
                                        width: '120%'
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight: 'bold' }
                                }}
                                size='small'
                                onChange={(e) => {
                                    handleInputChange(e.target.value)
                                }}
                                error={isEmailError}
                                helperText={displayHelperText()} />
                        </Grid>
                        <Grid item paddingBottom={'10%'}>
                            <CustomBtnBlue id='submitEmailLink' variant='contained' onClick={onResetPasswordClick}>Send link to reset password</CustomBtnBlue>
                        </Grid>
                    </Grid>
                </div>
                <section id='resetPasswordForm'>

                </section>
            </Box>
        </React.Fragment>
    )
}

const resetPasswordStyles = {
    panel: {
        border: '1px transparent',
        width: '65%',
        margin: 'auto',
        paddingY: '10%'
    },
    panelBox: {
        background: '#FFF',
        borderRadius: '0 0 1em 0',
        borderBottom: '1px #A4A4A4 solid',
        borderRight: '1px #A4A4A4 solid',
        borderLeft: '1px #A4A4A4 solid',
        width: '50%',
        margin: 'auto',
        padding: '2em 2em',
    },
    panelBoxHeader: {
        background: 'white',
        marginBottom: '0px',
        width: '50%',
        margin: 'auto',
        border: '1px #4C4E52 solid',
        borderRadius: '1em 0 0 0',
        color: 'white',
        fontSize: 'large',
        backgroundColor: globalStyles.siteThemeColor,
        textAlign: 'center' as 'center',
        padding: '1em'
    },
    panelFooter: {
        borderColor: '#90CCCC',
        background: '#90CCCC',
        borderRadius: '0 0 1em 1em',
        fontFamily: 'Lucida Sans Unicode, Myriad Pro, Microsoft Sans Serif, sans - serif',
        fontSize: '10pt',
        color: '#fff',
        padding: '15px 15px',
        borderBottom: 'solid 2px #CDCDCD',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD',
        boxShadow: '5px 5px 5px 5px black'
    },
    resetPasswordPanelBody: {
        background: '#F3F3F3',
        padding: '15px',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD',
    },
    tealHeader: {
        background: '#90CCCC',
        color: '#FFF',
        borderColor: '#90CCCC',
        lineHeight: '1.5em',
        height: '4.5em',
        padding: '0 3em',
        borderRadius: '1em 1em 0 0',
        borderTop: 'solid 2px #CDCDCD',
        borderRight: 'solid 1px #CDCDCD',
        borderLeft: 'solid 1px #CDCDCD'
    },
}

const newStyle = {
    page: {
        height: 'maxHeight',
        background: '#F3F3F3',
        paddingTop: '5%'
    }
}

export default ForgotPassword
